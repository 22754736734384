import React, { useState, useEffect } from "react";
import { Card, Tab, Nav, Col, Row, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectPdf, setSelectedpdf] = useState(null);
  const history = useHistory();
  const [bankScr1, setBankSrc1] = useState(null);
  const [bankScr2, setBankSrc2] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [empBankId, setEmpBankId] = useState(null);

  const id = localStorage.getItem("USERID");
  console.log("bankScr1", bankScr1)

  useEffect(() => {
    if (id) {
      fetchUser(id);
    }
  }, [id]);

  const fetchUser = async (userId) => {
    const data = await fetch(environment.apiUrl + `employee/${userId}`);
    const responseData = await data.json();
    setUsers(responseData);
    console.log(responseData);
    console.log(responseData.bankId);
    if (responseData.bankId != null) {
      const dataBank = await fetch(
        environment.apiUrl + `empBank/${responseData.bankId}`
      );
      const responseBank = await dataBank.json();
      setEmpBankId(responseBank.id);
      console.log("responseBank ", responseBank);
      setName(responseBank.name);
      setAccNumber(responseBank.accountNumber);
      setIfsc(responseBank.ifsc);
      setBranchName(responseBank.branchName);
      setSelectedImage(responseBank.imageOfCancelledCheque);
      setSelectedpdf(responseBank.documentOfCancelledCheque);
      let bankdata1 = responseBank.imageOfCancelledCheque
        ? environment.apiUrl +
          "empBank/files/" +
          responseBank.imageOfCancelledCheque
        : null;
      let bankdata2 =
        environment.apiUrl +
        "empBank/files/" +
        responseBank.documentOfCancelledCheque;
      setBankSrc1(bankdata1);
      setBankSrc2(bankdata2);
    }
  };

  const defaultImageUrl =
    "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";

  const onBankUpdate = async () => {
    console.log(bankId);
    console.log({
      name: name,
      accountNumber: accNumber,
      ifsc: ifsc,
      branchName: branchName,
      imageOfCancelledCheque: selectedImage,
      documentOfCancelledCheque: selectPdf,
    });
    const formDataBank = new FormData();
    formDataBank.append("name", name);
    formDataBank.append("accountNumber", accNumber);
    formDataBank.append("ifsc", ifsc);
    formDataBank.append("branchName", branchName);

    if (selectedImage) {
      formDataBank.append("imageOfCancelledCheque", selectedImage);
    }
    if (selectPdf) {
      formDataBank.append("documentOfCancelledCheque", selectPdf);
    }

    try {
      const response = await fetch(
        environment.apiUrl + `empBank/update/${empBankId}`,
        {
          method: "PUT",
          headers: {},
          body: formDataBank,
        }
      );

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Bank updated successfully!");

        history.push("/dashboard");
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error updating bank. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onBankCreate = async () => {
    const formDataBank = new FormData();
    formDataBank.append("name", name);
    formDataBank.append("accountNumber", accNumber);
    formDataBank.append("ifsc", ifsc);
    formDataBank.append("branchName", branchName);

    if (selectedImage) {
      formDataBank.append("imageOfCancelledCheque", selectedImage);
    }
    if (selectPdf) {
      formDataBank.append("documentOfCancelledCheque", selectPdf);
    }

    try {
      const response = await fetch(environment.apiUrl + `empBank/create`, {
        method: "POST",
        headers: {},
        body: formDataBank,
      });

      const responseData2 = await response.json();
      console.log("responseData2", responseData2);

      if (response.ok) {
        const empBankId = responseData2.id;
        const userTableData = {
          bankId: empBankId,
        };
        try {
          const response = await fetch(
            environment.apiUrl + `employee/update/bank/${id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userTableData),
            }
          );
          if (response.ok) {
            toast.success("Bank details uploaded successfully!");
            history.push("/dashboard");
          } else {
            toast.error("Error at adding bank details id at userTable");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding bank details. Please try again");
    }
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handlepdfUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedpdf(imageFile);
  };

  // const formData = new FormData();

  // if (selectedImage) {
  //   formData.append("image", selectedImage);
  // }

  // Define tabData before usage
  const tabData = [
    {
      name: "Bank Account Details",
      icon: "bank",
    },
  ];

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await axios.get(environment.apiUrl + "auth/authors", {
  //         headers: {
  //           Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
  //         },
  //       });
  //       setUsers(response.data.authors);
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //     }
  //   }
  //   fetchData();
  // }, []);

  /********************* Bank Account details *********************** */

  const renderBankTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>Name</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentNo">
                <Form.Label>Account Number</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="number"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={accNumber}
                    onChange={(e) => setAccNumber(parseInt(e.target.value))}
                    placeholder="Enter account Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>IFSC Code</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    placeholder="Enter ifsc code..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
           
              <Form.Group controlId="documentType">
                <Form.Label>Branch Name</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter branch name..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage">
                    <Form.Label> Image Of cancel cheque</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  {bankScr1 !== null ? (
                    <img
                      src={bankScr1}
                      // alt="Image 1"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImageUrl}
                      alt="Default Image"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docPdf">
                    <Form.Label>Document PDF</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handlepdfUpload}
                    />
                  </Form.Group>
                </div>
                <div className="col-4" style={{ marginTop: "40px" }}>
                  <a target="_blank" href={bankScr2}>
                    View Document as Pdf
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-end">
          {users?.bankId === null ? (
            <button
              type="button"
              className={`btn btn-secondary`}
              style={{ marginTop: "10px" }}
              onClick={() => {
                onBankCreate();
              }}
            >
              Create
            </button>
          ) : (
            <button
              type="button"
              className={`btn btn-secondary`}
              style={{ marginTop: "10px" }}
              onClick={() => {
                onBankUpdate();
              }}
            >
              Update
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderTabContent = (tabName) => {
    if (tabName === "Bank Account Details") {
      return renderBankTab();
    }
  };

  return (
    <div className="col-12">
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Bank Details</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Body>
          {/* <div className="card-header">
            <h1 className="card-title">Update Bank Details</h1>
          </div> */}
          <div className="default-tab">
            <Tab.Container
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={i}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={i} key={i}>
                    {renderTabContent(data.name)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
