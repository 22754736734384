import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { DatePicker } from "reactstrap-date-picker";
import * as moment from 'moment';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const userId = localStorage.getItem("USERID");

  useEffect(() => {
    attendanceData();
  }, []);

  const attendanceData = async () => {
    try {
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      const response = await fetch(
        environment.apiUrl + `empAttendance/myAttendance/${userId}`
      );
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
      setFilteredUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = async () => {
    try {
      const formattedFromDate = fromDate ? formatDateForSearch(fromDate) : "";
      const formattedToDate = toDate ? formatDateForSearch(toDate) : "";

      const dateData = {
        fromDate:formattedFromDate,
        toDate:formattedToDate,
        empId:userId
      };

      console.log("dateData", dateData)

      const response = await fetch(environment.apiUrl + "empAttendance/date", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dateData),
      });

      const res1 = await response.json();
      
      if (response.ok) {
        setFilteredUsers(res1);
        console.log("ans", res1)
    console.log(filteredUsers)
      }
    } catch (error) {
      console.error("Error fetching data by date range:", error);
    }
  };

  const formatDateForSearch = (dateString) => {
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${year}-${month}-${day}`;
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + `empAttendance/delete/${id}`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setFilteredUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== id)
        );
      })
      .catch((error) => {
        toast.error(
          "It cannot be Deleted because it's linked with a supervisor"
        );
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <Link to="/superAttendance"></Link>
            </div>
          </div>
          <div className="default-tab">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Employee Attendance</h4>
                <Link to="/add-empAttendances">
                  <button className="btn btn-primary">Mark Attendance</button>
                </Link>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <h5>Date</h5>
                  <div className="form-row" style={{ display: "flex" }}>
                    <div className="col-4">
                      <DatePicker
                        placeholder="From (YYYY-MM-DD)"
                        value={fromDate}
                        onChange={(date) => setFromDate(date)}
                      />
                    </div>
                    &nbsp;&nbsp;
                    <div className="col-4">
                      <DatePicker
                        placeholder="To (YYYY-MM-DD)"
                        value={toDate}
                        onChange={(date) => setToDate(date)}
                      />
                    </div>
                    &nbsp;&nbsp;
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-100 table-responsive">
                  <div>
                    <table
                      className="table table-bordered"
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Location Map View</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map((res, index) => (
                          <tr key={res.id}>
                            <td>{index + 1}</td>
                            <td>{formatDate(res.date)}</td>
                            <td>{res.time}</td>
                            <td>
                              <iframe
                                title={`Location-${index}`}
                                style={{ marginLeft: "10px" }}
                                width="200"
                                height="100"
                                src={`https://www.google.com/maps/embed/v1/place?key=${environment.mapKey}&q=${res.latitude},${res.longitude}`}
                              ></iframe>
                            </td>
                            <td>
                              <button
                                onClick={() => onDelete(res.id)}
                                style={{ marginLeft: "5px" }}
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Pagination */}
                    <ul className="pagination justify-content-end">
                      {Array.from({
                        length: Math.ceil(filteredUsers.length / usersPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(index + 1)}
                            className="page-link"
                            style={{ alignItems: "center" }}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
