import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function JQueryValidation(props) {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const history = useHistory();

  const calculateLocation = async (e) => {
    try {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log("Latitude:", latitude);
        console.log("Longitude:", longitude);

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${environment.mapKey}`
        );

        if (response.ok) {
          const data = await response.json();
          if (data && data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            const currentDate = new Date();

            console.log(formattedAddress);

            setDate(currentDate.toDateString());
            setTime(currentDate.toLocaleTimeString());
            setLocation(formattedAddress);
          } else {
            console.error("No results found in the Google Maps API response.");
          }
        } else {
          console.error(
            "Error response from the Google Maps API:",
            response.statusText
          );
        }
      });
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/empAttendance",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();

    const attendanceData = {
      date,
      time,
      location,
      employeeId: 1,
    };

    try {
      const response = await fetch(environment.apiUrl + "attendance/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(attendanceData),
      });
      if (response.ok) {
        toast.success("Attendance Marked");
        handleCancel()
      } else {
        toast.error("Error at marking attendance");
      }
    } catch (error) {
      toast.error("Error at marking attendance");
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          {/* <li className="breadcrumb-item active">
            <Link to="/superAttendance">Admin Attendance</Link>
          </li> */}
          <li className="breadcrumb-item active">
            <Link to="empAttendance">Employee Attendance</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Mark Attendance</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Mark Attendance</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Date
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            value={date}
                            readOnly
                            placeholder="Current date"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Time
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            value={time}
                            readOnly
                            placeholder="Current Time"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Location
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="distance"
                            name="distance"
                            value={location}
                            readOnly
                            placeholder="Current Location"
                          />
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={calculateLocation}
                          >
                            Location
                          </button>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
