import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { environment } from "../../../environments/environment";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();

  useEffect(() => {
    fetchCalc();
  }, []);

  const fetchCalc = async () => {
    try {
      const response = await fetch(environment.apiUrl + "calculator/all");
      const calc = await response.json();
      console.log(calc);
      setUsers(calc);
    } catch (error) {
      console.error("Error fetching calc:", error);
    }
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "calculator/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error("It cannot be deleted because it's linked with a supervisor");
      });
  }

  // Calculate the index of the last user to display on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  // Calculate the index of the first user to display on the current page
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  // Get the users to display on the current page
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Freight Calculator</h4>
                <Link to="/add-calculator">
                  <button className="btn btn-primary">
                    Add Freight Calculator
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="w-100 table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th>Origin</th>
                        <th>Destination</th>
                        <th>Distance</th>
                        <th>Vehicle Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUsers.map((res, index) => (
                        <tr key={res.id}>
                          <td>{index + 1}</td>
                          <td>{res.origin}</td>
                          <td>{res.destination}</td>
                          <td>{res.distance}</td>
                          <td>{res.vehicle.vehicleName}</td>
                          <td>
                            <i
                              className="bi bi-pencil-square"
                              onClick={() =>
                                history.push({
                                  pathname: `/add-calculator/` + res.id,
                                  state: res,
                                })
                              }
                              style={{
                                color: "purple",
                                fontSize: "30px",
                                cursor: "pointer",
                              }}
                            ></i>
                            &nbsp;&nbsp;
                            <i
                              className="bi bi-trash"
                              onClick={() => onDelete(res.id)}
                              style={{
                                color: "red",
                                fontSize: "30px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <ul className="pagination justify-content-end">
                    {Array.from({
                      length: Math.ceil(users.length / usersPerPage),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          index + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(index + 1)}
                          className="page-link"
                          style={{ alignItems: "center" }}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
