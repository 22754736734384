import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function MyVerticallyCenteredModal(props) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setModalImage(imageFile);
    const imageURL = URL.createObjectURL(imageFile);
    setImagePreviewUrl(imageURL);
  };

  const saveDataAndClose = () => {
    if (modalImage) {
      console.log("Image Data:", modalImage);
      props.onSave(modalImage);
    } else {
      console.log("No image selected");
    }
    props.onHide();
  };

  const defaultImageUrl =
    "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Payment Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-3 row">
          <label
            className="col-lg-2 col-form-label"
            style={{ marginTop: "30px" }}
          >
            Select Image :
          </label>
          <div className="col-lg-4" style={{ marginTop: "28px" }}>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>
          <div className="col-4" style={{ marginTop: "5px" }}>
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                style={{
                  width: "120px",
                  height: "80px",
                  borderRadius: "10px",
                }}
                alt="Image Preview"
              />
            ) : (
              <img
                src={defaultImageUrl}
                alt="Default Image"
                style={{
                  width: "120px",
                  height: "80px",
                  borderRadius: "10px",
                }}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveDataAndClose}>Save</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function AddVarientForm(props) {
  const history = useHistory();
  const [expenseType, setExpenseType] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [date, setDate] = useState("");
  const [party, setParty] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [narration, setNarration] = useState("");
  const [docPdf, setDocPdf] = useState(null);
  const [billImage, setBillImage] = useState(null);
  const [src1, setSrc1] = useState(null);
  const [src2, setSrc2] = useState(null);
  const { id } = useParams();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [division, setDivision] = useState("");
  const [divisions, setDivisions] = useState([]);

  const [partyUsers, setPartyUsers] = useState([]);

  const USERID = localStorage.getItem("reqUserId");
  const paymentStatus = localStorage.getItem("paymentStatus");
  const USERTYPE = localStorage.getItem("USERTYPE")

  useEffect(() => {
    fetchExpenseTypeData();
    fetchDivisions();
    if (id) {
      fetchEmpData(id);
    }
  }, [id]);

  const fetchExpenseTypeData = async () => {
    const data = await fetch(environment.apiUrl + "expenseType/all");
    const jsonData = await data.json();
    setExpenseTypes(jsonData);
  };

  const fetchDivisions = async () => {
    const data = await fetch(environment.apiUrl + "division/all");
    const jsonData = await data.json();
    setDivisions(jsonData);
  };

  useEffect(() => {
    if (USERID) {
      fetchPartyUsers();
    }
  }, [USERID]);

  const fetchPartyUsers = async () => {
    try {
      const response = await axios.get(
        environment.apiUrl + "party/all/" + USERID,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        }
      );
      setPartyUsers(response.data);
    } catch (error) {
      console.error("Error fetching party users:", error);
    }
  };

  // useEffect(() => {
  //   if (USERID) {
  //     fetchEmployeeById(USERID);
  //   }
  // }, [USERID]);

  // const fetchEmployeeById = async (USERID) => {
  //   const data = await fetch(environment.apiUrl + `employee/${USERID}`);
  //   const jsonData = await data.json();
  //   console.log("user division data :", jsonData);
  //   setDivision(jsonData.division);
  // };

  const fetchEmpData = async (paymentId) => {
    const response = await fetch(
      environment.apiUrl + `empPayment/${paymentId}`
    );
    const paymentData = await response.json();
    setExpenseType(paymentData.expenseTypeId);
    setExpenseAmount(paymentData.expenseAmount);
    const formattedDate = new Date(paymentData.date)
      .toISOString()
      .split("T")[0];
    setDate(formattedDate);
    setIsUrgent(paymentData.isUrgent);
    setParty(paymentData.partyEmployeeId);
    setBankDetails(paymentData.bankDetails);
    setNarration(paymentData.narration);
    setBillImage(paymentData.billImage);
    setDocPdf(paymentData.docPdf);
    setDivision(paymentData.divisionId);
    let src1 = paymentData.uploadBill
      ? environment.apiUrl + "empPayment/files/" + paymentData.uploadBill
      : null;
    let src2 = environment.apiUrl + "empPayment/files/" + paymentData.uploadPdf;
    setSrc1(src1);
    setSrc2(src2);

    if (
      paymentData.status === "Approved" ||
      paymentData.status === "Disapproved"
    ) {
      setButtonsDisabled(true);
    }
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setBillImage(imageFile);
  };

  const handlePdf = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/adminPayment",
    });
  };

  const saveModalData = (imageData) => {
    setModalImage(imageData);
    history.push("/adminPayment");
  };

  const onAddUser = async (e, paymentStatus) => {
    e.preventDefault();
    if (!expenseType || expenseType === "Select Option") {
      toast.error("Expense Type field is required.");
      return;
    }

    if (!party || party === "Select Option") {
      toast.error("Party field is required.");
      return;
    }

    const formData = new FormData();
    formData.append("expenseAmount", expenseAmount);
    formData.append("status", paymentStatus);
    formData.append("divisionId", division);
    formData.append("expenseTypeId", expenseType);
    formData.append("date", date);
    formData.append("partyEmployeeId", party);
    formData.append("bankDetails", bankDetails);
    formData.append("narration", narration);
    formData.append("isUrgent", isUrgent);
    formData.append("userTableId", USERID);
    if (billImage) {
      formData.append("uploadBill", billImage);
    }
    if (docPdf) {
      formData.append("uploadPdf", docPdf);
    }

    try {
      const response = await fetch(
        environment.apiUrl + `empPayment/update/${id}`,
        {
          method: "PUT",
          headers: {},
          body: formData,
        }
      );
      if (response.ok) {
        toast.success(`Employee payment ${paymentStatus} successfully!`);
        setButtonsDisabled(true);
        if(paymentStatus==="Disapproved"){
          history.push("/adminPayment")
        }
        // handleCancel();
      } else {
        toast.error("error updating");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultImageUrl =
    "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/adminPayment">Payment Request</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Submit Request For Payment</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Payment Request </h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Expense Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={expenseType}
                            onChange={(e) => setExpenseType(e.target.value)}
                            disabled={buttonsDisabled}
                          >
                            <option value="Select Option">Select Option</option>
                            {expenseTypes.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res?.expenseType}
                              </option>
                            ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Expense Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={expenseAmount}
                            onChange={(e) => setExpenseAmount(e.target.value)}
                            placeholder="Enter amount value.."
                            style={{ cursor: "pointer" }}
                            disabled={buttonsDisabled}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Date
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="date"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder="Enter date.."
                            disabled={buttonsDisabled}
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Choose Party
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={party}
                            onChange={(e) => setParty(e.target.value)}
                            disabled={buttonsDisabled}
                          >
                            <option value="Select Option">Select Option</option>
                            {partyUsers.map((res) => (
                              <option
                                value={res.id}
                                key={res.id}
                                style={{
                                  color: res.party.isBlacklist
                                    ? "red"
                                    : "inherit",
                                  textDecoration: res.party.isBlacklist
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {res?.party?.contactPerson}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label className="col-lg-2 col-form-label">
                          Upload Bill Copy
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload}
                            disabled={buttonsDisabled}
                          />
                        </div>
                        <div className="col-4">
                          {src1 !== null ? (
                            <img
                              src={src1}
                              style={{
                                width: "120px",
                                height: "80px",
                                borderRadius: "10px",
                              }}
                              alt="Image 1"
                            />
                          ) : (
                            <img
                              src={defaultImageUrl}
                              alt="Default Image"
                              style={{
                                width: "120px",
                                height: "80px",
                                borderRadius: "10px",
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Bank Details
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={bankDetails}
                            onChange={(e) => setBankDetails(e.target.value)}
                            placeholder="Enter bank details.."
                            disabled={buttonsDisabled}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Narration
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={narration}
                            onChange={(e) => setNarration(e.target.value)}
                            placeholder="Enter Narration.."
                            disabled={buttonsDisabled}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label className="col-lg-2 col-form-label">
                          Upload pdf
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept=".pdf"
                            onChange={handlePdf}
                            disabled={buttonsDisabled}
                          />
                        </div>
                        <div className="col-4" style={{ marginTop: "10px" }}>
                          <a target="_blank" href={src2}>
                            View Document as Pdf
                          </a>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          is Urgent
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="true"
                                checked={isUrgent}
                                onChange={() => setIsUrgent(true)}
                                required
                                disabled={buttonsDisabled}
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="false"
                                checked={!isUrgent}
                                onChange={() => setIsUrgent(false)}
                                required
                                disabled={buttonsDisabled}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Division
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={division}
                            onChange={(e) => setDivision(e.target.value)}
                            style={{ cursor: "pointer" }}
                            disabled={buttonsDisabled}
                          >
                            <option value="Select Option">Select Option</option>
                            {divisions.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res?.divisionName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          {paymentStatus === "Pending" && (
                            <>
                              <button
                                type="button"
                                className={`btn btn-danger ${
                                  buttonsDisabled ? "disabled" : ""
                                }`}
                                style={{ marginTop: "20px" }}
                                onClick={(e) => onAddUser(e, "Disapproved")}
                              >
                                Disapprove
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className={`btn btn-primary ${
                                  buttonsDisabled ? "disabled" : ""
                                }`}
                                style={{ marginTop: "20px" }}
                                onClick={(e) => {
                                  onAddUser(e, "Approved");
                                  setModalShow(true);
                                }}
                              >
                                Approve
                              </button>
                            </>
                          )}
                          {paymentStatus === "Approved" && (
                            <button
                              type="button"
                              className={`btn btn-primary ${
                                buttonsDisabled ? "disabled" : ""
                              }`}
                              style={{ marginTop: "20px", marginLeft: "50px" }}
                              onClick={(e) => {
                                onAddUser(e, "Approved");
                                setModalShow(true);
                              }}
                            >
                              Approve
                            </button>
                          )}

                          {paymentStatus === "Disapproved" && (
                            <button
                              type="button"
                              className={`btn btn-danger ${
                                buttonsDisabled ? "disabled" : ""
                              }`}
                              style={{ marginTop: "20px", marginLeft: "50px" }}
                              onClick={(e) => {
                                onAddUser(e, "Disapproved");
                              }}
                            >
                              Disapprove
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          history.push("/adminPayment");
        }}
        onSave={saveModalData}
      />
    </Fragment>
  );
}

export default AddVarientForm;
