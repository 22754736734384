import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [currentPageApproved, setCurrentPageApproved] = useState(1);
  const [currentPageDisapproved, setCurrentPageDisapproved] = useState(1);
  const [currentPagePending, setCurrentPagePending] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();

  useEffect(() => {
    fetchEmpPayment();
  }, []);

  const fetchEmpPayment = async () => {
    try {
      const response = await fetch(environment.apiUrl + "empPayment/all");
      const fetchedUsers = await response.json();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching quality:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    return `${day}-${month}-${year}`;
  };

  const paginate = (tab, pageNumber) => {
    switch (tab) {
      case "all":
        setCurrentPageAll(pageNumber);
        break;
      case "approved":
        setCurrentPageApproved(pageNumber);
        break;
      case "disapproved":
        setCurrentPageDisapproved(pageNumber);
        break;
      case "pending":
        setCurrentPagePending(pageNumber);
        break;
      default:
        break;
    }
  };

  const getPaginatedData = (tab) => {
    const filteredData = users.filter((item) => {
      switch (tab) {
        case "all":
          return true;
        case "approved":
          return item.status === "Approved";
        case "disapproved":
          return item.status === "Disapproved";
        case "pending":
          return item.status === "Pending";
        default:
          return true;
      }
    });
  
    const indexOfLastUser = getPageState(tab) * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
  
    return filteredData.slice(indexOfFirstUser, indexOfLastUser);
  };

  const getPageState = (tab) => {
    switch (tab) {
      case "all":
        return currentPageAll;
      case "approved":
        return currentPageApproved;
      case "disapproved":
        return currentPageDisapproved;
      case "pending":
        return currentPagePending;
      default:
        return 1;
    }
  };

  const renderTableRows = (data) => {
    return data.map((res, index) => (
      <tr key={res.id}>
        <td>{index + 1}</td>
        <td
          style={{
            color: res.partyEmployee.party.isBlacklist ? "red" : "inherit",
            textDecoration: res.partyEmployee.party.isBlacklist
              ? "line-through"
              : "none",
          }}
        >
          {res.partyEmployee?.party?.contactPerson}
        </td>
        <td>{res.expenseAmount}</td>
        <td>{formatDate(res.date)}</td>
        <td>
          <button
            onClick={() => {
              history.push({
                pathname: `/payment/` + res.id,
                state: res,
              });
              localStorage.setItem(
                "reqUserId",
                res?.partyEmployee?.userTableId
              );
              localStorage.setItem("paymentStatus", res.status);
            }}
            className="btn btn-primary"
          >
            View
          </button>
        </td>
        <td>{res.status}</td>
        <td>{res.isUrgent ? "Yes" : "No"}</td>
      </tr>
    ));
  };

  const renderPagination = (tab) => {
    const filteredUsers = users.filter((item) => {
      switch (tab) {
        case "all":
          return true;
        case "approved":
          return item.status === "Approved";
        case "disapproved":
          return item.status === "Disapproved";
        case "pending":
          return item.status === "Pending";
        default:
          return true;
      }
    });

    return (
      <ul className="pagination justify-content-end">
        {Array.from({
          length: Math.ceil(filteredUsers.length / usersPerPage),
        }).map((_, index) => (
          <li
            key={index}
            className={`page-item ${
              index + 1 === getPageState(tab) ? "active" : ""
            }`}
          >
            <button
              onClick={() => paginate(tab, index + 1)}
              className="page-link"
              style={{ alignItems: "center" }}
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey="all">
              <Nav className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="all" className="tab-link">
                    ALL
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="approved" className="tab-link">
                    Approved
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="disapproved" className="tab-link">
                    Disapproved
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="pending" className="tab-link">
                    Pending
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th>Party Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Is Urgent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderTableRows(
                        getPaginatedData("all")
                      )}
                    </tbody>
                  </table>
                  {renderPagination("all")}
                </Tab.Pane>

                <Tab.Pane eventKey="approved">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th>Party Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Is Urgent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderTableRows(
                        getPaginatedData("approved")
                      )}
                    </tbody>
                  </table>
                  {renderPagination("approved")}
                </Tab.Pane>

                <Tab.Pane eventKey="disapproved">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th>Party Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Is Urgent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderTableRows(
                        getPaginatedData("disapproved")
                      )}
                    </tbody>
                  </table>
                  {renderPagination("disapproved")}
                </Tab.Pane>

                <Tab.Pane eventKey="pending">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th>Party Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Is Urgent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderTableRows(
                        getPaginatedData("pending")
                      )}
                    </tbody>
                  </table>
                  {renderPagination("pending")}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
