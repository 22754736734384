import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function AddVarientForm(props) {
  const history = useHistory();
  const [varient, setVarient] = useState("");
  const [commodity, setCommodity] = useState("");
  const [cropDuration, setCropDuration] = useState("");
  const [isImported, setIsImported] = useState(false);
  const [farmable, setFarmable] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [commodities, setCommodities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchCommodities();
  }, []);

  const fetchCommodities = async () => {
    const data = await fetch(environment.apiUrl + "commodity/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setCommodities(jsonData);
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/varient",
    });
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    // Perform form submission logic
    setIsSubmitting(true);
    if (!varient) {
      toast.error("Variant field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!commodity || commodity === "Select Option") {
      toast.error("Commodity field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!cropDuration) {
      toast.error("Crop Duration field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!selectedImage) {
      toast.error("Please select an image");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("varient", varient);
    formData.append("commodityId", commodity);
    formData.append("cropDuration", cropDuration);
    formData.append("isImported", isImported);
    formData.append("farmable", farmable);

    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    console.log(formData);

    try {
      const response = await fetch(environment.apiUrl + "varient/create", {
        method: "POST",
        headers: {},
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success("Varient created successfully!");
        handleCancel();
      } else {
        if (responseData && responseData.message) {
          toast.error(responseData.message);
        } else {
          toast.error("Error adding Varient. Please try again.");
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);

      toast.error("Error adding Varient. Please try again");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/varient">Varient</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Varient</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Variant</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Variant
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={varient}
                            onChange={(e) => setVarient(e.target.value)}
                            placeholder="Enter variant name.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Commodity
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={commodity}
                            onChange={(e) => setCommodity(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {commodities.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.commodity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Crop Duration
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={cropDuration}
                            onChange={(e) => setCropDuration(e.target.value)}
                            placeholder="Enter crop duration.."
                          />
                        </div>

                        <label className="col-lg-2 col-form-label">
                          Upload Image
                          <span className="text-danger">*</span>
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          isImported
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="true"
                                checked={isImported}
                                onChange={() => setIsImported(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="false"
                                checked={!isImported}
                                onChange={() => setIsImported(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          Farmable
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="farmable"
                                value="true"
                                checked={farmable}
                                onChange={() => setFarmable(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="farmable"
                                value="false"
                                checked={!farmable}
                                onChange={() => setFarmable(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddVarientForm;
