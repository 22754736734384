import React, { Fragment, useContext, useEffect, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { environment } from "../../../environments/environment";
import defaultImageURL from "../../../images/newLogo_icon.jpeg";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  const [imagePreview, setImagePreview] = useState(null);
  const [src, setSrc] = useState(null);

  const id = localStorage.getItem("USERID");

  useEffect(() => {
    if (id) {
      fetchEmployeeData(id);
    }
  }, [id]);

  const fetchEmployeeData = async (employeeId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `employee/${employeeId}`
      );
      const employeeData = await response.json();

      let data1 = environment.apiUrl + "employee/files/" + employeeData.picture;
      console.log(data1);
      setSrc(data1);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  };

  return (
    <div className="nav-header">
      <Link to="/dashboard">
        <img
          src={defaultImageURL}
          style={{
            height: "50px",
            width: "50px",
            marginLeft: "23px",
            marginTop: "15px",
            borderRadius: "50%",
          }}
        />
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
