import React, { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const UserList = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    const data = await fetch(environment.apiUrl + "vehicle/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setUsers(jsonData);
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "vehicle/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error("It cannot be deleted because it's linked with a supervisor");
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const activeUsers = users.filter((user) => user.isActive);
  const currentUsers = activeUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (activeUsers.length > 0) {
    return (
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="default-tab">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Vehicle</h4>
                  <Link to="/add-vehicle">
                    <button className="btn btn-primary">Add Vehicle</button>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="w-100 table-responsive">
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Serial No</th>
                            <th>Vehicle Name</th>
                            <th>Maximum Weight</th>
                            <th>First 80</th>
                            <th>Per Km</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.map((res, index) => (
                            <tr key={res.id}>
                              <td>{index + 1}</td>
                              <td>{res.vehicleName}</td>
                              <td>{res.maxWeight}</td>
                              <td>{res.first80kmCharges}</td>
                              <td>{res.perkmCharges}</td>
                              <td>
                                <div className="btn-group" role="group">
                                  <button
                                    onClick={() =>
                                      history.replace("add-vehicle/" + res.id)
                                    }
                                    className="btn btn-primary"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => onDelete(res.id)}
                                    style={{ marginLeft: "5px" }}
                                    className="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <ul className="pagination justify-content-end">
                        {Array.from({
                          length: Math.ceil(activeUsers.length / usersPerPage),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              index + 1 === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ alignItems: "center" }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Vehicle </h4>
            <Link to="/add-vehicle">
              <button className="btn btn-primary">Add Vehicle</button>
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive"></div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserList;
