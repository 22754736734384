import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function JQueryValidation(props) {
  const [priceType, setPriceType] = useState("");
  const history = useHistory();
  const {id}= useParams();

  useEffect(() => {
    if (id) {
      fetchPriceType(id);
    }
  }, [id]);

  const fetchPriceType = async (priceId) => {
    try {
        const response = await fetch(environment.apiUrl + `priceType/${priceId}`);
        if (!response.ok) {
          throw new Error("Error fetching price type data.");
        }
  
        const priceTypeData = await response.json();
        setPriceType(priceTypeData.priceType);
      } catch (error) {
        console.error("Error fetching price type data:", error);
        toast.error("Error fetching price type data");
      }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/priceType",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    // Dispatch your action here

    if (!priceType) {
      toast.error("Price Type field is required.");
      return;
    }

    const priceData = {
      priceType,
    };

    console.log(priceData);

    try {
        const response = await fetch(environment.apiUrl + `priceType/update/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(priceData),
          });
    
          if (response.ok) {
            toast.success("Price type Updated successfully!");
            handleCancel();
    
            history.push({
              pathname: "/priceType",
              state: { newPrice: priceData },
            });
          } else {
            toast.error("Error adding price type .");
          }
        } catch (error) {
          toast.error("Error adding price type");
        }
  };

  return (
    <Fragment>
         <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active"><Link to="/priceType">Price Type</Link></li>
          <li className="breadcrumb-item"><Link to="#">Update Price Type</Link></li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Price Type</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Price Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={priceType}
                            onChange={(e) => setPriceType(e.target.value)}
                            placeholder="Enter price type..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
