import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const UserList = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);

  useEffect(() => {
    fetchAreas();
  }, []);

  const handleExcelUpload = (e) => {
    const excelFile = e.target.files[0];
    setSelectedImage(excelFile);
  };

  const handleSaveClick = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        const response = await axios.post(
          environment.apiUrl + "area/excelUpload",
          formData,
          {
            headers: {
              // Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
            },
          }
        );

        if (response.status === 200) {
          toast.success("Excel data uploaded successfully!");
          fetchAreas();
          setSelectedImage(null);
        }
      } catch (error) {
        console.error("Error uploading Excel data:", error);
        toast.error("Data already uploaded");
      }
    } else {
      toast.error("Please select an Excel file to upload.");
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(environment.apiUrl + "area/getareas", {
        headers: {
          // Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      });

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching areas:", error);
      toast.error("Error fetching areas. Please try again.");
    }
  };

  const onDelete = async (id) => {
    try {
      console.log("Deleting ID:", id);
      await axios.delete(environment.apiUrl + "area/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      });

      toast.success("Successfully Deleted");
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));

      console.log("Users after deletion:", users);

      fetchAreas();
    } catch (error) {
      toast.error("It cannot be Deleted because it's linked with a supervisor");
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Area</h4>
                <Link to="/add-area">
                  <button className="btn btn-primary">Add Area</button>
                </Link>
              </div>
              <div className="card-body">
                <div className="w-100 table-responsive">
                  <div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Area</th>
                          <th>District</th>
                          <th>State</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers &&
                          currentUsers.map((i, index) => (
                            <tr key={i.id}>
                              <td>{index + 1}</td>
                              <td>{i.area}</td>
                              <td>{i.district}</td>
                              <td>{i.state}</td>
                              <td>
                                {" "}
                                <button
                                  onClick={() => onDelete(i.id)}
                                  style={{ marginLeft: "5px" }}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <ul className="pagination justify-content-end">
                      {Array.from({
                        length: Math.ceil(users.length / usersPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(index + 1)}
                            className="page-link"
                            style={{ alignItems: "center" }}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form className="form-group mb-3 row d-flex justify-content-center align-items-center">
            <label
              className="col-lg-4 col-form-label"
              htmlFor="excel-upload"
              style={{ marginBottom: "60px" }}
            >
              Upload Excel Sheet
              <span className="text-danger"></span>
            </label>
            <div className="col-lg-6">
              <input
                type="file"
                className="form-control"
                id="excel-upload"
                name="excel-upload"
                // accept=".xlsx, .xls"
                onChange={handleExcelUpload}
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveClick}
                style={{ marginTop: "30px" }}
              >
                Upload
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
