import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [unit, setUnit] = useState([]);
  const [commodity, setCommodity] = useState([]);
  const [varient, setVarient] = useState([]);
  const [quality, setQuality] = useState([]);
  const [size, setSize] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();

  useEffect(() => {
    fetchSellingPrice();
    fetchUnit();
    fetchVarient();
    fetchSize();
    fetchCommodity();
    fetchQuality();
  }, []);

  const fetchSellingPrice = async () => {
    try {
      const response = await fetch(environment.apiUrl + "sellingPrice/all");
      if (response.ok) {
        const fetchedUsers = await response.json();
        console.log(fetchedUsers);
        setUsers(fetchedUsers);
      } else {
        // Handle API response errors here
        console.error("API response error:", response.status);
      }
    } catch (error) {
      // Handle fetch or other errors here
      console.error("Error fetching unit :", error);
    }
  };

  const fetchVarient = async () => {
    try {
      const response = await fetch(environment.apiUrl + "varient/all");
      if (response.ok) {
        const fetchedUsers = await response.json();
        setVarient(fetchedUsers);
      } else {
        console.error("API response error:", response.status);
      }
    } catch (error) {
      console.error("Error fetching unit :", error);
    }
  };

  const fetchUnit = async () => {
    try {
      const response = await fetch(environment.apiUrl + "unit/all");
      if (response.ok) {
        const fetchedUsers = await response.json();
        setUnit(fetchedUsers);
      } else {
        console.error("API response error:", response.status);
      }
    } catch (error) {
      console.error("Error fetching unit :", error);
    }
  };

  const fetchCommodity = async () => {
    try {
      const response = await fetch(environment.apiUrl + "commodity/all");
      if (response.ok) {
        const fetchedUsers = await response.json();
        setCommodity(fetchedUsers);
      } else {
        console.error("API response error:", response.status);
      }
    } catch (error) {
      console.error("Error fetching unit :", error);
    }
  };

  const fetchQuality = async () => {
    try {
      const response = await fetch(environment.apiUrl + "quality/all");
      if (response.ok) {
        const fetchedUsers = await response.json();
        setQuality(fetchedUsers);
      } else {
        console.error("API response error:", response.status);
      }
    } catch (error) {
      console.error("Error fetching unit :", error);
    }
  };

  const fetchSize = async () => {
    try {
      const response = await fetch(environment.apiUrl + "size/all");
      if (response.ok) {
        const fetchedUsers = await response.json();
        setSize(fetchedUsers);
      } else {
        console.error("API response error:", response.status);
      }
    } catch (error) {
      console.error("Error fetching unit :", error);
    }
  };

  /* useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios.get(environment.apiUrl + "account/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      })
        .then(response => 
        setUsers(response.data.authors)
      );
      // ...
    }
    fetchData();
  }, []); */

  // axios.get(environment.apiUrl + "account/authors", {
  //   headers: {
  //     Authorization: "bearer " + localStorage.getItem("MYTOKEN")
  //   }
  // })
  //   .then(response =>
  //   setUsers(response.data.authors)
  // );

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "sellingPrice/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error("It can not be Deleted because its link with supervisor");
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (users != null) {
    return (
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="default-tab">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Selling Price</h4>
                  <Link to="/add-sellingPrice">
                    <button className="btn btn-primary">
                      Add Selling Price
                    </button>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="w-100 table-responsive">
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Serial No</th>
                            <th>Product</th>
                            <th>Party</th>
                            <th>Price Type</th>
                            <th>Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.map((res, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                {res.product?.commodity?.commodity}-
                                {res.product?.varient?.varient}|
                                {res.product?.size != null ? (
                                  <span>{res.product?.size?.size}</span>
                                ) : (
                                  <span></span>
                                )}
                                |
                                {res.product.quality != null ? (
                                  <span>{res.product.quality?.quality}</span>
                                ) : (
                                  <span></span>
                                )}
                                {res.product?.packSize}-{res.product?.unit?.unit}
                              </td>
                              <td>
                                {" "}
                                <span
                                  style={{
                                    color: res.party?.isBlacklist
                                      ? "red"
                                      : "inherit",
                                    textDecoration: res.party?.isBlacklist
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {res.party?.contactPerson}
                                </span>
                              </td>
                              <td>{res.priceType?.priceType}</td>
                              <td>{res.price}</td>

                              <td>
                                <div className="btn-group" role="group">
                                  <button
                                    onClick={() =>
                                      history.push({
                                        pathname: `/add-sellingPrice/` + res.id,
                                        state: res,
                                      })
                                    }
                                    className="btn btn-primary"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => onDelete(res.id)}
                                    style={{ marginLeft: "5px" }}
                                    className="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <ul className="pagination justify-content-end">
                        {Array.from({
                          length: Math.ceil(users.length / usersPerPage),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              index + 1 === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ alignItems: "center" }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Selling Price </h4>
            <Link to="/add-sellingPrice">
              <button className="btn btn-primary">Add Selling Price </button>
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserList;
