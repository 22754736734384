import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function JQueryValidation(props) {
  const [commodityType, setCommodityType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    if (id) {
      fetchCommodityTypeData(id);
    }
  }, [id]);

  const fetchCommodityTypeData = async (commodityTypeId) => {
    try {
        const response = await fetch(environment.apiUrl + `commodityType/${commodityTypeId}`);
        if (!response.ok) {
          throw new Error("Error fetching commodity type data.");
        }
  
        const commodityTypeData = await response.json();
        setCommodityType(commodityTypeData.commodityType);
      } catch (error) {
        console.error("Error fetching commodity type data:", error);
        toast.error("Error fetching commodity type data");
      }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/commodityType",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    // Dispatch your action here
    setIsSubmitting(true)

    if (!commodityType) {
      toast.error("Commodity Type field is required.");
      setIsSubmitting(false)
      return;
    }

    const commodityTypeData = {
      commodityType,
    };

    console.log(commodityTypeData);

    try {
        const response = await fetch(environment.apiUrl + `commodityType/update/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(commodityTypeData),
          });
    
          if (response.ok) {
            toast.success("Commodity Type Updated successfully!");
            handleCancel();
    
            history.push({
              pathname: "/commodityType",
              state: { newBusinessType: commodityTypeData },
            });
          } else {
            toast.error("Error adding commodity type.");
          }
        } catch (error) {
          toast.error("Error adding commodity type");
        }
        finally{
          setIsSubmitting(false)
        }
  };

  return (
    <Fragment>
        <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active"><Link to="/commodityType">Commodity Type</Link></li>
          <li className="breadcrumb-item"><Link to="#">Edit Commodity Type</Link></li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Commodity Type</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Commodity Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={commodityType}
                            onChange={(e) => setCommodityType(e.target.value)}
                            placeholder="Enter commodity type..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
