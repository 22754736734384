import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function AddEmployeeForm(props) {
  const history = useHistory();

  const [src, setSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const id = localStorage.getItem("USERID");

  useEffect(() => {
    if (id) {
      fetchEmployeeData(id);
    }
  }, [id]);

  const fetchEmployeeData = async (employeeId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `employee/${employeeId}`
      );
      const employeeData = await response.json();

      setImageFile(employeeData.picture);
      let data1 = environment.apiUrl + "employee/files/" + employeeData.picture;
      console.log(data1);
      setSrc(data1);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/dashboard",
    });
  };

  const onAddUser = async (e) => {
    e.preventDefault();


    const formData = new FormData();

    formData.append("picture", imageFile);

    console.log(formData);

    try {
      const response = await fetch(
        environment.apiUrl + `employee/update/${id}`,
        {
          method: "PUT",
          headers: {},
          body: formData,
        }
      );

      if (response.ok) {
        toast.success("Profile image updated successfully!");
        setSrc(URL.createObjectURL(imageFile));

        /* history.push("/dashboard"); */
        window.location.reload();
      } else {
        toast.error("Error updating Image. Please try again.");
      }
    } catch (error) {
      console.error("Error updating image:", error);
      toast.error("Error updating image. Please try again.");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    // Preview the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const defaultImageURL =
    "https://img.freepik.com/free-icon/user_318-150864.jpg";

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Image</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6">
                          <label htmlFor="image-upload">
                            <img
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              src={imagePreview || src || defaultImageURL}
                              alt="User Avatar"
                            />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="image-upload"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>

                      <div
                        className="form-group mb-3 row"
                        style={{ marginLeft: "20px" }}
                      >
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddEmployeeForm;
