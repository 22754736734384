import React, { useState, useEffect } from "react";
import { Card, Tab, Nav, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import UpdateImage from "../../components/profile/updateImage";
import Bank from "../../components/profile/updateBank";
import KYC from "../../components/profile/updateKyc";
import KYCADMIN from "../../components/profile/adminPartyKyc"
import BANKADMIN from "../../components/profile/adminPartyBank"

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(environment.apiUrl + "auth/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      });
      setUsers(response.data.authors);
    }
    fetchData();
  }, []);

  const tabData = [
    {
      name: "Image",
      icon: "image",
      content: <UpdateImage />,
    },
    {
      name: "KYC",
      icon: "id-card",
      content: <KYC />,
    },
    {
      name: "Bank Details",
      icon: "bank",
      content: <Bank />,
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "auth/user/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => toast.success("Successfully Changed the status"));
  }

  const handleImageTabClick = () => {};

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      eventKey={data.name.toLowerCase()}
                      onClick={() => {
                        if (data.name === "Image") {
                          handleImageTabClick();
                        }
                      }}
                    >
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    {data.content}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const UserList2 = () => {
  const [users, setUsers] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const history = useHistory(); // Initialize history

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(environment.apiUrl + "auth/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      });
      setUsers(response.data.authors);
    }
    fetchData();
  }, []);

  const tabData = [
    {
      name: "Image",
      icon: "image",
      content: <UpdateImage />,
    },
    // {
    //   name: "KYC",
    //   icon: "id-card",
    //   content: <KYC />,
    // },
    // {
    //   name: "Bank Details",
    //   icon: "bank",
    //   content: <Bank />,
    // },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "auth/user/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => toast.success("Successfully Changed the status"));
  }

  const handleImageTabClick = () => {};

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      eventKey={data.name.toLowerCase()}
                      onClick={() => {
                        if (data.name === "Image") {
                          handleImageTabClick();
                        }
                      }}
                    >
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    {data.content}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const UserList3 = () => {
  const [users, setUsers] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const history = useHistory(); // Initialize history

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(environment.apiUrl + "auth/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      });
      setUsers(response.data.authors);
    }
    fetchData();
  }, []);

  const tabData = [
    {
      name: "Image",
      icon: "image",
      content: <UpdateImage />,
    },
    {
      name: "KYC",
      icon: "id-card",
      content: <KYCADMIN />,
    },
    {
      name: "Bank Details",
      icon: "bank",
      content: <BANKADMIN />,
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "auth/user/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => toast.success("Successfully Changed the status"));
  }

  const handleImageTabClick = () => {};

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      eventKey={data.name.toLowerCase()}
                      onClick={() => {
                        if (data.name === "Image") {
                          handleImageTabClick();
                        }
                      }}
                    >
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    {data.content}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const Profile = () => {
  const USERTYPE = localStorage.getItem("USERTYPE");
  const ISADMIN = localStorage.getItem("ISADMIN");

  if (USERTYPE === "Employee" && ISADMIN === "false") {
    return <UserList />;
  } else if (USERTYPE === "Employee" && ISADMIN === "true") {
    return <UserList3 />;
  } else {
    return <UserList2 />;
  }
};

export default Profile;
