import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../../environments/environment";

const ExpenseTab = ({ user, onTotalExpenseChange }) => {
  const [expenses, setExpenses] = useState([]);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (user) {
      fetchExpenses(user);
    }
  }, [user]);

  const fetchExpenses = async (userId) => {
    try {
      const response = await axios.get(
        environment.apiUrl + `empImprestBalance/all/${userId}`
      );

      if (response.data) {
        const sortedExpenses = response.data
          .filter((expense) => expense.isActive === true)
          .sort((a, b) => new Date(b.date) - new Date(a.date));

        setExpenses(sortedExpenses);
        const totalExpense = calculateTotalExpense(sortedExpenses);
        onTotalExpenseChange(totalExpense);
      }
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  const calculateTotalExpense = (expenses) => {
    return expenses.reduce(
      (total, expense) => total + expense.expenseAmount,
      0
    );
  };

  const groupExpensesByMonth = (expenses) => {
    const groupedExpenses = {};

    expenses.forEach((expense) => {
      const date = new Date(expense.date);
      const year = date.getFullYear();
      const month = date.toLocaleString("default", { month: "long" });

      const key = `${month} ${year}`;
      if (!groupedExpenses[key]) {
        groupedExpenses[key] = [];
      }

      groupedExpenses[key].push(expense);
    });

    return groupedExpenses;
  };

  const renderExpenseCards = (groupedExpenses) => {
    return Object.keys(groupedExpenses).map((key, index) => (
      <div key={index}>
        <h4>{key}</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Expense Type</th>
              <th>Expense Amount</th>
              <th>Date</th>
              <th>Party</th>
              <th>Bill Copy</th>
            </tr>
          </thead>
          <tbody>
            {groupedExpenses[key].map((expense, index) => (
              <tr key={index}>
                <td>{expense.expenseType?.expenseType}</td>
                <td>{expense.expenseAmount}</td>
                <td>{new Date(expense.date).toISOString().split("T")[0]}</td>
                <td
                  style={{
                    color: expense.party?.isBlacklist ? "red" : "inherit",
                    textDecoration: expense.party?.isBlacklist
                      ? "line-through"
                      : "none",
                  }}
                >
                  {expense.party?.contactPerson}
                </td>
                <td>
                  {expense.uploadBill!=null? (
                    <a
                      target="_blank"
                      href={`${environment.apiUrl}empImprestBalance/files/${expense.uploadBill}`}
                    >
                      View Document as Pdf
                    </a>
                  ) : (
                    <p>No document available</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
      </div>
    ));
  };

  const groupedExpenses = groupExpensesByMonth(expenses);

  return (
    <div>
      {renderExpenseCards(groupedExpenses)}
      <div style={{ textAlign: "center" }}>
        <strong>Total Expense: {calculateTotalExpense(expenses)}</strong>
      </div>
    </div>
  );
};

export default ExpenseTab;
