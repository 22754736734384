import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

function AddVarientForm(props) {
  const history = useHistory();
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleClass, setVehicleClass] = useState("");
  const [crateCapacity, setCrateCapacity] = useState("");
  const [bigCrateCapacity, setBigCrateCapacity] = useState("");
  const [maxWeight, setMaxWeight] = useState("");
  const [type, setType] = useState("");
  const [first80, setFirst80] = useState("");
  const [perKm, setPerKm] = useState("");
  const [vehicleClasses, setVehicleClasses] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchVehicleClass();
    if (id) {
      fetchVehicleData(id);
    }
  }, [id]);

  const fetchVehicleClass = async () => {
    try {
      const response = await fetch(environment.apiUrl + "vehicleClass/all");
      if (response.ok) {
        const jsonData = await response.json();
        setVehicleClasses(jsonData);
      } else {
        toast.error("Error fetching vehicle classes.");
      }
    } catch (error) {
      console.error("Error fetching vehicle classes: ", error);
      toast.error("Error fetching vehicle classes. Please try again.");
    }
  };

  const fetchVehicleData = async (vehicleId) => {
    try {
      const response = await fetch(environment.apiUrl + `vehicle/${vehicleId}`);
      const vehicleData = await response.json();

      setVehicleName(vehicleData.vehicleName);
      setVehicleClass(vehicleData.vehicleClass);
      setCrateCapacity(vehicleData.crateCapacity);
      setBigCrateCapacity(vehicleData.bigCrateCapacity);
      setMaxWeight(vehicleData.maxWeight);
      setType(vehicleData.type);
      setFirst80(vehicleData.first80kmCharges);
      setPerKm(vehicleData.perkmCharges);
    } catch (error) {
      console.log("Error fetching vehicle data:", error);
    }
  };

  const handleCancel = () => {
    history.push("/vehicle");
  };

  const onAddVehicle = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (
      !vehicleName ||
      !vehicleClass ||
      !crateCapacity ||
      !bigCrateCapacity ||
      !maxWeight ||
      !type ||
      !first80 ||
      !perKm
    ) {
      toast.error("All fields are required.");
      setIsSubmitting(false);
      return;
    }

    const vehicleData = {
      vehicleName,
      vehicleClass,
      crateCapacity,
      bigCrateCapacity,
      maxWeight,
      type,
      first80kmCharges: first80,
      perkmCharges: perKm,
    };

    try {
      const response = await fetch(
        environment.apiUrl + `vehicle/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(vehicleData),
        }
      );

      if (response.ok) {
        toast.success("Vehicle updated successfully!");
        history.push({
          pathname: "/vehicle",
          state: { newVehicle: vehicleData },
        });
      } else {
        toast.error("Error adding vehicle. Please try again.");
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error adding vehicle: ", error);
      toast.error("Error adding vehicle. Please try again.");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/vehicle">Vehicle</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Update Vehicle</Link>
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Vehicle</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form className="form-valide" onSubmit={onAddVehicle}>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Vehicle Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={vehicleName}
                            onChange={(e) => setVehicleName(e.target.value)}
                            placeholder="Enter vehicle name.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Vehicle Class
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={vehicleClass}
                            onChange={(e) => setVehicleClass(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {vehicleClasses.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.vehicleClass}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Crate Capacity
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={crateCapacity}
                            onChange={(e) =>
                              setCrateCapacity(parseFloat(e.target.value))
                            }
                            placeholder="Enter crate capacity..."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Big Crate Capacity
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={bigCrateCapacity}
                            onChange={(e) =>
                              setBigCrateCapacity(parseFloat(e.target.value))
                            }
                            placeholder="Enter big crate capacity..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Max Weight
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={maxWeight}
                            onChange={(e) =>
                              setMaxWeight(parseFloat(e.target.value))
                            }
                            placeholder="Enter max weight..."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            placeholder="Enter type value..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          First 80
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={first80}
                            onChange={(e) =>
                              setFirst80(parseFloat(e.target.value))
                            }
                            placeholder="Enter first 80 km value..."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Per Km
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={perKm}
                            onChange={(e) =>
                              setPerKm(parseFloat(e.target.value))
                            }
                            placeholder="Enter per Km value..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddVarientForm;
