import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import {  Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const UserList = () => {

  const history = useHistory();
  const [users, setUsers] = useState(null);

  /* useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios.get(environment.apiUrl + "account/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      })
        .then(response => 
        setUsers(response.data.authors)
      );
      // ...
    }
    fetchData();
  }, []); */

  axios.get(environment.apiUrl + "account/authors", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers(response.data.authors)
  );

  function onDelete(id) {
    axios.delete(environment.apiUrl + "account/author/" + id, {
      headers: {
        Authorization: "bearer " + localStorage.getItem("MYTOKEN")
      }
    })
    .then(response =>
      toast.success('Successfully Deleted')
    )
    .catch(error => {
      toast.error('It can not be Deleted because its link with supervisor')
    })
  }
    
    if(users!=null) {
      return (
        <div className="col-12">

        <Card>
            <Card.Body>
              <div className="default-tab">
              <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Subscription List</h4>
                          <Link to="/add-subscription">
                          <button className="btn btn-primary">
                            Add Subscription
                          </button>
                          </Link>
                        </div>
                        <div className="card-body">
                          <div className="w-100 table-responsive">
                            <div>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Renew Days</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {users.map((i, index) => 
                                  <tr>
                                    <td>{index+1}</td>
                                    <td>{i.title}</td>
                                    <td>{i.day} days</td>
                                    <td>{i.amount}</td>
                                    <td>
                                      <button onClick={() => history.replace("edit-subscription/"+ i.id)} className="btn btn-primary">Edit</button>
                                      <button onClick={() => onDelete(i.id)} style={{marginLeft:"5px"}} className="btn btn-danger">Delete</button>                                      
                                    </td>
                                  </tr>
                                )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        </div>
              </div>
            </Card.Body>
          </Card>
          
          
        </div>
      );
    }
    else {
      return (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Subscription List</h4>
              <Link to="/add-user">
              <button className="btn btn-primary">
                Add Subscription
              </button>
              </Link>
            </div>
            <div className="card-body">
              <div className="w-100 table-responsive">
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

export default UserList;
