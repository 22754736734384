import React, { useState, useEffect } from "react";
import { Card, Tab, Nav, Col, Row, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [documentType, setDocumentType] = useState("");
  const [docTypes, setDocTypes] = useState([]);
  const [documentNumber, setDocNumber] = useState("");
  const [docImage, setDocImage] = useState(null);
  const [docImage2, setDocImage2] = useState(null);
  const [docPdf, setDocPdf] = useState(null);
  const history = useHistory();

  const [kycSrc1, setKycSrc1] = useState(null);
  const [kycSrc2, setKycSrc2] = useState(null);
  const [kycSrc3, setKycSrc3] = useState(null);

  const [kycId, setKycId] = useState(null);
  console.log("the kyc", users?.kycId);

  const id = localStorage.getItem("USERID");

  useEffect(() => {
    if (id) {
      fetchUser(id);
    }
  }, [id]);

  const fetchUser = async (userId) => {
    const data = await fetch(environment.apiUrl + `employee/${userId}`);
    const responseData = await data.json();
    setUsers(responseData);
    console.log(responseData);
    console.log(responseData.kycId);
    if(responseData.kycId !=null){
        const dataKyc = await fetch(environment.apiUrl+`empKyc/${responseData.kycId}`);
        const responseKyc = await dataKyc.json();
        setEmpKycId(responseKyc.id)
        console.log("responseKyc ", responseKyc)
        setDocumentType(responseKyc.documentTypeId)
        setDocNumber(responseKyc.documentNumber)
        let kycdata1 = responseKyc.imageOfDocument
      ? environment.apiUrl + "empKyc/files/" + responseKyc.imageOfDocument
      : null;
    console.log("image data", kycdata1);
    let kycdata2 = responseKyc.image2
      ? environment.apiUrl + "empKyc/files/" + responseKyc.image2
      : null;
    console.log("image data", kycdata2);
    let kycdata3 =
      environment.apiUrl + "empKyc/files/" + responseKyc.documentPDF;
    setKycSrc1(kycdata1);
    setKycSrc2(kycdata2);
    setKycSrc3(kycdata3);
    }


  };


  useEffect(() => {
    fetchDocType();
  }, []);

  const fetchDocType = async () => {
    const data = await fetch(environment.apiUrl + "doctype/all");
    const jsonData = await data.json();
    setDocTypes(jsonData);
  };
 const [empKycId, setEmpKycId] = useState(null)

  const defaultImageUrl =
    "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";

  const onKycUpdate = async () => {
    const formData = new FormData();
    formData.append("documentTypeId", documentType);
    formData.append("documentNumber", documentNumber);

    if (docImage) {
      formData.append("imageOfDocument", docImage);
    }
    if (docImage2) {
      formData.append("image2", docImage2);
    }

    if (docPdf) {
      formData.append("documentPDF", docPdf);
    }

    try {
      const response = await fetch(environment.apiUrl + `empKyc/update/${empKycId}`, {
        method: "PUT",
        headers: {},
        body: formData,
      });

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Kyc updated successfully!");
        history.push("/dashboard");
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error adding Kyc. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding Party. Please try again");
    }
  };

  const onKycCreate = async () => {
    const formData = new FormData();
    formData.append("documentTypeId", documentType);
    formData.append("documentNumber", documentNumber);

    if (docImage) {
      formData.append("imageOfDocument", docImage);
    }
    if (docImage2) {
      formData.append("image2", docImage2);
    }

    if (docPdf) {
      formData.append("documentPDF", docPdf);
    }

    try {
      const response = await fetch(environment.apiUrl + `empKyc/create`, {
        method: "POST",
        headers: {},
        body: formData,
      });

      const responseData2 = await response.json();
      console.log("responseData2", responseData2)

      if (response.ok) {
        const empKycId = responseData2.id;
        const userTableData = {
          kycId:empKycId
        };
        try {
          const response = await fetch(
            environment.apiUrl + `employee/update/kyc/${id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userTableData),
            }
          );
          if (response.ok) {
            toast.success("Kyc created successfully!");
            history.push("/dashboard");
          } else {
            toast.error("Error at adding kyc id at userTable");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding Party. Please try again");
    }
  };

  const handleDocImage = (e) => {
    const imageFile = e.target.files[0];
    setDocImage(imageFile);
  };

  const handleDocImage2 = (e) => {
    const imageFile = e.target.files[0];
    setDocImage2(imageFile);
  };

  const handlePdf = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };
  const tabData = [
    {
      name: "KYC",
      icon: "pen",
    },
  ];

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await axios.get(environment.apiUrl + "auth/authors", {
  //         headers: {
  //           Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
  //         },
  //       });
  //       setUsers(response.data.authors);
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //     }
  //   }
  //   fetchData();
  // }, []);

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const handleBack = () => {};

  const isLastTab = activeTab === tabData.length - 1;

  const addUser = () => {
    alert("Selected");
  };

  /***************** KYC Tab ******************** */

  const handleCancel = () => {
    history.push({
      pathname: "/party",
    });
  };

  const renderKYCTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="documentType">
                <Form.Label>Type of Document</Form.Label>
                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {docTypes.map((res) => (
                      <option value={res.id}>{res.docName}</option>
                    ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>Document Number</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={documentNumber}
                    onChange={(e) => setDocNumber(e.target.value)}
                    placeholder="Enter Document Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage">
                    <Form.Label>Document Image</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleDocImage}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  {kycSrc1 !== null ? (
                    <img
                      src={kycSrc1}
                      // alt="Image 1"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImageUrl}
                      alt="Default Image"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage2">
                    <Form.Label>Document Image 2</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleDocImage2}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  {kycSrc2 !== null ? (
                    <img
                      src={kycSrc2}
                      // alt="Image 1"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={defaultImageUrl}
                      alt="Default Image"
                      style={{
                        width: "120px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-4">
              <Form.Group controlId="docPdf">
                <Form.Label>Document PDF</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handlePdf}
                />
              </Form.Group>
            </div>
            <div className="col-4" style={{ marginTop: "40px" }}>
              <a target="_blank" href={kycSrc3}>
                View Document as Pdf
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {users?.kycId === null ? (
              <button
                type="button"
                className={`btn btn-secondary`}
                style={{ marginTop: "10px" }}
                onClick={() => {
                  onKycCreate();
                }}
              >
                Create
              </button>
            ) : (
              <button
                type="button"
                className={`btn btn-secondary`}
                style={{ marginTop: "10px" }}
                onClick={() => {
                  onKycUpdate();
                }}
              >
                Update
              </button>
            )}
            {/* <button
              type="button"
              className="btn btn-secondary"
              style={{ marginTop: "10px" }}
              onClick={handleBack}
            >
              Back
            </button> */}
          </div>
        </Form>
      </div>
    );
  };

  const renderTabContent = (tabName) => {
    if (tabName === "KYC") {
      return renderKYCTab();
    }
  };

  return (
    <div className="col-12">
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#"> KYC</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Body>
          {/* <div className="card-header">
            <h1 className="card-title">Update KYC</h1>
          </div> */}

          <div className="default-tab">
            <Tab.Container
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={i}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={i} key={i}>
                    {renderTabContent(data.name)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
