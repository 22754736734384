import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();
  const userId = localStorage.getItem("USERID");

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await fetch(environment.apiUrl + `empPayment/all/user/${userId}`);
      const fetchedUsers = await response.json();
      console.log("users :", fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching Payments", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle user deletion
  const onDelete = (id) => {
    axios
      .delete(environment.apiUrl + "empPayment/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error(
          "It cannot be Deleted because it's linked with a supervisor"
        );
      });
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Request For Payment</h4>
                <Link to="/add-empPayment">
                  <button className="btn btn-primary">
                    Add Request For Payment
                  </button>
                </Link>
              </div>
              <div className="card-body">
                <div className="w-100 table-responsive">
                  <div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Party Name</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map((res, index) => (
                          <tr key={res.id}>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                color: res.partyEmployee.party.isBlacklist
                                  ? "red"
                                  : "inherit",
                                textDecoration: res.partyEmployee.party
                                  .isBlacklist
                                  ? "line-through"
                                  : "none",
                              }}
                            >
                              {res.partyEmployee?.party?.contactPerson}
                            </td>
                            <td>{res.expenseAmount}</td>
                            <td>{formatDate(res.date)}</td>
                            {/* <td>{res.isAdmin ? "Admin" : "Not Admin"}</td> */}
                            <td>
                              <div className="btn-group" role="group">
                                <button
                                  onClick={() =>
                                    history.push({
                                      pathname: `/add-empPayment/` + res.id,
                                      state: res,
                                    })
                                  }
                                  className="btn btn-primary"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => onDelete(res.id)}
                                  style={{ marginLeft: "5px" }}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ul className="pagination justify-content-end">
                      {Array.from({
                        length: Math.ceil(users.length / usersPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(index + 1)}
                            className="page-link"
                            style={{ alignItems: "center" }}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
