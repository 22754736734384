/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import profile from "../../../images/user.jpg";
import { environment } from "../../../environments/environment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const id = localStorage.getItem("USERID");

const defaultImageURL =
  "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png";

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  //console.log(sidebarposition);
  //console.log(sidebarLayout);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector('.heart');
    //       function heartBlast() {
    //           return handleheartBlast.classList.toggle("heart-blast");
    //       }
    //       handleheartBlast.addEventListener('click', heartBlast);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [userName, setUserName] = useState(localStorage.getItem("USERNAME"));
  const [userType, setUserType] = useState(localStorage.getItem("USERTYPE"));
  const [roleName, setRoleName] = useState(localStorage.getItem("ROLENAME"));
  const [role, setRole] = useState(localStorage.getItem("ISADMIN"));
  const [imagePreview, setImagePreview] = useState(null);
  const [src, setSrc] = useState(null);
  const [interval, setInterval] = useState();

  const id = localStorage.getItem("USERID");

  const history = useHistory();

  const handleCancel = () => {
    history.push({
      pathname: "/profile",
    });
  };

  useEffect(() => {
    if (id) {
      fetchEmployeeData(id);
    } 
    
    /* interval = setInterval(() => {
      if (id) {
        fetchEmployeeData(id);
      } 
      
  },1000) */
  }, [id]);

  const fetchEmployeeData = async (employeeId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `employee/${employeeId}`
      );
      const employeeData = await response.json();

      let data1 = environment.apiUrl + "employee/files/" + employeeData.picture;
      console.log(data1);
      setSrc(data1);
      
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
      "",
      "dashboard-dark",
      "guest-list",
      "guest-details",
      "concierge-list",
      "room-list",
      "task",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  if (userType == "SuperAdministrator") {
    return (
      <div
        className={`dlabnav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="dlabnav-scroll">
          {/* <Dropdown className="dropdown header-profile2">
            <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
              <div className="header-info2 d-flex align-items-center border">
                <img src={profile} width={20} alt="" />
                <div className="d-flex align-items-center sidebar-info">
                  <div>
                    <span className="font-w700 d-block mb-2">Eren Yeager</span>
                    <small className="text-end font-w400">Super Admin</small>
                  </div>
                  <i className="fas fa-sort-down ms-4"></i>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
              <Link to="/app-profile" className="dropdown-item ai-icon">
                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
                  width={18} height={18} viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
                <span className="ms-2">Profile </span>
              </Link>
              <Link to="/email-inbox" className="dropdown-item ai-icon">
                <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success me-1" width={18}
                  height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
                  strokeLinecap="round" strokeLinejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                  <polyline points="22,6 12,13 2,6" />
                </svg>
                <span className="ms-2">Inbox</span>
              </Link>
              <LogoutPage />
            </Dropdown.Menu>
          </Dropdown>  */}
          <MM className="metismenu" id="menu">
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/dashboard" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/employee" className="ai-icon">
                <i className="fas fa-user"></i>

                <span className="nav-text">Employee</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/party" className="ai-icon">
                <i className="fas fa-cog"></i>
                <span className="nav-text">Party</span>
              </Link>
            </li>

            {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="/setting" >
                  <i className="fas fa-cog"></i>
                  <span className="nav-text">Settings</span>
                </Link> */}
            {/* <ul >
                  <li><Link className={`${path === "app-profile" ? "mm-active" : ""}`} to="#">Organization Setup</Link></li>
                  <li><Link className={`${path === "subscription-list" ? "mm-active" : ""}`} to="/subscription-list">Subscription Fee</Link></li>
                  <li><Link className={`${path === "user-list" ? "mm-active" : ""}`} to="/my-user">User Access</Link></li>
                </ul> */}
            {/* </li> */}

            {/* <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
                <Link to="#" className="ai-icon" >
                  <i className="fas fa-signal"></i>
                  <span className="nav-text">Trading</span>
                </Link>
              </li> */}
            {/* 
              <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
                <Link to="/my-user" className="ai-icon" >
                  <i className="fas fa-signal"></i>
                  <span className="nav-text">My User</span>
                </Link>
              </li> */}

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/varient" className="ai-icon">
                <i className="fas fa-shopping-cart"></i>
                <span className="nav-text">Variant</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/commodity" className="ai-icon">
                <i className="fas fa-store"></i>
                <span className="nav-text">Commodity</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/commodityType" className="ai-icon">
                <i className="fas fa-store"></i>
                <span className="nav-text">Commodity Type</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/business" className="ai-icon">
                <i className="fas fa-user"></i>
                <span className="nav-text">Business Type</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/businessClass" className="ai-icon">
                <i className="fas fa-user"></i>
                <span className="nav-text">Business Class</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/area" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Area</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/unit" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Packaging Type</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/size" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Size</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/quality" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Quality</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/product" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Product</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/vehicle" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Vehicle</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/vehicleClass" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">Vehicle Class</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/calculator" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text"> Freight Calculator </span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/priceType" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text"> Price Type</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/sellingPrice" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text"> Selling Price</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/superAttendance" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text"> Attendance</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/expenseType" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Expense Type</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/imprestBalance" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">
                  <span style={{ whiteSpace: "nowrap" }}>Upload Imprest</span>
                  <br />
                  <span style={{ whiteSpace: "nowrap", marginLeft: "40px" }}>
                    Balance
                  </span>
                </span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/payment" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text"> Payment Request</span>
              </Link>
            </li>

            {/* <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
                <Link to="/report" className="ai-icon" >
                  <i className="fas fa-file-alt"></i>
                  <span className="nav-text">Report</span>
                </Link>
              </li> */}

            {/* <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
                <Link to="#" className="ai-icon" >
                  <i className="fas fa-flag"></i>
                  <span className="nav-text">Reports</span>
                </Link>
              </li> */}
          </MM>
          <div className="dropdown header-profile2 ">
            <div className="header-info2 text-center">
              <img
                src={imagePreview || src || defaultImageURL}
                style={{
                  height: "50px",
                  width: "50px",
                  marginLeft: "10px",
                  marginTop: "15px",
                  borderRadius: "100%",
                }}
              />
              <div className="sidebar-info">
                <div>
                  <h5 className="font-w500 mb-0">{userName}</h5>
                  <span className="fs-12">{userType}</span>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary "
                  onClick={() => history.push("/userProfile/" + id)}
                >
                  View Profile
                </button>
                {/* <Link to={"#"} className="btn btn-primary btn-block btn-md">View Profile</Link> */}
              </div>
              &nbsp;&nbsp;
              <div>
                {/* <button
                  className="btn btn-primary "
                  onClick={() => history.push("/userProfile/" + id)}
                >
                  Update Image
                </button> */}
                {/* <Link to={"#"} className="btn btn-primary btn-block btn-md">View Profile</Link> */}
              </div>
            </div>
          </div>
          <div className="copyright">
            <p className="text-center">
              <strong></strong>
            </p>
            {/* <p className="fs-12 text-center"><span className="heart" style={{color:"whitesmoke",backgroundColor:"whitesmoke"}}></span></p> */}
          </div>
        </PerfectScrollbar>
      </div>
    );
  } else if (userType != "SuperAdministrator" && role == "true") {
    return (
      <div
        className={`dlabnav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="dlabnav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/dashboard" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/adminParty" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Party</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/adminUnit" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Packing Type</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/size" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Size</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/quality" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Quality</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/product" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Product</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/vehicle" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Vehicle</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/adminAttendance" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Attendance</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/adminBalance" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">
                  <span style={{ whiteSpace: "nowrap" }}>Upload Imprest</span>
                  <br />
                  <span style={{ whiteSpace: "nowrap", marginLeft: "40px" }}>
                    Balance
                  </span>
                </span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/adminPayment" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Payment Request</span>
              </Link>
            </li>

            {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="fas fa-cog"></i>
                <span className="nav-text">Settings</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "profile" ? "mm-active" : ""}`}
                    to="/profile"
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "user-list" ? "mm-active" : ""}`}
                    to="/super-user-list"
                  >
                    User Access
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* 
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="#" className="ai-icon">
                <i className="fas fa-signal"></i>
                <span className="nav-text">Trading</span>
              </Link>
            </li> */}

            {/* <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="#" className="ai-icon">
                <i className="fas fa-flag"></i>
                <span className="nav-text">Reports</span>
              </Link>
            </li> */}
          </MM>
          <div className="dropdown header-profile2 ">
            <div className="header-info2 text-center">
              <img
                src={imagePreview || src || defaultImageURL}
                style={{
                  height: "50px",
                  width: "50px",
                  marginLeft: "10px",
                  marginTop: "15px",
                  borderRadius: "100%",
                }}
              />
              <div className="sidebar-info">
                <div>
                  <h5 className="font-w500 mb-0">{userName}</h5>
                  <span className="fs-12">{roleName} (Admin)</span>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary "
                  onClick={() => history.push("/userProfile/" + id)}
                >
                  View Profile
                </button>
                {/* <Link to={"#"} className="btn btn-primary btn-block btn-md">View Profile</Link> */}
              </div>
            </div>
          </div>
          <div className="copyright">
            <p className="text-center">
              <strong></strong>
            </p>
            {/* <p className="fs-12 text-center"><span className="heart" style={{color:"whitesmoke",backgroundColor:"whitesmoke"}}></span></p> */}
          </div>
        </PerfectScrollbar>
      </div>
    );
  } else if (userType != "SuperAdministrator" && role == "false") {
    return (
      <div
        className={`dlabnav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="dlabnav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/dashboard" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/empParty" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Party</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/empUnit" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Packaging Type</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/size" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Size</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/quality" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Quality</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/product" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Product</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/vehicle" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Vehicle</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/empAttendances" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">Attendance</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/empBalance" className="ai-icon">
                <i className="fas fa-home"></i>
                <span className="nav-text">My Imprest</span>
              </Link>
            </li>
            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="/empPayment" className="ai-icon">
                <i className="fas fa-file-signature"></i>
                <span className="nav-text">
                  <span style={{ whiteSpace: "nowrap" }}>Request for</span>
                  <br />
                  <span style={{ whiteSpace: "nowrap", marginLeft: "40px" }}>
                    Payment
                  </span>
                </span>
              </Link>
            </li>

            {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="fas fa-cog"></i>
                <span className="nav-text">Settings</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "profile" ? "mm-active" : ""}`}
                    to="/profile"
                  >
                    My Profile
                  </Link>
                </li>
              </ul>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="#" className="ai-icon">
                <i className="fas fa-signal"></i>
                <span className="nav-text">Trading</span>
              </Link>
            </li>

            <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
              <Link to="#" className="ai-icon">
                <i className="fas fa-flag"></i>
                <span className="nav-text">Reports</span>
              </Link>
            </li> */}
          </MM>
          <div className="dropdown header-profile2 ">
            <div className="header-info2 text-center">
              <img
                src={imagePreview || src || defaultImageURL}
                style={{
                  height: "50px",
                  width: "50px",
                  marginLeft: "10px",
                  marginTop: "15px",
                  borderRadius: "100%",
                }}
              />
              <div className="sidebar-info">
                <div>
                  <h5 className="font-w500 mb-0">{userName}</h5>
                  {userType != "Employee" ? (
                    <span className="fs-12">{userType}</span>
                  ) : (
                    <span className="fs-12">{roleName}</span>
                  )}
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary "
                  onClick={() => history.push("/userProfile/" + id)}
                >
                  View Profile
                </button>
                {/* <Link to={"#"} className="btn btn-primary btn-block btn-md">View Profile</Link> */}
              </div>
            </div>
          </div>
          <div className="copyright">
            <p className="text-center">
              <strong></strong>
            </p>
            {/* <p className="fs-12 text-center"><span className="heart" style={{color:"whitesmoke",backgroundColor:"whitesmoke"}}></span></p> */}
          </div>
        </PerfectScrollbar>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SideBar;
