import React, { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [emp, setEmp] = useState([]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState(null);
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: "/productVarient",
    });
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  const fetchAdmin = async () => {
    try {
      const response = await fetch(environment.apiUrl + "employee/all_admin");
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching employee  :", error);
    }
  };

  function onShow(item) {
    console.log(item);
    axios
      .get(environment.apiUrl + "employee/my_emp/" + item.roleId, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        console.log(response);
        setEmp(response.data);
        setName(item.role.roleName);
        setShow(true);
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  function onHide() {
    setShow(false);
    setEmp([]);
    setName(null);
  }

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "quality/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error(
          "It can not be Deleted because it's linked with a supervisor"
        );
      });
  }

  const adminUsers = users.filter((user) => user.isAdmin);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          {show == false ? (
            <h2
              className="mb-4"
              style={{ textAlign: "center", paddingRight: "30px" }}
            >
              Admins
            </h2>
          ) : (
            <div>
              <div className="row page-titles mx-0">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to="/superAttendance">Admins</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">All Employee List</Link>
                  </li>
                </ol>
              </div>
              <h2
                className="mb-4"
                style={{ textAlign: "center", paddingRight: "30px" }}
              >
                {name}{" "}
                <span style={{ fontSize: "14px" }}>(All Employee List)</span>
              </h2>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          {show == false ? (
            <Link to="/allParty">
              {/* <button className="btn btn-primary" style={{margin:"10px"}}>Add Party</button> */}
            </Link>
          ) : (
            <button
              onClick={() => onHide()}
              className="btn btn-primary"
              style={{ margin: "10px" }}
            >
              Back
            </button>
          )}
        </div>
      </div>

      {show == false ? (
        <div className="row">
          {users.map((user, index) => (
            <div className="col-md-4" key={index}>
              <Card className="mb-3">
                <Card.Header>
                  <h4 className="card-title">{user.name}</h4>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-4">
                      <ul className="list-unstyled">
                        <li>{user.role.roleName}</li>
                      </ul>
                    </div>
                    <div className="col-8" style={{ marginTop: "-15px" }}>
                      <button
                        onClick={() => onShow(user)}
                        className="btn btn-primary"
                        style={{ margin: "10px" }}
                      >
                        View Attendance
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {emp.map((user, index) => (
            <div className="col-md-4" key={index}>
              <Card className="mb-3">
                <Card.Header>
                  <h4 className="card-title">{user.name}</h4>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-6">
                      <ul className="list-unstyled">
                        <li>{user.role.roleName}</li>
                      </ul>
                    </div>
                    <div
                      className="col-6"
                      style={{ textAlign: "right", marginTop: "-10px" }}
                    >
                      {/* <Link to="/empAttendance/"> */}
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          history.push({
                            pathname: `/empAttendance/` + user.id,
                            state: user,
                          })
                        }
                      >
                        View Attendance
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserList;
