import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const[Class, setClass]=useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const {id}=useParams();

  useEffect(()=>{
    if (id) {
        fetchBusinessClassData(id);
      }
  },[id])

  const fetchBusinessClassData = async (businessClassId) => {
    try {
      const response = await fetch(environment.apiUrl + `businessClass/${businessClassId}`);
      if (!response.ok) {
        throw new Error("Error fetching business class data.");
      }

      const businessClassData = await response.json();
      setClass(businessClassData.Class);
    } catch (error) {
      console.error("Error fetching business class data:", error);
      toast.error("Error fetching business class data");
    }
  };



  const handleCancel = () => {
    history.push({
      pathname: "/businessClass",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    // Dispatch your action here
    setIsSubmitting(true)

    if (!Class) {
      toast.error("Class field is required");
      setIsSubmitting(false);
      return;
    }

    const businessClassData = {
     Class
    };

    console.log(businessClassData);

    try {
      const response = await fetch(
        environment.apiUrl + `businessClass/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(businessClassData),
        }
      );

      if (response.ok) {
        toast.success(" Business Class updated successfully!");
        handleCancel();

        history.push({
          pathname: "/businessClass",
          state: { newBusinessClass: businessClassData },
        });
      } else {
        toast.error("Error adding business class.");
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Error adding business class");
    }finally{
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active"><Link to="/businessClass">Business Class</Link></li>
          <li className="breadcrumb-item"><Link to="#">Update Business Type</Link></li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Business class</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                      <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Class
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={Class}
                            onChange={(e) => setClass(e.target.value)}
                            placeholder="Enter business class..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
