import React, { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const history = useHistory();

  const userId = localStorage.getItem("USERID");
  const [myBalance, setMyBalance] = useState(0);

  useEffect(() => {
    fetchEmpImprest();
    myBalanceAmount();
  }, []);

  const fetchEmpImprest = async () => {
    try {
      const response = await fetch(
        environment.apiUrl + "empImprestBalance/all/" + userId
      );
      const fetchedUsers = await response.json();
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching my imprest:", error);
    }
  };

  const myBalanceAmount = async () => {
    try {
      const response = await fetch(
        environment.apiUrl + "empImprestBalance/my_super/" + userId
      );
      const myBalanceData = await response.json();
      console.log(myBalanceData);
      setMyBalance(myBalanceData);
      sessionStorage.setItem("myBalanceAmount", myBalanceData);
    } catch (error) {
      console.error("Error fetching my imprest:", error);
    }
  };

  function onDelete(id, deletedAmount) {
    axios
      .delete(environment.apiUrl + "empImprestBalance/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setMyBalance((prevBalance) => prevBalance + deletedAmount);
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error(
          "It cannot be deleted because it's linked with a supervisor"
        );
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="col-12">
      <Card>
        <Card.Body>
          <div className="default-tab">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add My Imprest.</h4>
                <Link to="/add-empBalance">
                  <button className="btn btn-primary">Add Expense</button>
                </Link>
              </div>
              <div className="card-body">
                <div>
                  {/* Display the summation */}
                  <input
                    placeholder="0"
                    readOnly
                    style={{
                      display: "block",
                      margin: "0 auto",
                      textAlign: "center",
                      borderRadius: "10px",
                      height: "50px",
                      fontSize: "20px",
                    }}
                    value={` ${myBalance.toFixed(2)}`}
                  />
                  <h4
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginBottom: "50px",
                      marginTop: "20px",
                    }}
                  >
                    Imprest Balance
                  </h4>
                </div>

                <div className="w-100 table-responsive">
                  <div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Serial No</th>
                          <th>Party Name</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers &&
                          currentUsers.map((res, index) => (
                            <tr key={res.id}>
                              <td>{index + 1}</td>
                              <td
                                style={{
                                  color: res.party?.isBlacklist
                                    ? "red"
                                    : "inherit",
                                  textDecoration: res.party?.isBlacklist
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {res.party.contactPerson}
                              </td>
                              <td>{res.expenseAmount}</td>
                              <td>{formatDate(res.date)}</td>
                              <td>
                                <div className="btn-group" role="group">
                                  <button
                                    onClick={() =>
                                      history.push({
                                        pathname: `/add-empBalance/` + res.id,
                                        state: res,
                                      })
                                    }
                                    className="btn btn-primary"
                                  >
                                    Edit
                                  </button>
                                  {/* <button
                                    onClick={() =>
                                      onDelete(res.id, res.expenseAmount)
                                    }
                                    style={{ marginLeft: "5px" }}
                                    className="btn btn-danger"
                                  >
                                    Delete
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <ul className="pagination justify-content-end">
                      {Array.from({
                        length: Math.ceil(users.length / usersPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(index + 1)}
                            className="page-link"
                            style={{ alignItems: "center" }}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
