import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const [quality, setQuality] = useState("");
  const [varient, setVarient] = useState("");
  const [varients, setVarients] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    fetchVarientData();
    if (id) {
      fetchQualityData(id);
    }
  }, [id]);

  const fetchQualityData = async (qualityId) => {
    try {
      const response = await fetch(environment.apiUrl + `quality/${qualityId}`);
      if (!response.ok) {
        throw new Error("Error fetching quality data.");
      }

      const qualityData = await response.json();
      setQuality(qualityData.quality);
      setVarient(qualityData.varientId);
    } catch (error) {
      console.error("Error fetching quality data:", error);
      toast.error("Error fetching quality data");
    }
  };

  const fetchVarientData = async () => {
    const data = await fetch(environment.apiUrl + "varient/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setVarients(jsonData);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/quality",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    // Dispatch your action here
    setIsSubmitting(true);

    if (!varient || varient === "Select Option") {
      toast.error("varient field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!quality) {
      toast.error("Quality field is required.");
      setIsSubmitting(false);
      return;
    }

    const qualityData = {
      quality,
      varientId: varient,
    };

    console.log(qualityData);

    try {
      const response = await fetch(
        environment.apiUrl + `quality/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(qualityData),
        }
      );

      if (response.ok) {
        toast.success(" Quality created successfully!");
        handleCancel();

        history.push({
          pathname: "/quality",
          state: { newQuality: qualityData },
        });
      } else {
        toast.error("Error adding quality.");
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Error adding quality");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/quality">Quality</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Update Quality</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Quality</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Variant
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={varient}
                            onChange={(e) => setVarient(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {varients.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.varient}
                              </option>
                            ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Quality
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={quality}
                            onChange={(e) => setQuality(e.target.value)}
                            placeholder="Enter quality type..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
