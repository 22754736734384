import React,{useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import { environment } from "../../../environments/environment";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import ReservationStats from './Dashboard/ReservationStats';
import LatestReview from './Dashboard/LatestReview';
import RecentBooking from './Dashboard/RecentBooking';


const Home = () => {
	const [users, setUsers] = useState(null);
  const [users1, setUsers1] = useState(null);
  const [users2, setUsers2] = useState(null);

  axios.get(environment.apiUrl + "auth/authors", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers(response.data.authors.length)
  );

  axios.get(environment.apiUrl + "auth/authorss", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers1(response.data.authors.length)
  );

  axios.get(environment.apiUrl + "auth/authorsss", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers2(response.data.authors.length)
  );
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);
	
	if(users!=null&&users1!=null&&users2!=null) {
		return(
			<>
				<div className="row">
					<div className="col-xl-12">
						<div className="row">
							<div className="col-xl-12">
								<div className="row">
									<div className="col-xl-6 col-sm-6">
										<div className="card booking">
											<div className="card-body">
												<div className="booking-status d-flex align-items-center">
													<span>
													<img src="https://cdn-icons-png.flaticon.com/512/4149/4149883.png" width="44" height="44" alt="" />
	
													{/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-friends" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="var(--primary)" fill="none" stroke-linecap="round" stroke-linejoin="round"> */}
	  {/* <path stroke="none" d="M0 0h24v24H0z" fill="none"/> */}
	  {/* <circle cx="7" cy="5" r="2" />
	  <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
	  <circle cx="17" cy="5" r="2" />
	  <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
	</svg> */}
													</span>
													<div className="ms-4">
														<h2 className="mb-0 font-w600">{users2}</h2>
														<p className="mb-0">Total Customers</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>		
			</>
		)
	}
	else {
		return(
			<>
				<div className="row">
					<div className="col-xl-12">
						<div className="row">
							<div className="col-xl-12">
								<div className="row">
									<div className="col-xl-6 col-sm-6">
										<div className="card booking">
											<div className="card-body">
												<div className="booking-status d-flex align-items-center">
													<span>
													<img src="https://cdn-icons-png.flaticon.com/512/4149/4149883.png" width="44" height="44" alt="" />
	
													{/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-friends" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="var(--primary)" fill="none" stroke-linecap="round" stroke-linejoin="round"> */}
	  {/* <path stroke="none" d="M0 0h24v24H0z" fill="none"/> */}
	  {/* <circle cx="7" cy="5" r="2" />
	  <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
	  <circle cx="17" cy="5" r="2" />
	  <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
	</svg> */}
													</span>
													<div className="ms-4">
														<h2 className="mb-0 font-w600">0</h2>
														<p className="mb-0">Total Customers</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>		
			</>
		)
	}
	
}
export default Home;