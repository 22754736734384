import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function JQueryValidation(props) {
  const [businessType, setBusinessType] = useState("");
  const [businessClass, setBusinessClass] = useState("");
  const [businessClasses, setBusinessClasses] =useState([])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    fetchBusinessClassData();
    if (id) {
      fetchBusinessTypeData(id);
    }
  }, [id]);

  const fetchBusinessClassData = async()=>{
    const data= await fetch(environment.apiUrl+"businessClass/all")
    const jsonData = await data.json();
    console.log(jsonData);
    setBusinessClasses(jsonData)
  }


  const fetchBusinessTypeData = async (businessTypeId) => {
    try {
      const data = await fetch(environment.apiUrl + `businessType/${businessTypeId}`);
      const businessData = await data.json();
      setBusinessType(businessData.businessType);
      setBusinessClass(businessData.businessClassId);
    } catch (error) {
        console.log(error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/business",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    // Dispatch your action here
    setIsSubmitting(true)

    if (!businessType) {
      toast.error("Business Type is required.");
      setIsSubmitting(false)
      return;
    }
    if (!businessClass) {
      toast.error("Business Class is required.");
      setIsSubmitting(false)
      return;
    }

    const businessData = {
      businessType,
      businessClassId:businessClass,
    };

    console.log(businessData);

    try {
      const response = await fetch(environment.apiUrl + `businesstype/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(businessData),
      });

      if (response.ok) {
        toast.success("Business Type Updated successfully!");
        handleCancel();

        history.push({
          pathname: "/business",
          state: { newBusinessType: businessData },
        });
      } else {
        toast.error("Error adding business type.");
        setIsSubmitting(false)
      }
    } catch (error) {
      toast.error("Error adding business type");
    }finally{
      setIsSubmitting(false)
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active"><Link to="/business">Business Type</Link></li>
          <li className="breadcrumb-item"><Link to="#">Update Business Type</Link></li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Business Type</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Business Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={businessType}
                            onChange={(e) => setBusinessType(e.target.value)}
                            placeholder="Enter business type..."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Class
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={businessClass}
                            onChange={(e) => setBusinessClass(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {businessClasses.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.Class}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
