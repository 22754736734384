import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Col, Row } from "react-bootstrap";

function AddEmployeeForm(props) {
  const history = useHistory();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [division, setDivision] = useState("");
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [terminate, setTerminate] = useState(false);
  const [getRoles, setGetRoles] = useState([]);
  const [getDivisions, setGetDivisions] = useState([]);
  const [src, setSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [roleEmpList, setRoleEmpList] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [_isAdmin, _setIsAdmin] = useState(false);
  const [_terminate, _setTerminate] = useState(false);

  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  console.log("data all", roleEmpList);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    fetchRole();
    fetchDivision();

    if (id) {
      fetchEmployeeData(id);
    }
  }, [id]);

  const fetchRole = async () => {
    try {
      const data = await fetch(environment.apiUrl + "role/all");
      const jsonData = await data.json();
      setGetRoles(jsonData);
    } catch (error) {
      console.log("Error fetching roles:", error);
    }
  };

  const fetchDivision = async () => {
    try {
      const data = await fetch(environment.apiUrl + "division/all");
      const jsonData = await data.json();
      setGetDivisions(jsonData);
    } catch (error) {
      console.log("Error fetching divisions:", error);
    }
  };

  const fetchEmployeeData = async (employeeId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `employee/${employeeId}`
      );
      const employeeData = await response.json();

      setName(employeeData.name);
      setMobileNumber(employeeData.mobileNumber);
      setEmail(employeeData.email);
      setDivision(employeeData.divisionId);
      setRole(employeeData.roleId);
      setIsAdmin(employeeData.isAdmin);
      setTerminate(employeeData.terminate);
      _setIsAdmin(employeeData.isAdmin);
      _setTerminate(employeeData.terminate);
      setImageFile(employeeData.picture);
      let data1 = environment.apiUrl + "employee/files/" + employeeData.picture;
      console.log(data1);
      setSrc(data1);
      const fetchRoleEmp = await fetch(
        environment.apiUrl + `employee/emp/all/${employeeData.roleId}`
      );
      const responseData = await fetchRoleEmp.json();
      setRoleEmpList(responseData);
    } catch (error) {
      console.log("Error fetching employee data:", error);
    }
  };

  const newUpdate = async (item) => {
    const data = {
      userId: item.id,
      roleId: item.roleId,
    };

    console.log(data);
    try {
      const response = await fetch(environment.apiUrl + "employee/emp/all1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        console.log({
          mobileNumber: mobileNumber,
        });
        const formData = new FormData();
        formData.append("name", name);
        formData.append("mobileNumber", mobileNumber);
        formData.append("email", email);
        formData.append("divisionId", division);
        formData.append("roleId", role);
        formData.append("userTypeId", 2);
        //formData.append("isAdmin", isAdmin);
        formData.append("terminate", terminate);
        formData.append("password", "defaultPassword");
        formData.append("picture", imageFile);

        console.log(formData);

        try {
          const response = await fetch(
            environment.apiUrl + `employee/update/${id}`,
            {
              method: "PUT",
              headers: {},
              body: formData,
            }
          );

          if (response.ok) {
            toast.success("Employee updated successfully!");
            handleClose();
            history.push("/employee");
          } else {
            toast.error("Error updating employee. Please try again.");
          }
        } catch (error) {
          console.error("Error updating employee:", error);
          toast.error("Error updating employee. Please try again.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("image", imagePreview);

  const handleCancel = () => {
    /* setName("");
    setMobileNumber("");
    setEmail("");
    setDivision("");
    setRole("");
    setIsAdmin(false);
    setTerminate(false); */
    history.push({
      pathname: "/employee",
    });
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!name) {
      toast.error("Name is required.");
      setIsSubmitting(false);
      return;
    }
    if (!mobileNumber) {
      toast.error("MobileNumber is required.");
      setIsSubmitting(false);
      return;
    }

    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error("Mobile Number should contain exactly 10 digits.");
      setIsSubmitting(false);
      return;
    }

    if (email) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailPattern.test(email)) {
        toast.error("Please enter a valid email address.");
        setIsSubmitting(false);
        return;
      }
    }

    if (!division || division === "Select Option") {
      toast.error("  Division is required.");
      setIsSubmitting(false);
      return;
    }

    if (!role || role === "Select Option") {
      toast.error(" Role  is required.");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("divisionId", division);
    formData.append("roleId", role);
    formData.append("userTypeId", 2);
    formData.append("isAdmin", isAdmin);
    formData.append("terminate", terminate);
    formData.append("password", "defaultPassword");
    formData.append("picture", imageFile);

    console.log(formData);

   
  if(_isAdmin===true && _terminate===false && isAdmin===true && terminate===true){
    try {
      const response = await fetch(
        environment.apiUrl + `employee/update/${id}`,
        {
          method: "PUT",
          headers: {},
          body: formData,
        }
      );

      const responseData = await response.json();


      if (response.ok) {
        handleOpen();

        // if (responseData.openModal===true) {
        //   console.log("hello");
        //   console.log("response", response)
        //   handleOpen();
        // } 
        // else {
        //   toast.success("Employee updated successfully!");

        //   history.push("/employee");
        // }
      }
      //  else {
      //   if (isAdmin == true) {
      //     toast.error("An admin is already exist in this role");
      //     setIsSubmitting(false);
      //   } else {
      //     toast.error("Error updating employee. Please try again.");
      //     setIsSubmitting(false);
      //   }
      // }
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Error updating employee. Please try again.");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  }else if(_isAdmin===false && _terminate===false && isAdmin===true && terminate===true){
        toast.error("This employee is Terminated we cannot make it as a Admin");
  }else{
    try {
      const response = await fetch(
        environment.apiUrl + `employee/update/${id}`,
        {
          method: "PUT",
          headers: {},
          body: formData,
        }
      );

      const responseData = await response.json();


      if (response.ok) {
        toast.success("Employee updated successfully!");
        history.push("/employee");
        

        //   history.push("/employee");

        // if (responseData.openModal===true) {
        //   console.log("hello");
        //   console.log("response", response)
        //   handleOpen();
        // } 
        // else {
        //   toast.success("Employee updated successfully!");

        //   history.push("/employee");
        // }
      }
       else {
        if (isAdmin == true) {
          toast.error("An admin is already exist in this role");
          setIsSubmitting(false);
        } else {
          toast.error("Error updating employee. Please try again.");
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.error("Error updating employee:", error);
      toast.error("Error updating employee. Please try again.");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  }


   

  
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    // Preview the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const defaultImageURL =
    "https://img.freepik.com/free-icon/user_318-150864.jpg";

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/employee">Employee</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Edit Employee</Link>
          </li>
        </ol>
      </div>
      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>

          <div>
            {roleEmpList.length > 0 ? (
              <Box sx={style}>
                {roleEmpList.map((res, index) => (
                  <Row>
                    <Col md={6}>
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "bold",
                        }}
                      >
                        {index + 1} {res.name}
                      </span>
                    </Col>
                    <Col md={6} style={{ marginBottom: "10px" }}>
                      <button
                        onClick={() => newUpdate(res)}
                        //disabled={true}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </Col>
                  </Row>
                ))}
              </Box>
            ) : (
              <span>Data not found</span>
            )}
          </div>
        </Box>
      </Modal>

      {!open ? (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Update Employee</h4>
              </div>
              <div className="card-body">
                <div className="form-validation">
                  <form
                    className="form-valide"
                    action="#"
                    method="post"
                    onSubmit={onAddUser}
                  >
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group mb-3 row">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-6">
                            {/* <img
                            style={{
                              margin: "auto",
                              display: "block",
                              width: "100px",
                              cursor: "pointer",
                            }}
                            src="https://img.freepik.com/free-icon/user_318-150864.jpg"
                            alt="User Avatar"
                          /> */}

                            <label htmlFor="image-upload">
                              {imagePreview !== null ? (
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                  src={imagePreview}
                                  alt="User Avatar"
                                />
                              ) : (
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                  src={src ? src : defaultImageURL}
                                  alt="User Avatar"
                                />
                              )}
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              id="image-upload"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-username"
                          >
                            Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="val-username"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Enter full name.."
                            />
                          </div>

                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-username"
                          >
                            Mobile Number
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="val-username"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              placeholder="Enter mobile number.."
                            />
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-username"
                          >
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="val-username"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter email.."
                            />
                          </div>

                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-division"
                          >
                            Division
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <select
                              className="form-control"
                              id="val-division"
                              name="val-division"
                              value={division}
                              onChange={(e) => setDivision(e.target.value)}
                            >
                              <option value="Select Option">
                                Select Option
                              </option>
                              {getDivisions.map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.divisionName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-division"
                          >
                            Role
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <select
                              className="form-control"
                              id="val-role"
                              name="val-role"
                              value={role}
                              onChange={(e) => setRole(e.target.value)}
                            >
                              <option value="Select Option">
                                Select Option
                              </option>
                              {getRoles.map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.roleName}
                                </option>
                              ))}
                            </select>
                          </div>

                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-is-admin"
                          >
                            Is Admin
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="isAdmin"
                                  value="true"
                                  checked={isAdmin}
                                  onChange={() => setIsAdmin(true)}
                                  required
                                />
                                Yes
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="isAdmin"
                                  value="false"
                                  checked={!isAdmin}
                                  onChange={() => setIsAdmin(false)}
                                  required
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-2 col-form-label"
                            htmlFor="val-terminate"
                          >
                            Terminate
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-4">
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="terminate"
                                  value="true"
                                  checked={terminate}
                                  onChange={() => setTerminate(true)}
                                  // onClick={() => handleOpen()}
                                  required
                                />
                                Yes
                              </label>
                            </div>

                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="terminate"
                                  value="false"
                                  checked={!terminate}
                                  onChange={() => setTerminate(false)}
                                  required
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <div className="col-lg-8 ms-auto">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {roleEmpList.length > 0 ? (
            <Box sx={style}>
              {roleEmpList.map((res, index) => (
                <Row>
                  <Col md={6}>
                    <span
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1} {res.name}
                    </span>
                  </Col>
                  <Col md={6} style={{ marginBottom: "10px" }}>
                    <button
                      // onClick={newUpdate(res)}

                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Update
                    </button>
                    {/* <span
                              style={{
                                backgroundColor: "#56B349",
                                color: "white",
                                fontWeight: "bold",
                                marginBottom: "5px",
                                borderRadius: "5px",
                              }}
                              
                            >
                              Button
                            </span> */}
                  </Col>
                </Row>
              ))}
            </Box>
          ) : (
            <span>Data not found</span>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default AddEmployeeForm;
