import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import axios from "axios";

function AddVarientForm(props) {
  const history = useHistory();
  const [expenseType, setExpenseType] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [date, setDate] = useState("");
  const [party, setParty] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [narration, setNarration] = useState("");
  const [parties, setParties] = useState([]);
  const [docPdf, setDocPdf] = useState(null);
  const [billImage, setBillImage] = useState(null);
  const [partyUsers, setPartyUsers] = useState([]);
  const [division, setDivision] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const USERID = localStorage.getItem("USERID");

  useEffect(() => {
    fetchExpenseType();

    if (USERID) {
      fetchPartyUsers();
    }
  }, [USERID]);

  useEffect(() => {
    if (USERID) {
      fetchEmployeeById(USERID);
    }
  }, [USERID]);

  const fetchPartyUsers = async () => {
    try {
      const response = await axios.get(
        environment.apiUrl + "party/all/" + USERID,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        }
      );
      setPartyUsers(response.data);
    } catch (error) {
      console.error("Error fetching party users:", error);
    }
  };

  const fetchEmployeeById = async (USERID) => {
    const data = await fetch(environment.apiUrl + `employee/${USERID}`);
    const jsonData = await data.json();
    console.log("user division data :", jsonData);
    setDivision(jsonData.division);
  };

  const fetchExpenseType = async () => {
    const data = await fetch(environment.apiUrl + "expenseType/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setExpenseTypes(jsonData);
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setBillImage(imageFile);
  };

  const handlePdf = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/empPayment",
    });
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!expenseType || expenseType === "Select Option") {
      toast.error("Expense Type field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!party || party === "Select Option") {
      toast.error("Party field is required.");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("expenseTypeId", expenseType);
    formData.append("expenseAmount", expenseAmount);
    formData.append("date", date);
    formData.append("partyEmployeeId", party);
    formData.append("bankDetails", bankDetails);
    formData.append("narration", narration);
    formData.append("isUrgent", isUrgent);
    formData.append("userTableId", USERID);
    formData.append("divisionId", division.id);

    if (billImage) {
      formData.append("uploadBill", billImage);
    }
    if (docPdf) {
      formData.append("uploadPdf", docPdf);
    }
    console.log(formData);
    try {
      const response = await fetch(environment.apiUrl + "empPayment/create", {
        method: "POST",
        headers: {},
        body: formData,
      });
      const responseData = await response.json();
      if (response.ok) {
        toast.success("Employee payment created successfully!");
        handleCancel();
      } else {
        if (responseData && responseData.message) {
          toast.error(responseData.message);
        } else {
          toast.error("Error adding employee payment. Please try again.");
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Error adding employee payment. Please try again");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/empPayment">Request for Payment</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Submit Request For Payment</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Submit Request For Payment</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Expense Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={expenseType}
                            onChange={(e) => setExpenseType(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {expenseTypes.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res?.expenseType}
                              </option>
                            ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Expense Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={expenseAmount}
                            onChange={(e) => setExpenseAmount(e.target.value)}
                            placeholder="Enter amount value.."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Date
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="date"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder="Enter date.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Choose Party
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={party}
                            onChange={(e) => setParty(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {partyUsers
                              .filter((item) => item.isActive)
                              .map((res) => (
                                <option
                                  value={res.id}
                                  key={res.id}
                                  style={{
                                    color: res.party.isBlacklist
                                      ? "red"
                                      : "inherit",
                                  }}
                                >
                                  {res.party.contactPerson}
                                </option>
                              ))}
                            {/* <option>party Type 1</option> */}
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label className="col-lg-2 col-form-label">
                          Upload Bill Copy
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </div>
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Bank Details
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={bankDetails}
                            onChange={(e) => setBankDetails(e.target.value)}
                            placeholder="Enter bank details.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Narration
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={narration}
                            onChange={(e) => setNarration(e.target.value)}
                            placeholder="Enter Narration.."
                          />
                        </div>
                        <label className="col-lg-2 col-form-label">
                          Upload pdf
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept=".pdf"
                            onChange={handlePdf}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          is Urgent
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="true"
                                checked={isUrgent}
                                onChange={() => setIsUrgent(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="false"
                                checked={!isUrgent}
                                onChange={() => setIsUrgent(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Division
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={division?.divisionName}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddVarientForm;
