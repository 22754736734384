import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import {  Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const Myuser = () => {
    
  return (
    <div className="col-12">

<Card>
        <Card.Body>
        </Card.Body>
      </Card>
      
      
    </div>
  );
  };

export default Myuser;
