import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from 'react-redux';
import { Formik } from "formik";
import * as Yup from "yup";
import { editSubscription
} from '../../../store/actions/AuthActions';
import axios from "axios";
import { environment } from "../../../environments/environment";


function JQueryValidation (props) {
    const [title, setTitle] = useState();
    const [day, setDay] = useState();
    const [amount, setAmount] = useState();

    const dispatch = useDispatch();

    const params = useParams();
    const [detail, setDetail] = useState(null);

    const id = params.id;
    useEffect(async () => {
        let result = await fetch(environment.apiUrl + "account/author/" + id,{
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
          });
        result = await result.json();
        setDetail(result.author);
        setTitle(result.author.title);
        setDay(result.author.day);
        setAmount(result.author.amount);
      },[])

    function onAddUser(e) {
		  e.preventDefault();
      dispatch(editSubscription(id, title, day, amount, props.history));
    }

    if(detail!=null) {
        return (
            <Fragment>
              <div className="row page-titles mx-0">
			<ol className="breadcrumb">
				<li className="breadcrumb-item active"><Link to="/subscription-list">Subscription List</Link></li>
				<li className="breadcrumb-item  "><Link to="">Edit Subscription</Link></li>
			</ol>
      </div>
        
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Edit Subscription</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-validation">
                        <form
                          className="form-valide"
                          action="#"
                          method="post"
                          onSubmit={onAddUser}
                        >
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="form-group mb-3 row">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Title
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-username"
                                    name="val-username"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter a title.."
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3 row">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-email"
                                >
                                  Vaidity (in Days) <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="val-day"
                                    name="val-day"
                                    value={day}
                                    onChange={(e) => setDay(e.target.value)}
                                    placeholder="Days"
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3 row">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-email"
                                >
                                  Subscription Fees <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="val-fee"
                                    name="val-fee"
                                    value={amount}
                                                  onChange={(e) => setAmount(e.target.value)}
                                    placeholder="Amount"
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3 row">
                                <div className="col-lg-8 ms-auto">
                                  <button type="submit" className="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
    }
    else {
        return (
            <Fragment>
              <div className="row page-titles mx-0">
			<ol className="breadcrumb">
				<li className="breadcrumb-item active"><Link to="/subscription-list">Subscription List</Link></li>
				<li className="breadcrumb-item  "><Link to="">Edit Subscription</Link></li>
			</ol>
      </div>
        
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Add Subscription</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-validation">
                        <form
                          className="form-valide"
                          action="#"
                          method="post"
                          onSubmit={onAddUser}
                        >
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="form-group mb-3 row">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Title
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-username"
                                    name="val-username"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter a title.."
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3 row">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-email"
                                >
                                  Vaidity (in Days) <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="val-day"
                                    name="val-day"
                                    value={day}
                                    onChange={(e) => setDay(e.target.value)}
                                    placeholder="Days"
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3 row">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-email"
                                >
                                  Subscription Fees <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="val-fee"
                                    name="val-fee"
                                    value={amount}
                                                  onChange={(e) => setAmount(e.target.value)}
                                    placeholder="Amount"
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3 row">
                                <div className="col-lg-8 ms-auto">
                                  <button type="submit" className="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
    }
};

export default JQueryValidation;
