import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const [employee, setEmployee] = useState("");
  const [amount, setAmount] = useState("");
  const [expense, setExpense] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [date, setDate] = useState("");
  const [employees, setEmployees] = useState([]);
  const [docPdf, setDocPdf] = useState(null);
  const history = useHistory();
  const currentDate = new Date();

  const userTableId = localStorage.getItem("USERID");
  const myBalanceAmount = sessionStorage.getItem("myBalanceAmount");
  const [src, setSrc] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchEmployeeData();
    fetchExpenseTypeData();
    if (id) {
      fetchEmpImprest(id);
    }
  }, [id]);

  const fetchEmployeeData = async () => {
    const data = await fetch(environment.apiUrl + "party/all/" + userTableId);
    const jsonData = await data.json();
    // console.log(jsonData);
    setEmployees(jsonData);
  };

  const fetchExpenseTypeData = async () => {
    const data = await fetch(environment.apiUrl + "expenseType/all");
    const jsonData = await data.json();
    // console.log(jsonData);
    setExpenseTypes(jsonData);
  };

  const fetchEmpImprest = async (empId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `empImprestBalance/${empId}`
      );
      const empImprestData = await response.json();
      console.log(empImprestData);
      setEmployee(empImprestData.partyId);
      setAmount(empImprestData.expenseAmount);
      setExpense(empImprestData.expenseTypeId);
      const formattedDate = new Date(empImprestData.date)
        .toISOString()
        .split("T")[0];
      setDate(formattedDate);
      setDocPdf(empImprestData.docPdf);

      let data2 =
        environment.apiUrl +
        "empImprestBalance/files/" +
        empImprestData.uploadBill;
      console.log(data2);
      setSrc(data2);
    } catch (error) {
      console.log("Error fetching imprest data:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/empBalance",
    });
  };

  const handleBillUpload = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };

  const dispatch = useDispatch();

  console.log(employee);

  const onAddUser = async (e) => {
    e.preventDefault();
    // console.log("saved");

    const formData = new FormData();

    formData.append("expenseTypeId", expense);
    formData.append("expenseAmount", amount);
    formData.append("date", date);
    formData.append("partyId", employee);
    formData.append("userTableId", userTableId);

    if (docPdf) {
      formData.append("uploadBill", docPdf);
    }

    console.log(formData);

    try {
      const response = await fetch(
        environment.apiUrl + `empImprestBalance/update/${id}`,
        {
          method: "PUT",
          headers: {},
          body: formData,
        }
      );

      if (response.ok) {
        toast.success(" My Imprest Updated successfully!");
        handleCancel();
      } else {
        toast.error("Error update my imprest.");
      }
    } catch (error) {
      toast.error("Error update my imprest");
    }
  };

  const maxDate = new Date(currentDate);
  maxDate.setDate(currentDate.getDate() + 15);

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/empBalance">My Imprest</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add My Imprest </Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add My Imprest </h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Expense Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={expense}
                            onChange={(e) => setExpense(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {expenseTypes.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.expenseType}
                              </option>
                            ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Expense Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter Amount value..."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Date
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="date"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={date}
                            min={currentDate.toISOString().slice(0, 10)}
                            max={maxDate.toISOString().slice(0, 10)}
                            onChange={(e) => {
                              const selectedDate = e.target.value;
                              setDate(selectedDate);
                            }}
                            placeholder="Enter date..."
                          />
                        </div>
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Choose Party
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          {employees.length > 0 ? (
                            <select
                              className="form-control"
                              id="val-role"
                              name="val-role"
                              value={employee}
                              onChange={(e) => setEmployee(e.target.value)}
                            >
                              <option value="Select Option">
                                Select Option
                              </option>

                              {employees.map((data) => (
                                <option
                                style={{
                                  color: data.party.isBlacklist
                                    ? "red"
                                    : "inherit",
                                  textDecoration: data.party.isBlacklist
                                    ? "line-through"
                                    : "none",
                                }}
                                  value={data.party.id}
                                  key={data.party.id}
                                >
                                  {data.party.contactPerson}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <span>No party available</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label className="col-lg-2 col-form-label">
                          Upload Bill Copy
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            // name="uploadBill"
                            accept=".pdf"
                            onChange={handleBillUpload}
                          />
                        </div>
                        <div className="col-4" style={{ marginTop: "10px" }}>
                          <a target="_blank" href={src}>
                            View Document as Pdf
                          </a>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
