import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import {  Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [users1, setUsers1] = useState(null);
  const [users2, setUsers2] = useState(null);

  const history = useHistory();

  axios.get(environment.apiUrl + "auth/authors", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers(response.data.authors)
  );

  axios.get(environment.apiUrl + "auth/authorss", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers1(response.data.authors)
  );

  axios.get(environment.apiUrl + "auth/authorsss", {
    headers: {
      Authorization: "bearer " + localStorage.getItem("MYTOKEN")
    }
  })
    .then(response => 
    setUsers2(response.data.authors)
  );

  const tabData = [
    {
      name: "Advisor",
      icon: "user",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Customer",
      icon: "user",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
  ];

  function onDelete(id) {
    axios.delete(environment.apiUrl + "auth/user/" + id, {
      headers: {
        Authorization: "bearer " + localStorage.getItem("MYTOKEN")
      }
    })
      .then(response =>
        toast.success('Successfully Changed the status')
    );
  }
    
    if(users!=null&&users1!=null&&users2!=null) {
      return (
        <div className="col-12">

<Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className="default-tab">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} me-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>

{ data.name == 'Advisor' && 
                        <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Advisor List</h4>
                          <Link to="/add-advisor">
                          <button className="btn btn-primary">
                            Add Advisor
                          </button>
                          </Link>
                        </div>
                        <div className="card-body">
                          <div className="w-100 table-responsive">
                            <div>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Full Name</th>
                                    <th>User Code</th>
                                    <th>Email ID</th>
                                    <th>Mobile No</th>
                                    <th style={{textAlign:"center"}}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {users1.map((i, index) => 
                                  <tr>
                                    <td>{index+1}</td>
                                    <td>{i.name}</td>
                                    <td>{i.code}</td>
                                    <td>{i.email}</td>
                                    <td>{i.phoneNumber}</td>
                                    <td>
                                      <button onClick={() => history.replace("edit-user/"+ i.id)} className="btn btn-primary">Edit</button>

                                      {i.status == true ? <button onClick={() => onDelete(i.id)} style={{marginLeft:"5px"}} className="btn btn-danger">De-Active Now</button> : <button onClick={() => onDelete(i.id)} style={{marginLeft:"5px"}} className="btn btn-warning">Active Now</button>}
                                      
                                    </td>
                                  </tr>
                                )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                                    </div>
    }


{ data.name == 'Customer' && 
                        <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Customer List</h4>
                          <Link to="/add-customer">
                          <button className="btn btn-primary">
                            Add Customer
                          </button>
                          </Link>
                        </div>
                        <div className="card-body">
                          <div className="w-100 table-responsive">
                            <div>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Full Name</th>
                                    <th>User Code</th>
                                    <th>Email ID</th>
                                    <th>Mobile No</th>
                                    <th style={{textAlign:"center"}}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {users2.map((i, index) => 
                                  <tr>
                                    <td>{index+1}</td>
                                    <td>{i.name}</td>
                                    <td>{i.code}</td>
                                    <td>{i.email}</td>
                                    <td>{i.phoneNumber}</td>
                                    <td>
                                      <button onClick={() => history.replace("edit-user/"+ i.id)} className="btn btn-primary">Edit</button>

                                      {i.status == true ? <button onClick={() => onDelete(i.id)} style={{marginLeft:"5px"}} className="btn btn-danger">De-Active Now</button> : <button onClick={() => onDelete(i.id)} style={{marginLeft:"5px"}} className="btn btn-warning">Active Now</button>}
                                      
                                    </td>
                                  </tr>
                                )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                                    </div>
    }
                        
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
          
          
        </div>
      );
    }
    else {
      return (
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">User List</h4>
              <Link to="/add-user">
              <button className="btn btn-primary">
                Add User
              </button>
              </Link>
            </div>
            <div className="card-body">
              <div className="w-100 table-responsive">
                <div>
                  {/* <table
                    id="example5"
                    className="display dataTable no-footer w-100"
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label=": activate to sort column descending"
                          style={{ width: 32 }}
                        >
                          <div className="form-check custom-checkbox">
                            <input
                              type="checkbox"
                              onClick={() => chackboxFun("all")}
                              className="form-check-input"
                              id="checkAll"
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkAll"
                            />
                          </div>
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient ID: activate to sort column ascending"
                          style={{ width: 73 }}
                        >
                          Patient ID
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Date Check in: activate to sort column ascending"
                          style={{ width: 100 }}
                        >
                          Date Check in
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Patient Name: activate to sort column ascending"
                          style={{ width: 100 }}
                        >
                          Patient Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Doctor Assgined: activate to sort column ascending"
                          style={{ width: 120 }}
                        >
                          Doctor Assgined
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Disease: activate to sort column ascending"
                          style={{ width: 62 }}
                        >
                          Disease
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Status: activate to sort column ascending"
                          style={{ width: 106 }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Room no: activate to sort column ascending"
                          style={{ width: 66 }}
                        >
                          Room no
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Action: activate to sort column ascending"
                          style={{ width: 47 }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" className="odd">
                        <td className="sorting_1">
                          <div className="form-check custom-checkbox ">
                            <input
                              type="checkbox"
                              onClick={() => chackboxFun()}
                              className="form-check-input"
                              id="customCheckBox2"
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            />
                          </div>
                        </td>
                        <td>#P-00001</td>
                        <td>26/02/2022, 12:42 AM</td>
                        <td>Tiger Nixon</td>
                        <td>Dr. Cedric</td>
                        <td>Cold &amp; Flu</td>
                        <td>
                          <span className="badge light badge-danger">
                            <i className="fa fa-circle text-danger me-1" />
                            New Patient
                          </span>
                        </td>
                        <td>AB-001</td>
                        <td>
                          <Dropdown className="dropdown ms-auto text-right">
                            <Dropdown.Toggle
                              variant=""
                              className="btn-link i-false"
                              data-toggle="dropdown"
                            >
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x={0} y={0} width={24} height={24} />
                                  <circle fill="#000000" cx={5} cy={12} r={2} />
                                  <circle fill="#000000" cx={12} cy={12} r={2} />
                                  <circle fill="#000000" cx={19} cy={12} r={2} />
                                </g>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                              <Dropdown.Item>Accept Patient</Dropdown.Item>
                              <Dropdown.Item>Reject Order</Dropdown.Item>
                              <Dropdown.Item>View Details</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                      <tr role="row" className="even">
                        <td className="sorting_1">
                          <div className="form-check custom-checkbox ">
                            <input
                              type="checkbox"
                              onClick={() => chackboxFun()}
                              className="form-check-input"
                              id="customCheckBox3"
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox3"
                            />
                          </div>
                        </td>
                        <td>#P-00002</td>
                        <td>28/02/2022, 12:42 AM</td>
                        <td>Garrett Winters</td>
                        <td>Dr. Cedric</td>
                        <td>Sleep Problem</td>
                        <td>
                          <span className="badge light badge-warning">
                            <i className="fa fa-circle text-warning me-1" />
                            In Treatment
                          </span>
                        </td>
                        <td>AB-002</td>
                        <td>
                          <Dropdown className="dropdown ms-auto text-right">
                            <Dropdown.Toggle
                              variant=""
                              className="btn-link i-false"
                              data-toggle="dropdown"
                            >
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x={0} y={0} width={24} height={24} />
                                  <circle fill="#000000" cx={5} cy={12} r={2} />
                                  <circle fill="#000000" cx={12} cy={12} r={2} />
                                  <circle fill="#000000" cx={19} cy={12} r={2} />
                                </g>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                              <Dropdown.Item>Accept Patient</Dropdown.Item>
                              <Dropdown.Item>Reject Order</Dropdown.Item>
                              <Dropdown.Item>View Details</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
    
                  {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                    <div className="dataTables_info">
                      Showing {activePag.current * sort + 1} to{" "}
                      {data.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : data.length}{" "}
                      of {data.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example5_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="/table-datatable-basic"
                        onClick={() =>
                          activePag.current > 0 && onClick(activePag.current - 1)
                        }
                      >
                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                      </Link>
                      <span>
                        {paggination.map((number, i) => (
                          <Link
                            key={i}
                            to="/table-datatable-basic"
                            className={`paginate_button  ${
                              activePag.current === i ? "current" : ""
                            } `}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="/table-datatable-basic"
                        onClick={() =>
                          activePag.current + 1 < paggination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

export default UserList;
