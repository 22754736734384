import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import PatientTable from "../table/PatientTable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { environment } from "../../../environments/environment";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [src, setSrc] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [imprestBalanceAmount, setImprestBalanceAmount] = useState([]);

  const userId = localStorage.getItem("imprestUserId");

  useEffect(() => {
    if (userId) {
      fetchImprestTotalBalance(userId);
    }
  }, [userId]);

  const fetchImprestTotalBalance = async (userId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `imprestBalance/all/${userId}`
      );
      const responseData = await response.json();
      console.log("response Data : ", responseData);
      setImprestBalanceAmount(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  let totalImprestBalance = 0;

  for (let index = 0; index < imprestBalanceAmount.length; index++) {
    totalImprestBalance += imprestBalanceAmount[index].expenseAmount;
  }

  useEffect(() => {
    if (id) {
      fetchImprestBalance(id);
    }
  }, [id]);

  const fetchImprestBalance = async (id) => {
    try {
      const response = await fetch(
        environment.apiUrl + "imprestBalance/all/" + id
      );
      const fetchedUsers = await response.json();
      // let data2 =
      //   environment.apiUrl +
      //   "empImprestBalance/files/" +
      //   fetchedUsers.uploadBill;
      // console.log(data2);
      // setSrc(data2);
      console.log(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching Imprest Balance :", error);
    }
  };

  function onDelete(id) {
    axios
      .delete(environment.apiUrl + "imprestBalance/delete/" + id, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
        },
      })
      .then((response) => {
        toast.success("Successfully Deleted");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      })
      .catch((error) => {
        toast.error("It can not be Deleted because its link with supervisor");
      });
  }

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const calculateTotalExpense = () => {
    let total = 0;
    currentUsers.forEach((user) => {
      total += user.expenseAmount;
    });
    return total;
  };

  const redirect = () => {
    history.push({
      pathname: `/add-adminBalance/balance/` + id,
    });
  };

  const handleForm = () => {};
  if (users != null) {
    return (
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="default-tab">
              <div className="card">
                <div className="card-header" style={{ justifyContent: "end" }}>
                  <button className="btn btn-primary " onClick={redirect}>
                    Add
                  </button>
                </div>
                {/* {users.map((res) => (
                  <div
                    className="card-header"
                    style={{ justifyContent: "end" }}
                  >
                    <button
                      className="btn btn-primary "
                      onClick={() =>
                        history.push({
                          pathname: `/add-imprestBalance/balance/` + res.id,
                        })
                      }
                    >
                      Add
                    </button>
                  </div>
                ))} */}
                <div className="card-body">
                  <div className="w-100 table-responsive">
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Imprest Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers &&
                            currentUsers
                              .filter((data) => (data.isActive = true))
                              .map((res, index) => (
                                <tr>
                                  <td>
                                    {
                                      new Date(res.date)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                  </td>
                                  <td>{res.expenseAmount}</td>
                                </tr>
                              ))}
                          {/* <tr>
                            <td
                              colSpan="4"
                              className="text-right"
                              style={{ fontWeight: "bold" }}
                            >
                              Total Amount:
                              <span
                                style={{
                                  marginLeft: "196px",
                                  fontWeight: "bold",
                                }}
                              >
                                {calculateTotalExpense()}
                              </span>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                      <ul className="pagination justify-content-end">
                        {Array.from({
                          length: Math.ceil(users.length / usersPerPage),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              index + 1 === currentPage ? "active" : ""
                            }`}
                          >
                            <button
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ alignItems: "center" }}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="d-flex">
                        <div>
                          <label
                            // className="col-lg-2 col-form-label"
                            htmlFor="val-imp"
                            style={{ marginLeft: "5px", marginTop:"9px" }}
                          >
                            Total Imprest Balance : 
                         
                          </label>
                        </div>
                        <div className="col-lg-3" style={{marginLeft:"40px"}}>
                          <input
                            type="text"
                            className="form-control"
                            id="val-imp"
                            name="val-imp"
                            value={totalImprestBalance}
                            readOnly
                            placeholder="Total Imprest Balance"
                          style={{textAlign:"center"}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Size</h4>
            <Link to="/add-size">
              <button className="btn btn-primary">Add Size</button>
            </Link>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default UserList;
