import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const [employee, setEmployee] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [employees, setEmployees] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    const data = await fetch(environment.apiUrl + "employee/all_emp");
    const jsonData = await data.json();
    console.log(jsonData);
    setEmployees(jsonData);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/adminBalance",
    });
  };

  

  const onAddUser = async (e) => {
    e.preventDefault();
    console.log("saved");
    setIsSubmitting(true)

    const BalanceData = {
      date,
      expenseAmount: amount,
      userTableId: employee,
      type:"Add"
    };

    console.log(BalanceData);

    try {
      const response = await fetch(
        environment.apiUrl + "adminImprestBalance/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(BalanceData),
        }
      );

      if (response.ok) {
        toast.success(" Imprest Balance created successfully!");
        handleCancel();

        history.push({
          pathname: "/adminBalance",
          state: { newBalance: BalanceData },
        });
      } else {
        toast.error("Employee already exists.");
        setIsSubmitting(false)
      }
    } catch (error) {
      toast.error("Error adding imprest Balance");
      setIsSubmitting(false)
    }finally{
      setIsSubmitting(false)
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/adminBalance">Upload Imprest Balance</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Imprest Balance</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Imprest Balance</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Employee
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={employee}
                            onChange={(e) => setEmployee(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {employees
                              .filter((res) => res.isAdmin == false)
                              .map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.name}
                                </option>
                              ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Expense Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={amount}
                            onChange={(e) =>
                              setAmount(parseFloat(e.target.value))
                            }
                            placeholder="Enter Amount value..."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Date
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="date"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={date}
                            onChange={(e) => {
                              const selectedDate = e.target.value;
                              setDate(selectedDate);
                            }}
                            placeholder="Enter date..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                          disabled={isSubmitting}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
