import React, { useState, useEffect } from "react";
import { Card, Tab, Nav, Col, Row, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Autocomplete, MenuItem } from "@mui/material";
import Geocode from "react-geocode";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [areaId, setArea] = useState("");
  const [businessTypeId, setBusiness] = useState("");
  const [commodities, setCommodities] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [documentNumber, setDocNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [brandMarka, setBrandMarka] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [UserID, setUser] = useState("");
  const [accessUser, setAccessUser] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [address, setAddress] = useState("");
  const [notes, setNote] = useState("");
  const [selectPdf, setSelectedpdf] = useState(null);
  const [image, setPartyImage] = useState(null);
  const [docImage, setDocImage] = useState(null);
  const [docImage2, setDocImage2] = useState(null);
  const [docPdf, setDocPdf] = useState(null);
  const [docTypes, setDocTypes] = useState([]);
  const [isBlackList, setIsBlackList] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  const id = areaId;

  const handleOriginSelect = (place) => {
    const selectedAddress = place && place.formatted_address;
    setAddress(selectedAddress || "");
  };

  useEffect(() => {
    if (id) {
      fetchAreaData(id);
    }
  }, [id]);

  const fetchAreaData = async (areaId) => {
    const areaData = await fetch(environment.apiUrl + `area/${areaId}`);
    const responseData = await areaData.json();
    console.log("area all data", responseData);
    setAddress(responseData.area);
  };

  // Dialog form for area
  const [open, setOpen] = useState(false);
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [dialogArea, setDialogArea] = useState("");
  const [district, setDistrict] = useState("");
  const [districts, setDistricts] = useState([]);

  // Geocode.setApiKey(environment.mapKey);

  const accessUserOptions = [
    {
      value: "all",
      label: "All",
    },
    ...accessUser
      .filter((user) => user.isAdmin === false)
      .map((res) => ({
        value: res.id,
        label: res.name,
      })),
  ];

  useEffect(() => {
    fetchState();
    if (state) {
      fetchCityData(state);
    }
  }, [state]);

  const fetchCityData = async (state_id) => {
    try {
      const data = await fetch(environment.apiUrl + `city/${state_id}`);
      const jsonData = await data.json();
      setDistricts(jsonData);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    const response = await fetch(environment.apiUrl + "state/all");
    const jsonData = await response.json();
    console.log("state : ", jsonData);
    setStates(jsonData);
  };

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (!dialogArea) {
      toast.error("Area field is required.");
      return;
    }

    if (!district) {
      toast.error("District field is required.");
      return;
    }

    if (!state) {
      toast.error("State field is required.");
      return;
    }

    const areaData = {
      area: dialogArea,
    };

    try {
      const response = await fetch(environment.apiUrl + "area/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(areaData),
      });

      const responseData = await response.json();

      if (response.ok) {
        const areaId = responseData.id;

        const newDialogArea = {
          areaId: areaId,
          state_id: state,
          city_id: district,
        };

        try {
          const response = await fetch(
            environment.apiUrl + "dialogArea/create",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newDialogArea),
            }
          );
          if (response.ok) {
            toast.success(" Area created successfully!");
            fetchArea();
          } else {
            toast.error("Error adding area.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      toast.error("Error adding area");
      console.log(error);
    }

    handleClose();
  };

  useEffect(() => {
    fetchDocType();
  }, []);

  const fetchDocType = async () => {
    const data = await fetch(environment.apiUrl + "doctype/all");
    const jsonData = await data.json();
    setDocTypes(jsonData);
  };

  const history = useHistory();

  const onSubmit = async () => {
    console.log(selectedUsers);
    if (!name) {
      toast.error("Name Field Is Required");
      return;
    }
    if (!accNumber) {
      toast.error("Account Number field is required");
      return;
    }
    if (!ifsc) {
      toast.error("IFSC field is required");
      return;
    }
    if (!branchName) {
      toast.error("Branch name field is required");
      return;
    }

    const formData = new FormData();
    formData.append("documentTypeId", documentType);
    formData.append("documentNumber", documentNumber);

    if (docImage) {
      formData.append("imageOfDocument", docImage);
    }
    if (docImage2) {
      formData.append("image2", docImage2);
    }

    if (docPdf) {
      formData.append("documentPDF", docPdf);
    }

    try {
      const response = await fetch(environment.apiUrl + "kyc/create", {
        method: "POST",
        headers: {},
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log(responseData);
        const kycId = responseData.id;
        console.log(kycId);

        const formDataBank = new FormData();
        formDataBank.append("name", name);
        formDataBank.append("accountNumber", accNumber);
        formDataBank.append("ifsc", ifsc);
        formDataBank.append("branchName", branchName);

        if (selectedImage) {
          formDataBank.append("imageOfCancelledCheque", selectedImage);
        }
        if (selectPdf) {
          formDataBank.append("documentOfCancelledCheque", selectPdf);
        }

        try {
          const response = await fetch(environment.apiUrl + "bank/create", {
            method: "POST",
            headers: {},
            body: formDataBank,
          });

          const responseData1 = await response.json();

          if (response.ok) {
            // toast.success("Varient created successfully!");
            // handleCancel();b
            const bankId = responseData1.id;
            console.log(bankId);

            const formDataBasic = new FormData();
            formDataBasic.append("businessName", businessName);
            formDataBasic.append("brandMarka", brandMarka);
            formDataBasic.append("contactPerson", contactPerson);
            formDataBasic.append("mobileNumber", mobileNumber);
            formDataBasic.append("address", address);
            formDataBasic.append("areaId", areaId);
            formDataBasic.append("businessTypeId", businessTypeId);
            formDataBasic.append("image", image);
            formDataBasic.append("commodityId", 1);
            formDataBasic.append("UserID", UserID);
            formDataBasic.append("notes", notes);
            formDataBasic.append("kycId", kycId);
            formDataBasic.append("bankId", bankId);
            formDataBasic.append("isBlacklist", isBlackList);
            formDataBasic.append("employees", JSON.stringify(selectedUsers));
            formDataBasic.append(
              "commodities",
              JSON.stringify(selectedCommodities)
            );

            try {
              const response = await fetch(
                environment.apiUrl + "party/create",
                {
                  method: "POST",
                  headers: {},
                  body: formDataBasic,
                }
              );

              const responseData2 = await response.json();

              if (response.ok) {
                toast.success("Party created successfully!");
                handleCancel();
              } else {
                if (responseData2 && responseData2.message) {
                  toast.error(responseData2.message);
                } else {
                  toast.error("Error adding party. Please try again.");
                  console.log();
                }
              }
            } catch (error) {
              console.log(error);

              toast.error("Error adding party. Please try again");
            }
          } else {
            if (responseData && responseData.message) {
              toast.error(responseData.message);
            } else {
              toast.error("Error adding party. Please try again.");
            }
          }
        } catch (error) {
          console.log(error);

          toast.error("Error adding party. Please try again");
        }

        //toast.success("Varient created successfully!");
        //handleCancel();
      } else {
        if (responseData && responseData.message) {
          toast.error(responseData.message);
        } else {
          toast.error("Error adding party. Please try again.");
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding party. Please try again");
    }
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handlepdfUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedpdf(imageFile);
  };

  const handlePartyImage = (e) => {
    const imageFile = e.target.files[0];
    setPartyImage(imageFile);
  };

  const handleDocImage = (e) => {
    const imageFile = e.target.files[0];
    setDocImage(imageFile);
  };

  const handleDocImage2 = (e) => {
    const imageFile = e.target.files[0];
    setDocImage2(imageFile);
  };

  const handlePdf = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };

  const addArea = async (e) => {
    e.preventDefault();
    console.log("saved");
  };

  // const formData = new FormData();

  // if (selectedImage) {
  //   formData.append("image", selectedImage);
  // }

  // Define tabData before usage
  const tabData = [
    {
      name: "Basic Info",
      icon: "user",
      disabled: true,
    },
    {
      name: "KYC",
      icon: "pen",
      disabled: true,
    },
    {
      name: "Bank Account Details",
      icon: "bank",
      disabled: true,
    },
  ];

  useEffect(() => {
    fetchAllEmp();
  }, []);

  const fetchAllEmp = async () => {
    const data = await fetch(environment.apiUrl + "employee/all_emp");
    const jsonData = await data.json();
    setAccessUser(jsonData);
  };

  useEffect(() => {
    fetchCommodity();
    fetchArea();
    fetchBusinessType();
  }, []);

  const fetchCommodity = async () => {
    const data = await fetch(environment.apiUrl + "commodity/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setCommodities(jsonData);
  };

  const fetchArea = async () => {
    const data = await fetch(environment.apiUrl + "area/getAreas");
    const jsonData = await data.json();
    console.log(jsonData);
    setAreas(jsonData);
  };

  const fetchBusinessType = async () => {
    const data = await fetch(environment.apiUrl + "businessType/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setBusinessTypes(jsonData);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(environment.apiUrl + "auth/authors", {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        });
        setUsers(response.data.authors);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
  }, []);

  const handleNext =async () => {
    if (activeTab == 0) {
     
      if (!contactPerson) {
        toast.error("Contact Person field is required.");
        return;
      }
      if (!mobileNumber) {
        toast.error("Mobile number field is required.");
        return;
      }

      const mobileNumberPattern = /^\d{10}$/;
      if (!mobileNumberPattern.test(mobileNumber)) {
        toast.error(" Provide valid mobile number");
        return;
      }

      if (!areaId || areaId === "Select Option") {
        toast.error(" Area field is required.");
        return;
      }

      if (!businessTypeId || businessTypeId === "Select Option") {
        toast.error(" Business Type field is required.");
        return;
      }

      if (selectedUsers.length === 0) {
        toast.error("Please select at least one user.");
        return;
      }
      // if (!UserID) {
      //   toast.error(" User Type field is required.");
      // }

      console.log("UserID", selectedUsers);

      const number = {
        mobileNumber,
      };
      try {
        const response = await fetch(environment.apiUrl + "party/getmobileNumber", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(number),
        });
  
        const responseData = await response.json();
      
        if (response.ok) {
          setActiveTab((prevTab) => prevTab + 1);
          /* if (responseData.message == "Mobile number already exists") {
            toast.error("Mobile number already exists");
            return true;
          }
          console.log("responseData", responseData)
           return false; */
        }
        else {
          toast.error(" Mobile no is already exist");
        }
      } catch (error) {
        toast.error(" Mobile no is already exist");
      }
    } else if (activeTab === 1) {
      if (!documentType || documentType === "Select Option") {
        toast.error(" Document Type field is required.");
        return;
      }

      else if (!documentNumber) {
        toast.error(" Document Number field is required.");
        return;
      }

      else if (!docImage) {
        toast.error("Please select an Document Image.");
        return;
      }
      else {
        setActiveTab((prevTab) => prevTab + 1);
      }

    }
    
  };

  const handleBack = () => {
    setActiveTab((prevTab) => prevTab - 1);
  };

  const isFirstTab = activeTab === 0;
  const isLastTab = activeTab === tabData.length - 1;

  const addUser = () => {
    alert("Selected");
  };

  /****************** Basic Details ************************** */
  const renderBasicInfoTab = () => {
    const commodityOptions = commodities.map((commodity) => ({
      value: commodity.id,
      label: commodity.commodity,
    }));

    const handleCommodityChange = (selectedOptions) => {
      setSelectedCommodities(selectedOptions);
    };

    return (
      <div
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="businessName">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Enter Business name..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="brandMarka">
                <Form.Label>Brand Marka</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={brandMarka}
                  onChange={(e) => setBrandMarka(e.target.value)}
                  placeholder="Enter brand Marka..."
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="contactPersonName">
                <Form.Label>
                  Contact Person Name
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  placeholder="Enter contact person name ..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="mobileNumber">
                <Form.Label>
                  Mobile Number
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter mobile number..."
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="area">
                <Form.Label>
                  Area
                  <span className="text-danger">*</span>
                </Form.Label>

                <div className="col-lg-8" style={{ display: "flex" }}>
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={areaId}
                    onChange={(e) => setArea(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {areas.map((res) => (
                      <option value={res.id} key={res.id}>
                        {res.area}
                      </option>
                    ))}
                  </select>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleClickOpen}
                  >
                    Add area
                  </button>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add Area</DialogTitle>
                    <DialogContent>
                      <div>
                        <Form.Label>
                          State
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Autocomplete
                          options={states
                            .filter((s) => s.status === 1)
                            .sort((a, b) =>
                              a.state_name.localeCompare(b.state_name)
                            )}
                          getOptionLabel={(option) => option.state_name}
                          value={states.find((s) => s.state_id === state)}
                          onChange={(e, newValue) =>
                            setState(newValue ? newValue.state_id : "")
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                        />
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        <Form.Label style={{ marginTop: "10px" }}>
                          District
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Autocomplete
                          options={districts
                            .filter((s) => s.status === 1)
                            .sort((a, b) =>
                              a.city_name.localeCompare(b.city_name)
                            )}
                          getOptionLabel={(option) => option.city_name}
                          value={districts.find((d) => d.city_id === district)}
                          onChange={(e, newValue) =>
                            setDistrict(newValue ? newValue.city_id : "")
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                        />
                      </div>

                      <div>
                        <TextField
                          margin="dense"
                          id="area"
                          label="Area"
                          type="text"
                          fullWidth
                          variant="standard"
                          value={dialogArea}
                          onChange={(e) => setDialogArea(e.target.value)}
                          style={{ marginBottom: "10px" }}
                        />
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <ReactGoogleAutocomplete
                  apiKey={environment.mapKey}
                  onPlaceSelected={handleOriginSelect}
                  types={["(regions)"]}
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="businessType">
                <Form.Label>
                  Business Type
                  <span className="text-danger">*</span>
                </Form.Label>

                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={businessTypeId}
                    onChange={(e) => setBusiness(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {businessTypes
                      .filter((businessType) => businessType.isActive)
                      .map((res) => (
                        <option value={res.id} key={res.id}>
                          {res.businessType}
                        </option>
                      ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="image">
                <Form.Label>Image</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handlePartyImage}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="dealInCommodity">
                <Form.Label>Deal in Commodity</Form.Label>
                <Select
                  isMulti
                  options={commodityOptions}
                  value={selectedCommodities}
                  onChange={handleCommodityChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Access to Users</Form.Label>
                <span className="text-danger">*</span>

                <Select
                  isMulti
                  options={accessUserOptions}
                  value={selectAllUsers ? accessUserOptions : selectedUsers}
                  onChange={(selectedOptions) => {
                    if (
                      selectedOptions.some((option) => option.value === "all")
                    ) {
                      setSelectAllUsers(true);
                      setSelectedUsers(accessUserOptions);
                    } else {
                      setSelectAllUsers(false);
                      setSelectedUsers(selectedOptions);
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3 ">
            <Col md={6}>
              <Form.Group controlId="note">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={notes}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Enter address..."
                />
              </Form.Group>
            </Col>
            <Col md={6} className="d-flex " style={{ marginTop: "28px" }}>
              <label className="col-lg-2 col-form-label" htmlFor="val-is-admin">
                Is Blacklist
                <span className="text-danger"></span>
              </label>

              <div className="col-lg-4">
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="isBlackList"
                      value="true"
                      checked={isBlackList}
                      onChange={() => setIsBlackList(true)}
                      required
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="isBlackList"
                      value="false"
                      checked={!isBlackList}
                      onChange={() => setIsBlackList(false)}
                      required
                    />
                    No
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                marginTop: "10px",
                display: isFirstTab ? "none" : "inline-block",
              }}
              onClick={handleBack}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-${isLastTab ? "primary" : "secondary"}`}
              style={{
                marginTop: "10px",
                display: isLastTab ? "none" : "inline-block",
              }}
              onClick={handleNext}
            >
              {isLastTab ? "Submit" : "Next"}
            </button>
          </div>
        </Form>
        {/* <button onClick={()=>{onSubmit1()}}>submit</button> */}
      </div>
    );
  };

  /***************** KYC Tab ******************** */

  const handleCancel = () => {
    history.push({
      pathname: "/adminParty",
    });
  };

  const renderKYCTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="documentType">
                <Form.Label>
                  Type of Document
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {docTypes.map((res) => (
                      <option value={res.id}>{res.docName}</option>
                    ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>
                  Document Number
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={documentNumber}
                    onChange={(e) => setDocNumber(e.target.value)}
                    placeholder="Enter Document Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="docImage">
                <Form.Label>
                  Document Image
                  <span className="text-danger">*</span>
                </Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleDocImage}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="docImage2">
                <Form.Label>Document Image 2</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleDocImage2}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="docPdf">
                <Form.Label>PDF file of Document</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept=".pdf"
                  onChange={handlePdf}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginTop: "10px" }}
              onClick={handleBack}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-${isLastTab ? "primary" : "secondary"}`}
              style={{
                marginTop: "10px",
                display: isLastTab ? "none" : "inline-block",
              }}
              onClick={handleNext}
            >
              {isLastTab ? "Submit" : "Next"}
            </button>
          </div>
        </Form>
      </div>
    );
  };

  /********************* Bank Account details *********************** */

  const renderBankTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>
                  Name
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>
                  Account Number
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="col-lg-8">
                  <input
                    type="number"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={accNumber}
                    onChange={(e) => setAccNumber(parseInt(e.target.value))}
                    placeholder="Enter account Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>
                  IFSC Code
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    placeholder="Enter ifsc code..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="documentType">
                <Form.Label>
                  Branch Name
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter branch name..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="docImage">
                <Form.Label> Image Of cancel cheque</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="docPdf">
                <Form.Label>PDF file of Cancelled Cheque</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept=".pdf"
                  onChange={handlepdfUpload}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-danger"
            style={{ marginTop: "10px" }}
            onClick={handleCancel}
          >
            Cancel
          </button>
          &nbsp;&nbsp;
          <button
            // type="submit"
            className={"btn btn-primary"}
            style={{ marginTop: "10px" }}
            onClick={() => {
              onSubmit();
            }}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  const renderTabContent = (tabName) => {
    if (tabName === "Basic Info") {
      return renderBasicInfoTab();
    } else if (tabName === "KYC") {
      return renderKYCTab();
    } else if (tabName === "Bank Account Details") {
      return renderBankTab();
    }
  };

  return (
    <div className="col-12">
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/party">Party</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Party</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Body>
          <div className="card-header">
            <h1 className="card-title">Add Party</h1>
          </div>
          <div className="default-tab">
            <Tab.Container
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={i} disabled={data.disabled}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={i} key={i}>
                    {renderTabContent(data.name)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
