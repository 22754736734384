import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function AddEmployeeForm(props) {
  const history = useHistory();
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [division, setDivision] = useState("");
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [terminate, setTerminate] = useState(false);
  const [getRoles, setGetRoles] = useState([]);
  const [getDivisions, setGetDivisions] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchRole();
    fetchDivision();
  }, []);

  const fetchRole = async () => {
    try {
      const data = await fetch(environment.apiUrl + "role/all");
      const jsonData = await data.json();
      setGetRoles(jsonData);
    } catch (error) {
      console.log("Error fetching roles:", error);
    }
  };

  const fetchDivision = async () => {
    try {
      const data = await fetch(environment.apiUrl + "division/all");
      const jsonData = await data.json();
      setGetDivisions(jsonData);
    } catch (error) {
      console.log("Error fetching divisions:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/employee",
    });
  };

  const onAddUser = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (!name) {
      toast.error("Name is required.");
      setIsSubmitting(false)
      return;
    }
    if (!mobileNumber) {
      toast.error("Mobile Number is required.");
      setIsSubmitting(false)
      return;
    }

    const mobileNumberPattern = /^\d{10}$/;
    if (!mobileNumberPattern.test(mobileNumber)) {
      toast.error(" Provide valid mobile number");
      setIsSubmitting(false)
      return;
    }

    if (email) {
      const emailPattern =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z]+\.[a-zA-Z]{2,}$/
    
      if (!emailPattern.test(email)) {
        toast.error("Please enter a valid email address.");
        setIsSubmitting(false)
        return;
      }
    }
    
    if (!division || division === "Select Option") {
      toast.error("  Division is required.");
      setIsSubmitting(false)
      return;
    }

    if (!role || role === "Select Option") {
      toast.error(" Role  is required.");
      setIsSubmitting(false)
      return;
    }

    if (!isImageSelected) {
      toast.error("Please select an image.");
      setIsSubmitting(false)
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("divisionId", division);
    formData.append("roleId", role);
    formData.append("userTypeId", 2);
    formData.append("isAdmin", isAdmin);
    formData.append("terminate", terminate);
    formData.append("password", "defaultPassword");
    formData.append("picture", imageFile);

    console.log(formData);

    try {
      const response = await fetch(environment.apiUrl + "employee/create", {
        method: "POST",
        headers: {},
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success("Employee created successfully!");
        handleCancel();

        history.push({
          pathname: "/employee",
          state: {
            newEmployee: {
              name,
              mobileNumber,
              email,
              division,
              role,
              isAdmin,
              terminate,
              imagePreview,
            },
          },
        });
      } else {
        if (responseData.message === "Employee already exist") {
          toast.error("Mobile number already exists");
        } else if (responseData.message === "Already exist") {
          toast.error("Admin is already present for this role");
        } else {
          toast.error("An error occurred while creating the employee");
        }
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Mobile number is already exist");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageClick = () => {
    document.getElementById("image-upload").click();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setIsImageSelected(true);

    // Preview the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/employee">Employee</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Employee</Link>
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Employee</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6">
                          {/* <img
                            style={{
                              margin: "auto",
                              display: "block",
                              width: "100px",
                              cursor: "pointer",
                            }}
                            src="https://img.freepik.com/free-icon/user_318-150864.jpg"
                            alt="User Avatar"
                          /> */}

                          <label htmlFor="image-upload">
                            <img
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                borderRadius: "90%",
                                cursor: "pointer",
                              }}
                              src={
                                imagePreview ||
                                "https://www.shareicon.net/data/512x512/2016/08/18/809259_user_512x512.png"
                              }
                              alt="User Avatar"
                            />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="image-upload"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter full name.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Mobile Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            placeholder="Enter mobile number.."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Email
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Division
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-division"
                            name="val-division"
                            value={division}
                            onChange={(e) => setDivision(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {getDivisions.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.divisionName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Role
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {getRoles.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.roleName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          Is Admin
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isAdmin"
                                value="true"
                                checked={isAdmin}
                                onChange={() => setIsAdmin(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isAdmin"
                                value="false"
                                checked={!isAdmin}
                                onChange={() => setIsAdmin(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-terminate"
                        >
                          Terminate
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="terminate"
                                value="true"
                                checked={terminate}
                                onChange={() => setTerminate(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="terminate"
                                value="false"
                                checked={!terminate}
                                onChange={() => setTerminate(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddEmployeeForm;
