import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function AddVarientForm(props) {
  const history = useHistory();
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [party, setParty] = useState("");
  const [parties, setParties] = useState([]);
  const [date, setDate] = useState("");
  const [priceType, setPriceType] = useState("");
  const [priceTypes, setPriceTypes] = useState([]);
  const [price, setPrice] = useState("");
  const [pricePerKg, setPricePerKg] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchParties();
    fetchProduct();
    fetchPriceType();
  }, []);

  const fetchParties = async () => {
    const data = await fetch(environment.apiUrl + "party/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setParties(jsonData);
  };

  const fetchProduct = async () => {
    const data = await fetch(environment.apiUrl + "product/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setProducts(jsonData);
  };

  const fetchPriceType = async () => {
    const data = await fetch(environment.apiUrl + "priceType/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setPriceTypes(jsonData);
  };

  //   const handleImageUpload = (e) => {
  //     const imageFile = e.target.files[0];
  //     setSelectedImage(imageFile);
  //   };

  const handleCancel = () => {
    history.push({
      pathname: "/sellingPrice",
    });
  };

  const handleImageUpload1 = (e) => {
    const imageFile = e.target.files[0];
    setImage1(imageFile);
  };

  const handleImageUpload2 = (e) => {
    const imageFile = e.target.files[0];
    setImage2(imageFile);
  };

  const handleImageUpload3 = (e) => {
    const imageFile = e.target.files[0];
    setImage3(imageFile);
  };

  const handleImageUpload4 = (e) => {
    const imageFile = e.target.files[0];
    setImage4(imageFile);
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!party || party === "Select Option") {
      toast.error("party field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!product || product === "Select Option") {
      toast.error("product field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!priceType || priceType === "Select Option") {
      toast.error("priceType field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!price) {
      toast.error("Price field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!pricePerKg) {
      toast.error("Price Per Kg field is required.");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("productId", product);
    formData.append("partyId", party);
    formData.append("priceTypeId", priceType);
    formData.append("date", date);
    formData.append("price", price);
    formData.append("pricePerKG", pricePerKg);

    console.log(formData);

    if (image1) {
      formData.append("image1", image1);
    }

    if (image2) {
      formData.append("image2", image2);
    }

    if (image3) {
      formData.append("image3", image3);
    }

    if (image4) {
      formData.append("image4", image4);
    }

    try {
      const response = await fetch(environment.apiUrl + "sellingPrice/create", {
        method: "POST",
        headers: {},
        body: formData,
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success("Selling Price created successfully!");
        handleCancel();
      } else {
        if (responseData && responseData.message) {
          toast.error(responseData.message);
        } else {
          toast.error("Error adding selling price. Please try again.");
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding Varient. Please try again");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/sellingPrice">Selling Price</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Selling price</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Selling Price</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Product
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {products
                              .filter((data) => data.packSize)
                              .map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.commodity?.commodity}-
                                  {res.varient?.varient}| {res.size?.size}|
                                  {res.quality?.quality}| {res.packSize}-
                                  {res.unit?.unit}
                                </option>
                                //    <option value={res.id} key={res.id}>
                                //     {res.unit.unit}
                                //  </option>
                              ))}
                            {/* <option>product 1</option> */}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Party
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={party}
                            onChange={(e) => setParty(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {parties.map((res) => (
                              <option
                                value={res.id}
                                key={res.id}
                                style={{
                                  color: res.isBlacklist ? "red" : "inherit",
                                  textDecoration: res.isBlacklist
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {res.contactPerson}
                              </option>
                            ))}
                            {/* <option>Party 1</option> */}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Date
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="date"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder="Enter date.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Price Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={priceType}
                            onChange={(e) => setPriceType(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {priceTypes
                              .filter((data) => data.priceType)
                              .map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.priceType}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Price
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={price}
                            onChange={(e) =>
                              setPrice(parseFloat(e.target.value))
                            }
                            placeholder="Enter price value..."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Price Per Kg
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={pricePerKg}
                            onChange={(e) =>
                              setPricePerKg(parseFloat(e.target.value))
                            }
                            placeholder="Enter price Per Kg value..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label className="col-lg-2 col-form-label">
                          Image 1<span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload1}
                          />
                        </div>
                        <label className="col-lg-2 col-form-label">
                          Image 2<span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload2}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label className="col-lg-2 col-form-label">
                          Image 3<span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload3}
                          />
                        </div>
                        <label className="col-lg-2 col-form-label">
                          Image 4<span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload4}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddVarientForm;
