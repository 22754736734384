import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const [expenseType, setExpenseType] = useState("");
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchExpenseType(id);
    }
  }, [id]);

  const fetchExpenseType = async (expenseId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `expenseType/${expenseId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching expense type data.");
      }
      const expenseData = await response.json();
      setExpenseType(expenseData.expenseType);
    } catch (error) {
      console.error("Error fetching expense type data:", error);
      toast.error("Error fetching expense type data");
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/expenseType",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();

    const expenseTypeData = {
      expenseType,
    };

    console.log(expenseTypeData);

    try {
      const response = await fetch(environment.apiUrl + `expenseType/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(expenseTypeData),
      });

      if (response.ok) {
        toast.success(" Expense Type Updated successfully!");
        handleCancel();

        history.push({
          pathname: "/expenseType",
          state: { newSize: expenseTypeData },
        });
      } else {
        toast.error("Error adding expense type.");
      }
    } catch (error) {
      toast.error("Error adding expense type");
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/expenseType">Expense Type</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Update Expense Type</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Expense Type</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Expense Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={expenseType}
                            onChange={(e) => setExpenseType(e.target.value)}
                            placeholder="Enter size type..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
