import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import   DatePicker  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { editUser, editUser1, editUser2
} from '../../../store/actions/AuthActions';
import axios from "axios";
import { environment } from "../../../environments/environment";

import { SRLWrapper } from "simple-react-lightbox";
//** Import Image */
import profile01 from "../../../images/profile/1.jpg";
import profile02 from "../../../images/profile/2.jpg";
import profile03 from "../../../images/profile/3.jpg";
import profile04 from "../../../images/profile/4.jpg";
import profile05 from "../../../images/profile/5.jpg";
import profile06 from "../../../images/profile/6.jpg";
import profile07 from "../../../images/profile/7.jpg";
import profile08 from "../../../images/profile/8.jpg";
import profile09 from "../../../images/profile/9.jpg";
import profile from "../../../images/profile/profile.png";
import PageTitle from "../../layouts/PageTitle";


function AppProfile (props) {
  //const [activeToggle, setActiveToggle] = useState("posts");
  //const [sendMessage, setSendMessage] = useState(false);
 // const [postModal, setPostModal] = useState(false);
  //const [cameraModal, setCameraModal] = useState(false);
 // const [linkModal, setLinkModal] = useState(false);
  //const [replayModal, setReplayModal] = useState(false);
	
  const city = [
    {
      id: 1,
      cityName: 'Howrah',
      stateId: 1
    }
  ]
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const [accounts, setAccounts] = useState(null);

    /* axios.get(environment.apiUrl + "account/authors", {
      headers: {
        Authorization: "bearer " + localStorage.getItem("MYTOKEN")
      }
    })
    .then(response => 
      setAccounts(response.data.authors)
    ); */

  const [detail, setDetail] = useState(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [userType, setUserType] = useState();
  const [accountId, setAccountId] = useState();


  const [address, setAddress] = useState();
  const [dob, setDob] = useState();
  const [pan, setPan] = useState();
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [postalCode, setPostalCode] = useState();
  const [link, setLink] = useState();

  const [stockBroker, setstockBroker] = useState();
  const [loginId, setloginId] = useState();
  const [mypassword, setmypassword] = useState();
  const [totpKey, settotpKey] = useState();
  const [mypan, setmypan] = useState();
  const [apiKey, setapiKey] = useState();

  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(city);

  const dispatch = useDispatch();

  const id = localStorage.getItem("USERID");

    useEffect(async () => {
      let result = await fetch(environment.apiUrl + "auth/author/" + id);
      result = await result.json();
      setDetail(result.author);
      setName(result.author.name);
      setPhoneNumber(result.author.phoneNumber);
      setEmail(result.author.email);
      setAccountId(result.author.accountId);


      setAddress(result.author.address);
      setDob(result.author.dob);
      setPan(result.author.pan);
      setStateId(result.author.stateId);
      setCityId(result.author.cityId);
      setPostalCode(result.author.postalCode);
      setLink(result.author.link);

      setstockBroker(result.author.stockBroker);
      setloginId(result.author.loginId);
      setmypassword(result.author.mypassword);
      settotpKey(result.author.totpKey);
      setmypan(result.author.mypan);
      setapiKey(result.author.apiKey);
    },[])

    useEffect(async () => {
      let result = await fetch(environment.apiUrl + "account/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      });
      result = await result.json();
      setAccounts(result.authors);
    },[])

    useEffect(async () => {
      let result = await fetch(environment.apiUrl + "state/authors", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      });
      result = await result.json();
      setStates(result.authors);
    },[]);

    useEffect(async () => {
      let result = await fetch(environment.apiUrl + "city/authors/1", {
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      });
      result = await result.json();
      setCities(result.authors);
    },[]);

    /* function onState () {
      axios.get(environment.apiUrl + "city/authors/" + stateId,{
        headers: {
          Authorization: "bearer " + localStorage.getItem("MYTOKEN")
        }
      })
        .then(response => 
          console.log(response)
          //setCities(response.data.authors)
      );
    } */

  function onAddUser(e) {
    e.preventDefault();
    dispatch(editUser(id, email, phoneNumber, name, accountId, props.history));
  }

  function onAddUser1(e) {
    e.preventDefault();
    dispatch(editUser1(id, address, dob, pan, stateId, cityId, postalCode, link, props.history));
  }

  function onAddUser2(e) {
    e.preventDefault();
    dispatch(editUser2(id, stockBroker, loginId, mypassword, totpKey, mypan, apiKey, props.history));
  }



  if(detail!=null&&accounts!=null&&states!=null&&detail.userType=="Supervisor") {
    return (
        <Fragment>
          <PageTitle motherMenu="Profile" />
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    <div className="cover-photo rounded"></div>
                  </div>
                  <div className="profile-info">
                    <div className="profile-photo">
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                        className="img-fluid rounded-circle"
                        alt="profile"
                      />
                    </div>
                    <div className="profile-details">
                      <div className="profile-name px-3 pt-2">
                        <h4 className="text-primary mb-0">{detail.name}</h4>
                        <p>{detail.userType}</p>
                      </div>
                      <div className="profile-email px-2 pt-2">
                        <h4 className="text-muted mb-0">{detail.email}</h4>
                        <p>Email</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                        <Tab.Container defaultActiveKey='Posts'>					
                            <Nav as='ul' className="nav nav-tabs">
                                <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#registration" eventKey='Posts'>Registration Info</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li'i className="nav-item">
                                    <Nav.Link to="#general"  eventKey='General'>General Info</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#trading" eventKey='Trading'>Trading A/C Info</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane id="registration"  eventKey='Posts'>
                                    <div className="my-post-content pt-3">
                                    <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="post"
                      onSubmit={onAddUser}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Fullname
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                ini
                                name="val-username"
                                defaultValue={detail.name}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter a username.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="email"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your valid email.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Mobile No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Your valid mobile no.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          Subscription Fees
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-subscription"
                            name="val-subscription"
                            defaultValue={detail.accountId}
                            onChange={(e) => setAccountId(e.target.value)}
                          >
                            {/* <option value="Select Option">Select Option</option> */}
                            {accounts.map(i => 
                              <option value={i.id}>{i.title} ({i.amount}) </option>
                            )}
                            
                          </select>
                        </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                                    </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane id="general" eventKey='General'>
                                <div className="my-post-content pt-3">
                                    <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="put"
                      onSubmit={onAddUser1}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Address
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                ini
                                name="val-username"
                                defaultValue={detail.address}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Enter a address.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Date of Birth <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                             <DatePicker selected={new Date(detail.dob)} onChange={(date) => setDob(new Date(date))} dateFormat="dd-MM-yyyy" className="form-control"/> 
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Pan No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.pan}
                                onChange={(e) => setPan(e.target.value)}
                                placeholder="Pan no.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          State
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-subscription"
                            name="val-subscription"
                            defaultValue={detail.stateId}
                            onChange={(e) => setStateId(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {states.map(i => 
                              <option value={i.id}>{i.stateName}</option>
                            )}
                            
                          </select>
                        </div>
                          </div>

                          <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          City
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-subscription"
                            name="val-subscription"
                            defaultValue={detail.cityId}
                            onChange={(e) => setCityId(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {cities.map(i => 
                              <option value={i.id}>{i.cityName}</option>
                            )}
                            
                          </select>
                        </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Postal Code <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                placeholder="Postal Code.."
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Link <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.link}
                                onChange={(e) => setLink(e.target.value)}
                                placeholder="Link.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                                    </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane id="trading" eventKey='Trading'>
                                <div className="my-post-content pt-3">
                                    <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="post"
                      onSubmit={onAddUser2}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Stock Broker
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                ini
                                name="val-username"
                                defaultValue={detail.stockBroker}
                                value={stockBroker}
                                onChange={(e) => setstockBroker(e.target.value)}
                                placeholder="Enter a stock broker.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Login ID <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.loginId}
                                onChange={(e) => setloginId(e.target.value)}
                                placeholder="Login ID.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.mypassword}
                                onChange={(e) => setmypassword(e.target.value)}
                                placeholder="Password.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              TOTP Key <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.totpKey}
                                onChange={(e) => settotpKey(e.target.value)}
                                placeholder="TOTP Key.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Pan No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.mypan}
                                onChange={(e) => setmypan(e.target.value)}
                                placeholder="Pan No.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              API Key <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.apiKey}
                                onChange={(e) => setapiKey(e.target.value)}
                                placeholder="API Key.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                                    </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>	
                        </Tab.Container>		
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
  }
  else if(detail!=null&&accounts!=null&&states!=null&&detail.userType!="Supervisor") {
    return (
        <Fragment>
          <PageTitle motherMenu="Profile" />
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    <div className="cover-photo rounded"></div>
                  </div>
                  <div className="profile-info">
                    <div className="profile-photo">
                      {/* <img
                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        className="img-fluid rounded-circle"
                        alt="profile"
                      /> */}
                    </div>
                    <div className="profile-details">
                      <div className="profile-name px-3 pt-2">
                        <h4 className="text-primary mb-0">{detail.name}</h4>
                        <p>{detail.userType}</p>
                      </div>
                      <div className="profile-email px-2 pt-2">
                        <h4 className="text-muted mb-0">{detail.email}</h4>
                        <p>Email</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                        <Tab.Container defaultActiveKey='Posts'>					
                            <Nav as='ul' className="nav nav-tabs">
                                <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#registration" eventKey='Posts'>Registration Info</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li'i className="nav-item">
                                    <Nav.Link to="#general"  eventKey='General'>General Info</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as='li' className="nav-item">
                                    <Nav.Link to="#trading" eventKey='Trading'>Trading A/C Info</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane id="registration"  eventKey='Posts'>
                                    <div className="my-post-content pt-3">
                                    <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="post"
                      onSubmit={onAddUser}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Fullname
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                ini
                                name="val-username"
                                defaultValue={detail.name}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter a username.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="email"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your valid email.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Mobile No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Your valid mobile no.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                                    </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane id="general" eventKey='General'>
                                <div className="my-post-content pt-3">
                                    <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="put"
                      onSubmit={onAddUser1}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Address
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                ini
                                name="val-username"
                                defaultValue={detail.address}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Enter a address.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Date of Birth <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                             <DatePicker selected={new Date(detail.dob)} onChange={(date) => setDob(new Date(date))} dateFormat="dd-MM-yyyy" className="form-control"/> 
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Pan No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.pan}
                                onChange={(e) => setPan(e.target.value)}
                                placeholder="Pan no.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          State
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-subscription"
                            name="val-subscription"
                            defaultValue={detail.stateId}
                            onChange={(e) => setStateId(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {states.map(i => 
                              <option value={i.id}>{i.stateName}</option>
                            )}
                            
                          </select>
                        </div>
                          </div>

                          <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          City
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-subscription"
                            name="val-subscription"
                            defaultValue={detail.cityId}
                            onChange={(e) => setCityId(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {cities.map(i => 
                              <option value={i.id}>{i.cityName}</option>
                            )}
                            
                          </select>
                        </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Postal Code <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                placeholder="Postal Code.."
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Link <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.link}
                                onChange={(e) => setLink(e.target.value)}
                                placeholder="Link.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                                    </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane id="trading" eventKey='Trading'>
                                <div className="my-post-content pt-3">
                                    <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="post"
                      onSubmit={onAddUser2}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Stock Broker
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                ini
                                name="val-username"
                                defaultValue={detail.stockBroker}
                                value={stockBroker}
                                onChange={(e) => setstockBroker(e.target.value)}
                                placeholder="Enter a stock broker.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Login ID <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.loginId}
                                onChange={(e) => setloginId(e.target.value)}
                                placeholder="Login ID.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.mypassword}
                                onChange={(e) => setmypassword(e.target.value)}
                                placeholder="Password.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              TOTP Key <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.totpKey}
                                onChange={(e) => settotpKey(e.target.value)}
                                placeholder="TOTP Key.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Pan No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.mypan}
                                onChange={(e) => setmypan(e.target.value)}
                                placeholder="Pan No.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              API Key <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                defaultValue={detail.apiKey}
                                onChange={(e) => setapiKey(e.target.value)}
                                placeholder="API Key.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                                    </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>	
                        </Tab.Container>		
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
  }
  else {
    return (
      <Fragment>
      <div className="row page-titles mx-0">
  <ol className="breadcrumb">
    <li className="breadcrumb-item  "><Link to="">My Profile</Link></li>
  </ol>
  </div>
  <div className="row page-titles mx-0">
    <p>Loading...</p>
  </div>
    </Fragment>
      );
  }
  
};

export default AppProfile;
