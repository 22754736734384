import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		// case 'postModalOpen':
		// 	return { ...state, post: true }
		// case 'postModalClose':			
		// 	return { ...state, post: false }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

const AppProfile = () => {
  //const [activeToggle, setActiveToggle] = useState("posts");
  //const [sendMessage, setSendMessage] = useState(false);
 // const [postModal, setPostModal] = useState(false);
  //const [cameraModal, setCameraModal] = useState(false);
 // const [linkModal, setLinkModal] = useState(false);
  //const [replayModal, setReplayModal] = useState(false);
	
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

	const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />
         <h2>sss</h2>
      {/* <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">Mitchell C. Shay</h4>
                    <p>UX / UI Designer</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">hello@email.com</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='Posts'>					
						<Nav as='ul' className="nav nav-tabs">
							<Nav.Item as='li' className="nav-item">
								<Nav.Link to="#my-posts" eventKey='Posts'>Posts</Nav.Link>
							</Nav.Item>
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>About Me</Nav.Link>
							</Nav.Item>
							<Nav.Item as='li' className="nav-item">
								<Nav.Link to="#profile-settings" eventKey='Setting'>Setting</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>
							<Tab.Pane id="my-posts"  eventKey='Posts'>
								<div className="my-post-content pt-3">
									<div className="post-input">
											<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control bg-transparent" placeholder="Please type what you want...."defaultValue={""}/>
											<Link to="/app-profile" className="btn btn-primary light px-3 me-1"  onClick={() => dispatch({type:'linkModal'})}>
												<i className="fa fa-link m-0" />{" "}
											</Link>
										
										<Link to={"#"} className="btn btn-primary light px-3 me-1"  data-target="#cameraModal" onClick={() => dispatch({type:'cameraModal'})}>
											<i className="fa fa-camera m-0" />{" "}
										</Link>
										
										<Link to={"#"} className="btn btn-primary ms-1" data-target="#postModal" onClick={() => dispatch({type:'postModal'})}>Post</Link>
										
										
									</div>

									<div className="profile-uoloaded-post border-bottom-1 pb-5">
										<img src={profile08} alt="" className="img-fluid w-100 rounded" />
										<Link className="post-title" to="/post-details">
											<h3 className="text-black">Collection of textile samples lay spread</h3>
										</Link>
										<p>
											A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take 
											possession of my entire soul like these sweet morning of spare which enjoy whole heart.
										</p>
										<button className="btn btn-primary me-2">
											<span className="me-2"> <i className="fa fa-heart" /> </span>Like 
										</button>
										<button className="btn btn-secondary" onClick={() => dispatch({type:'replyModal'})}>
											<span className="me-2"> <i className="fa fa-reply" /></span>Reply
										</button>
									</div>
									<div className="profile-uoloaded-post border-bottom-1 pb-5">
										<img src={profile09} alt="" className="img-fluid w-100 rounded" />
										<Link className="post-title" to="/post-details">
											<h3 className="text-black">Collection of textile samples lay spread</h3>
										</Link>
										<p>
											A wonderful serenity has take possession of my
											entire soul like these sweet morning of spare which
											enjoy whole heart.A wonderful serenity has take
											possession of my entire soul like these sweet
											morning of spare which enjoy whole heart.
										</p>
										<button className="btn btn-primary me-2">
											<span className="me-2"> <i className="fa fa-heart" /> </span>Like
										</button>
										<button className="btn btn-secondary" onClick={() => dispatch({type:'replyModal'})}>
											<span className="me-2">  <i className="fa fa-reply" /></span>Reply
										</button>
									</div>
									<div className="profile-uoloaded-post pb-3">
										<img src={profile08} alt="" className="img-fluid  w-100 rounded" />
										<Link className="post-title" to="/post-details">
											<h3 className="text-black">Collection of textile samples lay spread</h3>
										</Link>
										<p>
											A wonderful serenity has take possession of my
											entire soul like these sweet morning of spare which
											enjoy whole heart.A wonderful serenity has take
											possession of my entire soul like these sweet
											morning of spare which enjoy whole heart.
										</p>
										<button className="btn btn-primary me-2">
											<span className="me-2"><i className="fa fa-heart" /></span>Like
										</button>
										<button className="btn btn-secondary" onClick={() => dispatch({type:'replyModal'})}>
											<span className="me-2"> <i className="fa fa-reply" /></span>Reply
										</button>
									</div>
									
								</div>
							</Tab.Pane>
							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-about-me">
									<div className="pt-4 border-bottom-1 pb-3">
										<h4 className="text-primary">About Me</h4>
										<p className="mb-2">
											A wonderful serenity has taken possession of my
											entire soul, like these sweet mornings of spring
											which I enjoy with my whole heart. I am alone, and
											feel the charm of existence was created for the
											bliss of souls like mine.I am so happy, my dear
											friend, so absorbed in the exquisite sense of mere
											tranquil existence, that I neglect my talents.
										</p>
										<p>
											A collection of textile samples lay spread out on
											the table - Samsa was a travelling salesman - and
											above it there hung a picture that he had recently
											cut out of an illustrated magazine and housed in a
											nice, gilded frame.
										</p>
									</div>
								</div>
								<div className="profile-skills mb-5">
									<h4 className="text-primary mb-2">Skills</h4>
									<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1"> Admin</Link>
									<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1" > Dashboard</Link>
									<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Photoshop</Link>
									<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Bootstrap</Link>
									<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Responsive</Link>
									<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Crypto</Link>
								</div>
								<div className="profile-lang  mb-5">
									<h4 className="text-primary mb-2">Language</h4>
									<Link to="/app-profile" className="text-muted pe-3 f-s-16">
										<i className="flag-icon flag-icon-us" />English
									</Link>
									<Link to="/app-profile" className="text-muted pe-3 f-s-16">
										<i className="flag-icon flag-icon-fr" />French
									</Link>
									<Link to="/app-profile" className="text-muted pe-3 f-s-16">
										<i className="flag-icon flag-icon-bd" />Bangla
									</Link>
								</div>
								<div className="profile-personal-info">
									<h4 className="text-primary mb-4">
										Personal Information
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>Mitchell C.Shay</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>example@examplel.com</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">  Availability<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>Full Time (Free Lancer)</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">Age<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>27</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">  Location<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>Rosemont Avenue Melbourne, Florida</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">Year Experience<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>07 Year Experiences</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
							<Tab.Pane id="profile-settings" eventKey='Setting'>
								<div className="pt-3">
									<div className="settings-form">
										<h4 className="text-primary">Account Setting</h4>
										<form onSubmit={(e) => e.preventDefault()}>
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="form-label" >Email</label>
													<input type="email" placeholder="Email" className="form-control"/>
												</div>
												<div className="form-group mb-3 col-md-6">
													<label className="form-label">Password</label>
													<input type="password" placeholder="Password" className="form-control"/>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="form-label">Address</label>
												<input type="text" placeholder="1234 Main St" className="form-control"/>
											</div>
											<div className="form-group mb-3">
												<label className="form-label">Address 2</label>
												<input type="text" placeholder="Apartment, studio, or floor" className="form-control"/>
											</div>
											<div className="row">
												<div className="form-group mb-3 col-md-6">
													<label className="form-label" >City</label>
													<input type="text" className="form-control" />
												</div>
												<div className="form-group mb-3 col-md-4">
													<label className="form-label">State</label>
													<select
													className="form-control"
													id="inputState"
													defaultValue="option-1"
													>
													<option value="option-1">Choose...</option>
													<option value="option-2">Option 1</option>
													<option value="option-3">Option 2</option>
													<option value="option-4">Option 3</option>
													</select>
												</div>
												<div className="form-group mb-3 col-md-2">
													<label className="form-label">Zip</label>
													<input type="text" className="form-control" />
												</div>
											</div>
											<div className="form-group mb-3">
												<div className="form-check custom-checkbox">
													<input
													type="checkbox"
													className="form-check-input"
													id="gridCheck"
													/>
													<label
													className="form-check-label"
													htmlFor="gridCheck"
													>
													Check me out
													</label>
												</div>
											</div>
											<button className="btn btn-primary" type="submit">Sign in</button>
										</form>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default AppProfile;
