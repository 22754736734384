import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";

function AddVarientForm(props) {
  const history = useHistory();
  const { id } = useParams();
  const [varient, setVarient] = useState("");
  const [commodity, setCommodity] = useState("");
  const [cropDuration, setCropDuration] = useState("");
  const [isImported, setIsImported] = useState(false);
  const [farmable, setFarmable] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [commodities, setCommodities] = useState([]);
  const [src, setSrc] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchCommodities();
    if (id) {
      fetchVarientData(id);
    }
  }, [id]);

  const fetchCommodities = async () => {
    const data = await fetch(environment.apiUrl + "commodity/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setCommodities(jsonData);
  };

  const fetchVarientData = async (varientId) => {
    try {
      const response = await fetch(environment.apiUrl + `varient/${varientId}`);
      const varientData = await response.json();

      setVarient(varientData.varient);
      setCommodity(varientData.commodityId);
      setCropDuration(varientData.cropDuration);
      setIsImported(varientData.isImported);
      setFarmable(varientData.farmable);
      setSelectedImage(varientData.image);
      let data2 = environment.apiUrl + 'varient/files/' + varientData.image;
      console.log(data2);
      setSrc(data2);
    } catch (error) {
      console.log("Error fetching varient data:", error);
    }
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/varient",
    });
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    // Perform form submission logic
    setIsSubmitting(true)
    if (!varient) {
      toast.error("Varient field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!commodity || commodity === "Select Option") {
      toast.error("Commodity field is required.");
      setIsSubmitting(false);
      return;
    }

    if (!cropDuration) {
      toast.error("Crop Duration field is required.");
      setIsSubmitting(false);
      return;
    }

    const varientData = {
      varient,
      commodityId: commodity,
      cropDuration,
      isImported,
      farmable,
    };

    if (selectedImage) {
      const formData = new FormData();
      formData.append("varient", varient);
      formData.append("commodityId", commodity);
      formData.append("cropDuration", cropDuration);
      formData.append("isImported", isImported);
      formData.append("farmable", farmable);
      formData.append("image", selectedImage);

      try {
        const response = await fetch(
          environment.apiUrl + `varient/update/${id}`,
          {
            method: "PUT",
            body: formData,
          }
        );

        if (response.ok) {
          toast.success("Varient updated successfully!");
          history.push("/varient");
        } else {
          toast.error("Error updating varient. Please try again.");
        }
      } catch (error) {
        console.error("Error updating varient:", error);
        toast.error("Error updating varient. Please try again.");
      }
    } else {
      try {
        const response = await fetch(
          environment.apiUrl + `varient/update/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(varientData),
          }
        );

        if (response.ok) {
          toast.success("Varient updated successfully!");

          history.push("/varient");
        } else {
          toast.error("Error updating varient. Please try again.");
        }
      } catch (error) {
        console.error("Error updating varient:", error);
        toast.error("Error updating varient. Please try again.");
      }finally{
        setIsSubmitting(false)
      }
    }
  };

  return (
    <Fragment>
       <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/varient">Varient</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Edit Varient</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Varient</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                  <div className="col-xl-12">
                    <img style={{height:'120px',width:'120px',borderRadius:'50%',display:'block',margin:'auto'}} src={src} />
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Varient
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={varient}
                            onChange={(e) => setVarient(e.target.value)}
                            placeholder="Enter varient name.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Commodity
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={commodity}
                            onChange={(e) => setCommodity(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {commodities.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.commodity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Crop Duration
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={cropDuration}
                            onChange={(e) => setCropDuration(e.target.value)}
                            placeholder="Enter crop duration.."
                          />
                        </div>

                        <label className="col-lg-2 col-form-label">
                          Upload Image
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        {/* <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-skill"
                        >
                          Is Imported
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="optradio"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="optradio"
                              />
                              No
                            </label>
                          </div>
                        </div> */}

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          isImported
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="true"
                                checked={isImported}
                                onChange={() => setIsImported(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isImported"
                                value="false"
                                checked={!isImported}
                                onChange={() => setIsImported(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-is-admin"
                        >
                          Farmable
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="farmable"
                                value="true"
                                checked={farmable}
                                onChange={() => setFarmable(true)}
                                required
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="farmable"
                                value="false"
                                checked={!farmable}
                                onChange={() => setFarmable(false)}
                                required
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddVarientForm;
