import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { environment } from "../../../environments/environment";
import { ToastContainer, toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

function JQueryValidation(props) {
  const history = useHistory();
  const { id } = useParams();
  const [commodity, setCommodity] = useState("");
  const [commodityType, setCommodityType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [src, setSrc] = useState(null);
  const [commodityTypes, setCommodityTypes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  useEffect(() => {
    fetchCommodityType();
    if (id) {
      fetchCommodityData(id);
    }
  }, [id]);

  const fetchCommodityType = async () => {
    try {
      const data = await fetch(environment.apiUrl + "commodityType/all");
      const jsonData = await data.json();
      setCommodityTypes(jsonData);
    } catch (error) {
      console.log("Error fetching commodity Types:", error);
    }
  };

  // const dispatch = useDispatch();

  const fetchCommodityData = async (commodityId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `commodity/${commodityId}`
      );
      const commodityData = await response.json();

      setCommodity(commodityData.commodity);
      setCommodityType(commodityData.commodityTypeId);
      setSelectedImage(commodityData.image);
      let data1 = environment.apiUrl + "commodity/files/" + commodityData.image;
      console.log(data1);
      setSrc(data1);
    } catch (error) {
      console.log("Error fetching commodity data:", error);
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/commodity",
    });

    // setCommodity("");
    // setCommodityType("");
    // setSelectedImage(null);
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // if(!commodity || !commodityType){
    //     toast.error("All field are  required.");
    //   return;
    // }

    if (!commodity) {
      toast.error("Commodity field is  required.");
      setIsSubmitting(false);
      return;
    }
    if (!commodityType || commodityType === "Select Option") {
      toast.error("CommodityType field is  required.");
      setIsSubmitting(false);
      return;
    }

    const commodityData = {
      commodity,
      commodityTypeId: commodityType,
      //image: selectedImage,
    };

    if (selectedImage) {
      const formData = new FormData();
      formData.append("commodity", commodity);
      formData.append("commodityTypeId", commodityType);
      formData.append("image", selectedImage);

      try {
        const response = await fetch(
          environment.apiUrl + `commodity/update/${id}`,
          {
            method: "PUT",
            body: formData,
          }
        );

        if (response.ok) {
          toast.success("Commodity updated successfully!");
          history.push("/commodity");
        } else {
          toast.error("Error updating commodity. Please try again.");
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error updating commodity:", error);
        toast.error("Error updating commodity. Please try again.");
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        const response = await fetch(
          environment.apiUrl + `commodity/update/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(commodityData),
          }
        );

        if (response.ok) {
          toast.success("Commodity updated successfully!");
          history.push("/commodity");
        } else {
          toast.error("Error updating commodity. Please try again.");
        }
      } catch (error) {
        console.error("Error updating commodity:", error);
        toast.error("Error updating commodity. Please try again.");
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/subscription-list">Commodity</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Edit Commodity</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Commodity</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <img
                        style={{
                          height: "120px",
                          width: "120px",
                          borderRadius: "50%",
                          display: "block",
                          margin: "auto",
                        }}
                        src={src}
                      />
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Commodity
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={commodity}
                            onChange={(e) => setCommodity(e.target.value)}
                            placeholder="Enter commodity name.."
                          />
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-skill"
                        >
                          Commodity Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-division"
                            name="val-division"
                            value={commodityType}
                            onChange={(e) => setCommodityType(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {commodityTypes.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.commodityType}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <form className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="image-upload"
                        >
                          Upload Image
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="file"
                            className="form-control"
                            id="image-upload"
                            name="image-upload"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </div>
                      </form>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
