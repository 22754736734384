import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from 'react-redux';
import { Formik } from "formik";
import * as Yup from "yup";
import { _addUser2
} from '../../../store/actions/AuthActions';
import axios from "axios";
import { environment } from "../../../environments/environment";

function JQueryValidation (props) {
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [name, setName] = useState();
    const [myParentId, setMyParentId] = useState();
    const [users, setUsers] = useState(null);

    const [userType, setUserType] = useState(localStorage.getItem("USERTYPE"));

    axios.get(environment.apiUrl + "auth/authorss", {
      headers: {
        Authorization: "bearer " + localStorage.getItem("MYTOKEN")
      }
    })
      .then(response => 
      setUsers(response.data.authors)
    );

    const dispatch = useDispatch();

    function onAddUser(e) {
		e.preventDefault();
        dispatch(_addUser2(email, phoneNumber, name, myParentId, props.history));
    }

    if(users!=null&&userType!='Advisor') {
      return (
        <Fragment>
          <div className="row page-titles mx-0">
			<ol className="breadcrumb">
				<li className="breadcrumb-item active"><Link to="/user-list">User List</Link></li>
				<li className="breadcrumb-item  "><Link to="">Add Customer</Link></li>
			</ol>
      </div>
    
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Add Customer</h4>
                </div>
                <div className="card-body">
                  <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="post"
                      onSubmit={onAddUser}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                        <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          Select A Advisor
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-subscription"
                            name="val-subscription"
                            value={myParentId}
                            onChange={(e) => setMyParentId(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {users.map(i => 
                              <option key={i.id} value={i.id}>{i.name}</option>
                            )}
                            
                          </select>
                        </div>
                        </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Fullname
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                name="val-username"
                                value={name}
                  onChange={(e) => setName(e.target.value)}
                                placeholder="Enter a username.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="email"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your valid email.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Mobile No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Your valid mobile no.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    else {
      return (
        <Fragment>
          <div className="row page-titles mx-0">
			<ol className="breadcrumb">
				<li className="breadcrumb-item active"><Link to="/user-list">User List</Link></li>
				<li className="breadcrumb-item  "><Link to="">Add Customer</Link></li>
			</ol>
      </div>
    
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Add Customer</h4>
                </div>
                <div className="card-body">
                  <div className="form-validation">
                    <form
                      className="form-valide"
                      action="#"
                      method="post"
                      onSubmit={onAddUser}
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Fullname
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                name="val-username"
                                value={name}
                  onChange={(e) => setName(e.target.value)}
                                placeholder="Enter a username.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="email"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your valid email.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-email"
                            >
                              Mobile No. <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                id="val-email"
                                name="val-email"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Your valid mobile no.."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <div className="col-lg-8 ms-auto">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  
};

export default JQueryValidation;
