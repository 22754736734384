import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";

function JQueryValidation(props) {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [distance, setDistance] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [vehicleNames, setVehicleNames] = useState([]);
  const [amount, setAmount] = useState("");
  const [first80kmCharges, setFirst80kmCharges] = useState(0);
  const [perkmCharges, setPerkmCharges] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState("");

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    fetchVehicleName();
    if (id) {
      fetchCalcData(id);
    }
  }, [id]);

  const fetchVehicleName = async () => {
    const data = await fetch(environment.apiUrl + "vehicle/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setVehicleNames(jsonData);
  };

  const fetchCalcData = async (calcId) => {
    const data = await fetch(environment.apiUrl + `calculator/${calcId}`);
    const calcData = await data.json();
    console.log(calcData);
    setOrigin(calcData.origin);
    setDestination(calcData.destination);
    setDistance(calcData.distance);
    setVehicleName(calcData.vehicleName);
    // setAmount(calcData.amount);
    setFirst80kmCharges(calcData.vehicleName.first80kmCharges);
    setPerkmCharges(calcData.vehicleName.perkmCharges);
    setCalculatedAmount(calcData.amount);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/calculator",
    });
  };

  const handleOriginSelect = (place) => {
    setOrigin(place.formatted_address);
  };

  const handleDestinationSelect = (place) => {
    setDestination(place.formatted_address);
  };

  const calculateDistance = () => {
    if (!origin || !destination) {
      toast.error("Origin and Destination are required.");
      return;
    }

    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          const result = response.rows[0].elements[0];
          if (result.status === "OK") {
            setDistance(result.distance.text);
          } else {
            toast.error("Error calculating distance.");
          }
        } else {
          toast.error("Error calculating distance.");
        }
      }
    );
  };

  const onVehicleNameChange = async (e) => {
    const selectedVehicleName = e.target.value;

    // Fetch the specific vehicle data based on the selected name
    try {
      const vehicleResponse = await fetch(
        environment.apiUrl + `vehicle/${selectedVehicleName}`
      );
      if (!vehicleResponse.ok) {
        toast.error("Error fetching vehicle data.");
        return;
      }
      const vehicleData = await vehicleResponse.json();

      // Update the state variables with the selected vehicle data
      setVehicleName(selectedVehicleName);
      setFirst80kmCharges(vehicleData.first80kmCharges);
      setPerkmCharges(vehicleData.perkmCharges);
    } catch (error) {
      toast.error("Error fetching vehicle data");
    }
  };

  const calculateAmount = () => {
    if (!origin || !destination) {
      toast.error("Origin and Destination are required.");
      return;
    }

    console.log(distance);
    const distanceInKm = parseFloat(distance.replace(",", "").split(" km")[0]);
    console.log(distanceInKm);
    let calculatedAmountValue = 0;

    if (distanceInKm * 2 >= 80) {
      calculatedAmountValue =
        first80kmCharges + (distanceInKm * 2 - 80) * perkmCharges;
    } else {
      calculatedAmountValue = first80kmCharges;
    }

    setCalculatedAmount(calculatedAmountValue.toFixed(2));
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();

    const CalcData = {
      origin,
      destination,
      distance,
      vehicleName,
      amount: calculatedAmount,
    };

    console.log(CalcData);

    try {
      const response = await fetch(
        environment.apiUrl + `calculator/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(CalcData),
        }
      );

      if (response.ok) {
        toast.success(" Updated successfully!");
        handleCancel();

        history.push({
          pathname: "/calculator",
          state: { newCalc: CalcData },
        });
      } else {
        toast.error("Error updating calculator.");
      }
    } catch (error) {
      toast.error("Error updating calculator");
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/calculator">Freight Calculator</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Freight Calculator</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Freight Calculator </h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Origin
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <ReactGoogleAutocomplete
                            apiKey={environment.mapKey}
                            onPlaceSelected={handleOriginSelect}
                            types={["(regions)"]}
                            className="form-control"
                            value={origin}
                            onChange={(e) => setOrigin(e.target.value)}
                          />
                        </div>
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Destination
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <ReactGoogleAutocomplete
                            apiKey={environment.mapKey}
                            onPlaceSelected={handleDestinationSelect}
                            types={["(regions)"]}
                            className="form-control"
                            value={destination}
                            onChange={(e) => setDestination(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Distance
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="distance"
                            name="distance"
                            value={distance}
                            readOnly
                            placeholder="Distance"
                          />
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={calculateDistance}
                          >
                            Calculate Distance
                          </button>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Vehicle Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          {/* Render options from vehicleNames */}
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={vehicleName}
                            onChange={onVehicleNameChange}
                          >
                            <option value="Select Option">Select Option</option>
                            {vehicleNames
                              .filter((item) => item.isActive)
                              .map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.vehicleName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Amount
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={calculatedAmount}
                            placeholder="Amount calculate automatically..."
                          />
                        </div>
                        <div className="col-lg-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={calculateAmount}
                          >
                            Calculate Amount
                          </button>
                        </div>
                      </div>

                      {/* Save and Cancel Buttons */}
                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
