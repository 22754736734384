import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";

function JQueryValidation(props) {
  const [packSize, setPackSize] = useState("");
  const [varient, setVarient] = useState("");
  const [quality, setQuality] = useState("");
  const [unit, setUnit] = useState("");
  const [size, setSize] = useState("");
  const [varients, setVarients] = useState([]);
  const [qualities, setQualities] = useState([]);
  const [units, setUnits] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [commodity, setCommodity] = useState("");
  const [commodities, setCommodities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const [qualityDialogOpen, setQualityDialogOpen] = useState(false);
  const [dialogVarient, setDialogVarient] = useState("");
  const [dialogQuality, setDialogQuality] = useState("");
  const [dialogVarients, setDialogVarients] = useState([]);
  const [packageDialogOpen, setPackageDialogOpen] = useState(false);
  const [dialogPackage, setDialogPackage] = useState("");
  const [sizeDialogOpen, setSizeDialodOpen] = useState(false);
  const [dialogSize, setDialogSize] = useState("");
  const [quantifiedBy, setQuantifiedBy] = useState("kg");
  const [newUnit, setNewUnit] = useState("");

  useEffect(() => {
    fetchQualityData();
    fetchUnitData();
    fetchSizeData();
    fetchCommodity();
    fetchVarient();
    fetchUnitById();
  }, []);

  const fetchUnitById = async (unitId) => {
    try {
      const response = await fetch(environment.apiUrl + `unit/${unitId}`);
      if (response.ok) {
        const unitData = await response.json();
        return unitData.unit;
      } else {
        throw new Error("Failed to fetch unit name");
      }
    } catch (error) {
      console.error("Error fetching unit name:", error);
      return "";
    }
  };

  useEffect(() => {
    const calculateNewUnit = async () => {
      if (packSize && quantifiedBy && unit) {
        const selectedUnitName = await fetchUnitById(unit);
        const newUnitValue = `${packSize} ${quantifiedBy} ${selectedUnitName}`;
        setNewUnit(newUnitValue);
      } else {
        setNewUnit("");
      }
    };

    calculateNewUnit();
  }, [packSize, quantifiedBy, unit]);

  const fetchVarient = async () => {
    const data = await fetch(environment.apiUrl + "varient/all");
    const jsonData = await data.json();
    setDialogVarients(jsonData);
  };

  const fetchCommodity = async () => {
    const data = await fetch(environment.apiUrl + "commodity/all");
    const jsonData = await data.json();
    setCommodities(jsonData);
  };

  useEffect(() => {
    if (commodity) {
      fetchVariantsData(commodity);
    }
  }, [commodity]);

  useEffect(() => {
    if (varient) {
      fetchSizeData(varient);
    }
  }, [varient]);

  useEffect(() => {
    if (varient) {
      fetchQualityData(varient);
    }
  }, [varient]);

  const fetchVariantsData = async (selectedCommodity) => {
    const data = await fetch(
      environment.apiUrl + `varient/${selectedCommodity}/varients`
    );
    const jsonData = await data.json();
    setVarients(jsonData);
  };

  const fetchQualityData = async (varientId) => {
    const data = await fetch(
      environment.apiUrl + `quality/${varientId}/qualities`
    );
    const jsonData = await data.json();
    setQualities(jsonData);
  };

  const fetchUnitData = async () => {
    const data = await fetch(environment.apiUrl + "unit/all");
    const jsonData = await data.json();
    setUnits(jsonData);
  };

  const fetchSizeData = async (varientId) => {
    const data = await fetch(environment.apiUrl + `size/${varientId}/sizes`);
    const jsonData = await data.json();
    setSizes(jsonData);
  };

  const handleClickOpenQuality = (e) => {
    e.preventDefault();
    setQualityDialogOpen(true);
  };

  const handleCloseQuality = () => {
    setQualityDialogOpen(false);
  };

  const handleClickOpenPackaging = (e) => {
    e.preventDefault();
    setPackageDialogOpen(true);
  };

  const handleClosePackage = () => {
    setPackageDialogOpen(false);
  };

  const handleClickOpenSize = (e) => {
    e.preventDefault();
    setSizeDialodOpen(true);
  };

  const handleCloseSize = () => {
    setSizeDialodOpen(false);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/product",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    
    if (!commodity || commodity === "Select Option") {
      toast.error("Commodity field is required.");
      setIsSubmitting(false)
      return;
    }

    if (!varient || varient === "Select Option") {
      toast.error("Varient field is required.");
      setIsSubmitting(false)
      return;
    }

    if (!quality || quality === "Select Option") {
      toast.error("Quality field is required.");
      setIsSubmitting(false)
      return;
    }

    if (!unit || unit === "Select Option") {
      toast.error("Packaging type field is required.");
      setIsSubmitting(false)
      return;
    }

    if (!size || size === "Select Option") {
      toast.error("Size field is required.");
      setIsSubmitting(false)
      return;
    }

    if (!quantifiedBy) {
      toast.error("QuantifieBy field is required.");
      setIsSubmitting(false)
      return;
    }

    if (!packSize || packSize === "Select Option") {
      toast.error("Pack Size field is required.");
      setIsSubmitting(false)
      return;
    }

    const productData = {
      packSize,
      varientId: varient,
      qualityId: quality,
      unitId: unit,
      sizeId: size,
      commodityId: commodity,
      quantifiedBy,
      newUnit,
    };

    try {
      const response = await fetch(environment.apiUrl + "product/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
      });

      if (response.ok) {
        toast.success("Product created successfully!");
        handleCancel();

        history.push({
          pathname: "/product",
          state: { newProduct: productData },
        });
      } else {
        toast.error("Error adding product.");
        setIsSubmitting(false)
      }
    } catch (error) {
      toast.error("Error adding product");
      setIsSubmitting(false)
    }finally{
      setIsSubmitting(false)
    }
  };

  const handleUnitTypeChange = (e) => {
    setQuantifiedBy(e.target.value);
  };

  const handleSizeChange = (e) => {
    const selectedSizeId = e.target.value;
    console.log(`Selected Size: ${selectedSizeId}`);
    setSize(selectedSizeId);
  };

  const handleSaveQuality = async () => {
    if (!dialogVarient) {
      toast.error("varient field is required.");
      return;
    }

    if (!dialogQuality) {
      toast.error("Quality field is required.");
      return;
    }

    const qualityData = {
      quality: dialogQuality,
      varientId: dialogVarient,
    };

    try {
      const response = await fetch(environment.apiUrl + "quality/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(qualityData),
      });

      if (response.ok) {
        toast.success(" Quality created successfully!");
        const newQuality = await response.json();
        setQualities([...qualities, newQuality]);
        // handleCancel();
        // fetchQualityData()

        // history.push({
        //   pathname: "/quality",
        //   state: { newQuality: qualityData },
        handleCloseQuality();
        // });
      } else {
        toast.error("Error adding quality.");
      }
    } catch (error) {
      toast.error("Error adding quality");
    }
  };

  const handleSavePackage = async () => {
    if (!dialogPackage) {
      toast.error("Packaging Type field is required.");
      return;
    }
    const unitData = {
      unit: dialogPackage,
    };
    try {
      const response = await fetch(environment.apiUrl + "unit/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(unitData),
      });

      if (response.ok) {
        toast.success(" Unit created successfully!");
        fetchUnitData();

        // history.push({
        //   pathname: "/unit",
        //   state: { newUnit: unitData },
        // });
      } else {
        toast.error("Error adding unit.");
      }
    } catch (error) {
      toast.error("Error adding unit");
    }

    handleClosePackage();
  };

  const handleSaveSize = async () => {
    if (!dialogVarient) {
      toast.error("varient field is required.");
      return;
    }

    if (!dialogSize) {
      toast.error("Size field is required.");
      return;
    }

    const sizeData = {
      size: dialogSize,
      varientId: dialogVarient,
    };

    console.log(sizeData);

    try {
      const response = await fetch(environment.apiUrl + "size/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sizeData),
      });

      if (response.ok) {
        const newSize = await response.json(); 
        toast.success(" Size created successfully!");
        setSizes([...sizes,newSize ]);
        handleCloseSize();
      } else {
        toast.error("Error adding size.");
      }
    } catch (error) {
      toast.error("Error adding size");
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/product">Product</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Add Product</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Product</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Commodity
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="commodity-select"
                            name="commodity-select"
                            value={commodity}
                            onChange={(e) => setCommodity(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {commodities.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.commodity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Variant
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={varient}
                            onChange={(e) => setVarient(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {varients
                              .filter((active) => active.isActive)
                              .map((res) => (
                                <option value={res.id} key={res.id}>
                                  {res.varient}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Quality
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-10" style={{ display: "flex" }}>
                          <select
                            className="form-control"
                            id="quality-select"
                            name="quality-select"
                            value={quality}
                            onChange={(e) => setQuality(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {qualities.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.quality}
                              </option>
                            ))}
                          </select>
                          &nbsp;&nbsp;
                          <div className="col-lg-8 ms-auto">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleClickOpenQuality}
                            >
                              Add Quality
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Packaging Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-10" style={{ display: "flex" }}>
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {units.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.unit}
                              </option>
                            ))}
                          </select>
                          &nbsp;&nbsp;
                          <div className="col-lg-8 ms-auto">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleClickOpenPackaging}
                            >
                              Add Packaging Type
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-suggestions"
                        >
                          Size
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-10" style={{ display: "flex" }}>
                          <select
                            className="form-control"
                            id="val-skill"
                            name="val-skill"
                            value={size}
                            onChange={handleSizeChange}
                          >
                            <option value="Select Option">Select Option</option>
                            {sizes.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.size}
                              </option>
                            ))}
                          </select>
                          &nbsp;&nbsp;
                          <div className="col-lg-8 ms-auto">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleClickOpenSize}
                            >
                              Add Size
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-unit-type"
                        >
                          Quantified By
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-10">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="QuantifiedBy"
                              id="kg"
                              value="kg"
                              checked={quantifiedBy === "kg"}
                              onChange={handleUnitTypeChange}
                            />
                            <label className="form-check-label" htmlFor="kg">
                              kg
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="QuantifiedBy"
                              id="pc"
                              value="pc"
                              checked={quantifiedBy === "pc"}
                              onChange={handleUnitTypeChange}
                            />
                            <label className="form-check-label" htmlFor="pc">
                              pc
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-suggestions"
                        >
                          Pack Size
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="number"
                            className="form-control"
                            id="packSize"
                            name="packSize"
                            value={packSize}
                            onChange={(e) =>
                              setPackSize(parseFloat(e.target.value))
                            }
                            placeholder="Enter pack size"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <label
                        className="col-lg-2 col-form-label"
                        htmlFor="val-suggestions"
                      >
                        Unit
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-4">
                        <input
                          type="text"
                          className="form-control"
                          id="newUnit"
                          name="newUnit"
                          value={newUnit}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        ></label>
                        <div className="col-lg-6">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={onAddUser}
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quality Dialog */}
      <Dialog open={qualityDialogOpen} onClose={handleCloseQuality}>
        <DialogTitle>Add Quality</DialogTitle>
        <DialogContent>
          <DialogContentText>Add Your custom Quality Data</DialogContentText>
          <FormControl>
            <InputLabel htmlFor="variant-dropdown">Variant</InputLabel>
            <Select
              labelId="variant-dropdown"
              id="variant-dropdown"
              value={dialogVarient}
              onChange={(e) => setDialogVarient(e.target.value)}
              style={{ minWidth: "200px" }}
            >
              <MenuItem value="">Select Variant</MenuItem>
              {dialogVarients.map((res) => (
                <MenuItem key={res.id} value={res.id}>
                  {res.varient}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="quality"
            label="Quality"
            type="text"
            fullWidth
            variant="standard"
            value={dialogQuality}
            onChange={(e) => setDialogQuality(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQuality}>Cancel</Button>
          <Button onClick={handleSaveQuality}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Package Type Dialog */}
      <Dialog open={packageDialogOpen} onClose={handleClosePackage}>
        <DialogTitle>Add Packaging Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Your custom Packaging Type Data
          </DialogContentText>
          <TextField
            margin="dense"
            id="package"
            label="Packaging Type"
            type="text"
            fullWidth
            variant="standard"
            value={dialogPackage}
            onChange={(e) => setDialogPackage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePackage}>Cancel</Button>
          <Button onClick={handleSavePackage}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Size Dialog  */}
      <Dialog open={sizeDialogOpen} onClose={handleCloseSize}>
        <DialogTitle>Add Size</DialogTitle>
        <DialogContent>
          <DialogContentText>Add Your custom Size Data</DialogContentText>
          <FormControl>
            <InputLabel htmlFor="variant-dropdown">Variant</InputLabel>
            <Select
              labelId="variant-dropdown"
              id="variant-dropdown"
              value={dialogVarient}
              onChange={(e) => setDialogVarient(e.target.value)}
              style={{ minWidth: "200px" }}
            >
              {/* <MenuItem >Select Variant</MenuItem> */}
              {dialogVarients.map((res) => (
                <MenuItem key={res.id} value={res.id}>
                  {res.varient}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="size"
            label="Size"
            type="text"
            fullWidth
            variant="standard"
            value={dialogSize}
            onChange={(e) => setDialogSize(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSize}>Cancel</Button>
          <Button onClick={handleSaveSize}>Save</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default JQueryValidation;
