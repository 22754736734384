import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import ExpenseTab from "./ExpenseTab";
import ImprestTab from "./ImprestTab";

function JQueryValidation(props) {
  const [employee, setEmployee] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [employees, setEmployees] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [activeTab, setActiveTab] = useState("Fields");
  const history = useHistory();
  const [readonly, setReadonly] = useState(false);
  const [imprestBalanceAmount, setImprestBalanceAmount] = useState([]);
  const [empImprestBalanceAmount, setEmpImprestBalanceAmount] = useState([]);
  const { id } = useParams();
  const userId = localStorage.getItem("imprestUserId");

  useEffect(() => {
    fetchEmployeeData();
    if (id) {
      fetchBalanceData(id);
    }
  }, [id]);

  useEffect(() => {
    if (userId) {
      fetchImprestBalance(userId);
      fetchEmpImprestBalance(userId);
    }
  }, [userId]);

  const fetchImprestBalance = async (userId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `imprestBalance/all/${userId}`
      );
      const responseData = await response.json();
      console.log("response Data : ", responseData);
      setImprestBalanceAmount(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmpImprestBalance = async (userId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `empImprestBalance/all/${userId}`
      );
      const responseData = await response.json();
      console.log("emp response Data : ", responseData);
      setEmpImprestBalanceAmount(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  let totalImprestBalance = 0;
  let totalEmpImprestBalance = 0;

  for (let index = 0; index < imprestBalanceAmount.length; index++) {
    totalImprestBalance += imprestBalanceAmount[index].expenseAmount;
  }

  for (let index = 0; index < empImprestBalanceAmount.length; index++) {
    totalEmpImprestBalance += empImprestBalanceAmount[index].expenseAmount;
  }

  const totalAmout = totalImprestBalance - totalEmpImprestBalance;

  useEffect(() => {
    const initialImprestBalance = (
      parseFloat(amount || "0.00") - parseFloat(totalExpense)
    ).toFixed(2);
    setAmount(initialImprestBalance);
  }, [totalExpense]);

  const fetchEmployeeData = async () => {
    const data = await fetch(environment.apiUrl + "employee/all_emp");
    const jsonData = await data.json();
    setEmployees(jsonData);
  };

  const fetchBalanceData = async (balanceId) => {
    try {
      const response = await fetch(
        environment.apiUrl + `imprestBalance/${balanceId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching imprest balance data.");
      }

      const balanceData = await response.json();
      setEmployee(balanceData.userTableId);
      setAmount(balanceData.expenseAmount);
      setDate(balanceData.date);
    } catch (error) {
      console.error("Error fetching imprest balance data:", error);
      toast.error("Error fetching imprest balance data");
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: "/imprestBalance",
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onAddUser = async (e) => {
    e.preventDefault();
    console.log("saved");

    const BalanceData = {
      date,
      expenseAmount: amount,
      userTableId: employee,
    };

    console.log(BalanceData);

    try {
      const response = await fetch(
        environment.apiUrl + `imprestBalance/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(BalanceData),
        }
      );

      if (response.ok) {
        toast.success(" Imprest Balance Updated successfully!");
        handleCancel();

        history.push({
          pathname: "/imprestBalance",
          state: { newBalance: BalanceData },
        });
      } else {
        toast.error("Error adding imprest Balance.");
      }
    } catch (error) {
      toast.error("Error adding imprest Balance");
    }
  };

  return (
    <Fragment>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/imprestBalance">Upload Imprest Balance</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Update Imprest Balance</Link>
          </li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Imprest Balance</h4>
            </div>
            <div className="card-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "Fields" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Fields")}
                    style={{ cursor: "pointer" }}
                  >
                    Fields
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "Imprest Balance" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Imprest Balance")}
                    style={{ cursor: "pointer" }}
                  >
                    Imprest Balance
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "Expenses" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Expenses")}
                    style={{ cursor: "pointer" }}
                  >
                    Expenses
                  </a>
                </li>
              </ul>

              <div className="tab-content mt-3">
                <div className="form-validation">
                  {activeTab === "Fields" && (
                    <form className="form-valide" action="#" method="post">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="val-division"
                            >
                              Employee
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <select
                                className="form-control"
                                id="val-role"
                                name="val-role"
                                value={employee}
                                onChange={(e) => setEmployee(e.target.value)}
                                disabled={true}
                              >
                                <option value="Select Option">
                                  Select Option
                                </option>
                                {employees
                                  .filter((res) => res.isAdmin === false)
                                  .map((res) => (
                                    <option value={res.id} key={res.id}>
                                      {res.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="val-username"
                            >
                              Current Imprest Balance
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="val-username"
                                name="val-username"
                                value={totalAmout}
                                readOnly
                                placeholder="Current Imprest Balance"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  )}

                  {activeTab === "Imprest Balance" && (
                    <div className="col-12" style={{ cursor: "pointer" }}>
                      <ImprestTab />
                  
                    </div>
                  )}
                  {activeTab === "Expenses" && (
                    <div
                      className="form-group mb-3 row"
                      style={{ cursor: "pointer" }}
                    >
                      <ExpenseTab
                        user={employee}
                        onTotalExpenseChange={setTotalExpense}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
