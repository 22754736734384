import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { addSubscription } from "../../../store/actions/AuthActions";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { environment } from "../../../environments/environment";
import { useEffect } from "react";

function JQueryValidation(props) {
  const [size, setSize] = useState("");
  const[varient, setVarient]=useState("");
  const[varients,setVarients]=useState([]);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {id}= useParams();
  

  useEffect(()=>{
    fetchVarientData();
    if(id){
        fetchSizeData(id);
    }
  },[id])

  const fetchSizeData = async (sizeId) => {
    try {
        const response = await fetch(environment.apiUrl + `size/${sizeId}`);
        if (!response.ok) {
          throw new Error("Error fetching size data.");
        }
  
        const sizeData = await response.json();
        setSize(sizeData.size);
        setVarient(sizeData.varientId)

      } catch (error) {
        console.error("Error fetching size data:", error);
        toast.error("Error fetching size data");
      }
  };

  const fetchVarientData = async () => {
    const data = await fetch(environment.apiUrl + "varient/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setVarients(jsonData);
  };


  const handleCancel = () => {
    history.push({
      pathname: "/size",
    });
  };

  const dispatch = useDispatch();

  const onAddUser = async (e) => {
    e.preventDefault();
    // Dispatch your action here
    setIsSubmitting(true)

    

    if (!varient || varient === "Select Option") {
        toast.error("varient field is required.");
        setIsSubmitting(false)
        return;
      }

      if (!size) {
        toast.error("Size field is required.");
        setIsSubmitting(false)
        return;
      }

    const sizeData = {
      size,
      varientId:varient
    };

    console.log(sizeData);

    try {
      const response = await fetch(
        environment.apiUrl + `size/update/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sizeData),
        }
      );

      if (response.ok) {
        toast.success(" Size created successfully!");
        handleCancel();

        history.push({
          pathname: "/size",
          state: { newSize: sizeData },
        });
      } else {
        toast.error("Error adding size.");
        setIsSubmitting(false)
      }
    } catch (error) {
      toast.error("Error adding size");
    }
    finally{
      setIsSubmitting(false)
    }
  };

  return (
    <Fragment>
     <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active"><Link to="/size">Size</Link></li>
          <li className="breadcrumb-item"><Link to="#">Update Size</Link></li>
        </ol>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Unit</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={onAddUser}
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label"></label>
                        <div className="col-lg-6"></div>
                      </div>

                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-division"
                        >
                          Variant
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <select
                            className="form-control"
                            id="val-role"
                            name="val-role"
                            value={varient}
                            onChange={(e) => setVarient(e.target.value)}
                          >
                            <option value="Select Option">Select Option</option>
                            {varients.map((res) => (
                              <option value={res.id} key={res.id}>
                                {res.varient}
                              </option>
                            ))}
                          </select>
                        </div>

                        <label
                          className="col-lg-2 col-form-label"
                          htmlFor="val-username"
                        >
                          Size
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                            placeholder="Enter unit type..."
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button
                            type="submit"
                            className="btn btn-danger"
                            style={{ marginTop: "20px" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginTop: "20px" }}
                            disabled={isSubmitting}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JQueryValidation;
