import React, { useState, useEffect } from "react";
import { Card, Tab, Nav, Col, Row, Form } from "react-bootstrap";
import axios from "axios";
import { environment } from "../../../environments/environment";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [areaId, setArea] = useState("");
  const [businessTypeId, setBusiness] = useState("");
  const [commodities, setCommodities] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [documentNumber, setDocNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [brandMarka, setBrandMarka] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [UserID, setUser] = useState("");
  const [accessUser, setAccessUser] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [address, setAddress] = useState("");
  const [notes, setNote] = useState("");
  const [selectPdf, setSelectedpdf] = useState(null);
  const [image, setPartyImage] = useState(null);
  const [docImage, setDocImage] = useState(null);
  const [docImage2, setDocImage2] = useState(null);
  const [docPdf, setDocPdf] = useState(null);
  const [docTypes, setDocTypes] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [basicSrc, setBasicSrc] = useState(null);
  const [kycSrc1, setKycSrc1] = useState(null);
  const [kycSrc2, setKycSrc2] = useState(null);
  const [kycSrc3, setKycSrc3] = useState(null);
  const [bankScr1, setBankSrc1] = useState(null);
  const [bankScr2, setBankSrc2] = useState(null);

  const [kycId, setKycId] = useState(null);
  const [bankId, setBankId] = useState(null);

  useEffect(() => {
    fetchDocType();
  }, []);

  const fetchDocType = async () => {
    const data = await fetch(environment.apiUrl + "doctype/all");
    const jsonData = await data.json();
    setDocTypes(jsonData);
  };

  useEffect(() => {
    if (id) {
      fetchParyBasic(id);
    }
  }, [id]);

  const fetchParyBasic = async (basicId) => {
    const data = await fetch(environment.apiUrl + `party/${basicId}`);
    const basicInfo = await data.json();
    console.log(basicInfo);
    setBusinessName(basicInfo.party.businessName);
    setBrandMarka(basicInfo.party.brandMarka);
    setContactPerson(basicInfo.party.contactPerson);
    setMobileNumber(basicInfo.party.mobileNumber);
    setAddress(basicInfo.party.address);
    setArea(basicInfo.party.areaId);
    setBusiness(basicInfo.party.businessTypeId);
    setPartyImage(basicInfo.party.image);
    setUser(basicInfo.party.UserID);
    setNote(basicInfo.party.notes);
    setKycId(basicInfo.party.kycId);
    setBankId(basicInfo.party.bankId);
    let data2 = environment.apiUrl + "party/files/" + basicInfo.party.image;
    console.log(data2);
    setBasicSrc(data2);

    let i = basicInfo.commodities.length;
    let j = 0;
    let itemAdded = [];
    for (let index = 0; index < basicInfo.commodities.length; index++) {
      const element = basicInfo.commodities[index];
      itemAdded.push({
        value: element.commodity.id,
        label: element.commodity.commodity,
      });
      j++;
      if (i == j) {
        setSelectedCommodities(itemAdded);
      }
    }

    let _i = basicInfo.employees.length;
    let _j = 0;
    let _itemAdded = [];
    for (let index = 0; index < basicInfo.employees.length; index++) {
      const element = basicInfo.employees[index];
      _itemAdded.push({
        value: element.userTable.id,
        label: element.userTable.name,
      });
      _j++;
      if (_i == _j) {
        setSelectedUsers(_itemAdded);
      }
    }

    setDocumentType(basicInfo.kyc.documentTypeId);
    setDocNumber(basicInfo.kyc.documentNumber);
    setDocImage(basicInfo.kyc.imageOfDocument);
    setDocImage2(basicInfo.kyc.image2);
    setDocPdf(basicInfo.kyc.documentPDF);
    let kycdata1 =
      environment.apiUrl + "kyc/files/" + basicInfo.kyc.imageOfDocument;
    let kycdata2 = environment.apiUrl + "kyc/files/" + basicInfo.kyc.image2;
    let kycdata3 =
      environment.apiUrl + "kyc/files/" + basicInfo.kyc.documentPDF;
    setKycSrc1(kycdata1);
    setKycSrc2(kycdata2);
    setKycSrc3(kycdata3);

    setName(basicInfo.bank.name);
    setAccNumber(basicInfo.bank.accountNumber);
    setIfsc(basicInfo.bank.ifsc);
    setBranchName(basicInfo.bank.branchName);
    setSelectedImage(basicInfo.bank.imageOfCancelledCheque);
    setSelectedpdf(basicInfo.bank.documentOfCancelledCheque);
    let bankdata1 =
      environment.apiUrl +
      "bank/files/" +
      basicInfo.bank.imageOfCancelledCheque;
    let bankdata2 =
      environment.apiUrl +
      "bank/files/" +
      basicInfo.bank.documentOfCancelledCheque;
    setBankSrc1(bankdata1);
    setBankSrc2(bankdata2);
  };

  const onBasicUpdate = async () => {
    /* console.log({
      employees: selectedUsers,
      commodities: selectedCommodities
    }); */
    const formDataBasic = new FormData();
    formDataBasic.append("businessName", businessName);
    formDataBasic.append("brandMarka", brandMarka);
    formDataBasic.append("contactPerson", contactPerson);
    formDataBasic.append("mobileNumber", mobileNumber);
    formDataBasic.append("address", address);
    formDataBasic.append("areaId", areaId);
    formDataBasic.append("businessTypeId", businessTypeId);
    formDataBasic.append("image", image);
    formDataBasic.append("commodityId", 1);
    formDataBasic.append("UserID", UserID);
    formDataBasic.append("notes", notes);
    // formDataBasic.append("kycId", kycId);
    // formDataBasic.append("bankId", bankId);
    formDataBasic.append("commodities", JSON.stringify(selectedCommodities));
    formDataBasic.append("employees", JSON.stringify(selectedUsers));

    try {
      const response = await fetch(environment.apiUrl + `party/update/${id}`, {
        method: "PUT",
        headers: {},
        body: formDataBasic,
      });

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Party updated successfully!");
        fetchParyBasic(id);
        // handleCancel();
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error Updateing Party. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error Updateing Party. Please try again");
    }
  };

  const onKycUpdate = async () => {
    const formData = new FormData();
    formData.append("documentTypeId", documentType);
    formData.append("documentNumber", documentNumber);

    if (docImage) {
      formData.append("imageOfDocument", docImage);
    }
    if (docImage2) {
      formData.append("image2", docImage2);
    }

    if (docPdf) {
      formData.append("documentPDF", docPdf);
    }

    try {
      const response = await fetch(environment.apiUrl + `kyc/update/${kycId}`, {
        method: "PUT",
        headers: {},
        body: formData,
      });

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Kyc updated successfully!");
        fetchParyBasic(id);
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error adding Party. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);

      toast.error("Error adding Party. Please try again");
    }
  };

  const onBankUpdate = async () => {
    console.log(bankId);
    console.log({
      name: name,
      accountNumber: accNumber,
      ifsc: ifsc,
      branchName: branchName,
      imageOfCancelledCheque: selectedImage,
      documentOfCancelledCheque: selectPdf,
    });
    const formDataBank = new FormData();
    formDataBank.append("name", name);
    formDataBank.append("accountNumber", accNumber);
    formDataBank.append("ifsc", ifsc);
    formDataBank.append("branchName", branchName);

    if (selectedImage) {
      formDataBank.append("imageOfCancelledCheque", selectedImage);
    }
    if (selectPdf) {
      formDataBank.append("documentOfCancelledCheque", selectPdf);
    }

    try {
      const response = await fetch(
        environment.apiUrl + `bank/update/${bankId}`,
        {
          method: "PUT",
          headers: {},
          body: formDataBank,
        }
      );

      const responseData2 = await response.json();

      if (response.ok) {
        toast.success("Bank updated successfully!");
        fetchParyBasic(id);
      } else {
        if (responseData2 && responseData2.message) {
          toast.error(responseData2.message);
        } else {
          toast.error("Error adding bank. Please try again.");
          console.log();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handlepdfUpload = (e) => {
    const imageFile = e.target.files[0];
    setSelectedpdf(imageFile);
  };

  const handlePartyImage = (e) => {
    const imageFile = e.target.files[0];
    setPartyImage(imageFile);
  };

  const handleDocImage = (e) => {
    const imageFile = e.target.files[0];
    setDocImage(imageFile);
  };

  const handleDocImage2 = (e) => {
    const imageFile = e.target.files[0];
    setDocImage2(imageFile);
  };

  const handlePdf = (e) => {
    const imageFile = e.target.files[0];
    setDocPdf(imageFile);
  };

  // const formData = new FormData();

  // if (selectedImage) {
  //   formData.append("image", selectedImage);
  // }

  // Define tabData before usage
  const tabData = [
    // {
    //   name: "Basic Info",
    //   icon: "user",
    // },
    {
      name: "KYC",
      icon: "pen",
    },
    // {
    //   name: "Bank Account Details",
    //   icon: "bank",
    // },
  ];

  useEffect(() => {
    fetchAllEmp();
  }, []);

  const fetchAllEmp = async () => {
    const data = await fetch(environment.apiUrl + "employee/all_emp");
    const jsonData = await data.json();
    setAccessUser(jsonData);
  };

  useEffect(() => {
    fetchCommodity();
    fetchArea();
    fetchBusinessType();
  }, []);

  const fetchCommodity = async () => {
    const data = await fetch(environment.apiUrl + "commodity/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setCommodities(jsonData);
  };

  const fetchArea = async () => {
    const data = await fetch(environment.apiUrl + "area/getAreas");
    const jsonData = await data.json();
    console.log(jsonData);
    setAreas(jsonData);
  };

  const fetchBusinessType = async () => {
    const data = await fetch(environment.apiUrl + "businessType/all");
    const jsonData = await data.json();
    console.log(jsonData);
    setBusinessTypes(jsonData);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(environment.apiUrl + "auth/authors", {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN"),
          },
        });
        setUsers(response.data.authors);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
  }, []);

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const handleBack = () => {
    history.push("/dashboard");
  };

  const isFirstTab = activeTab === 0;
  const isLastTab = activeTab === tabData.length - 1;

  const addUser = () => {
    alert("Selected");
  };

  /****************** Basic Details ************************** */
  const renderBasicInfoTab = () => {
    const commodityOptions = commodities.map((commodity) => ({
      value: commodity.id,
      label: commodity.commodity,
    }));

    const handleCommodityChange = (selectedOptions) => {
      setSelectedCommodities(selectedOptions);
    };

    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="businessName">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Enter Business name..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="brandMarka">
                <Form.Label>Brand Marka</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={brandMarka}
                  onChange={(e) => setBrandMarka(e.target.value)}
                  placeholder="Enter brand Marka..."
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="contactPersonName">
                <Form.Label>Contact Person Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  placeholder="Enter contact person name ..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="mobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter mobile number..."
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="val-username"
                  name="val-username"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter address..."
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="area">
                <Form.Label>Area</Form.Label>

                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={areaId}
                    onChange={(e) => setArea(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {areas.map((res) => (
                      <option value={res.id} key={res.id}>
                        {res.area}
                      </option>
                    ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="businessType">
                <Form.Label>Business Type</Form.Label>

                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={businessTypeId}
                    onChange={(e) => setBusiness(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {businessTypes
                      .filter((businessType) => businessType.isActive)
                      .map((res) => (
                        <option value={res.id} key={res.id}>
                          {res.businessType}
                        </option>
                      ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handlePartyImage}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <img
                    src={basicSrc}
                    style={{
                      width: "120px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="dealInCommodity">
                <Form.Label>Deal in Commodity</Form.Label>
                <Select
                  isMulti
                  options={commodityOptions}
                  value={selectedCommodities}
                  onChange={handleCommodityChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group>
                <Form.Label>Access to Users</Form.Label>
                <Select
                  isMulti
                  options={accessUser
                    .filter((user) => user.isAdmin === false)
                    .map((res) => ({
                      value: res.id,
                      label: res.name,
                    }))}
                  value={selectedUsers}
                  onChange={(selectedOptions) => {
                    setSelectedUsers(selectedOptions);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="note">
            <Form.Label>Note</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="val-username"
              name="val-username"
              value={notes}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Enter address..."
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                marginTop: "10px",
                display: isFirstTab ? "none" : "inline-block",
              }}
              onClick={handleBack}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-secondary`}
              style={{ marginTop: "10px" }}
              onClick={() => {
                onBasicUpdate();
              }}
            >
              Update
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-${isLastTab ? "primary" : "secondary"}`}
              style={{
                marginTop: "10px",
                display: isLastTab ? "none" : "inline-block",
              }}
              onClick={handleNext}
            >
              {isLastTab ? "Submit" : "Next"}
            </button>
          </div>
        </Form>
        {/* <button onClick={()=>{onSubmit1()}}>submit</button> */}
      </div>
    );
  };

  /***************** KYC Tab ******************** */

  const handleCancel = () => {
    history.push({
      pathname: "/dashboard",
    });
  };

  const renderKYCTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>Type of Document</Form.Label>
                <div className="col-lg-8">
                  <select
                    className="form-control"
                    id="val-role"
                    name="val-role"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                  >
                    <option value="Select Option">Select Option</option>
                    {docTypes.map((res) => (
                      <option value={res.id}>{res.docName}</option>
                    ))}
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentNo">
                <Form.Label>Document Number</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={documentNumber}
                    onChange={(e) => setDocNumber(e.target.value)}
                    placeholder="Enter Document Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage">
                    <Form.Label>Document Image</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleDocImage}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <img
                    src={kycSrc1}
                    style={{
                      width: "120px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage2">
                    <Form.Label>Document Image 2</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleDocImage2}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <img
                    src={kycSrc2}
                    style={{
                      width: "120px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-4">
              <Form.Group controlId="docPdf">
                <Form.Label>Document PDF</Form.Label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handlePdf}
                />
              </Form.Group>
            </div>
            <div className="col-4" style={{ marginTop: "40px" }}>
              <a target="_blank" href={kycSrc3}>
                View Document as Pdf
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className={`btn btn-secondary`}
              style={{ marginTop: "10px" }}
              onClick={() => {
                onKycUpdate();
              }}
            >
              Update
            </button>
            &nbsp;&nbsp;
            {/* <button
              type="button"
              className="btn btn-secondary"
              style={{ marginTop: "10px" }}
              onClick={handleBack}
            >
              Back
            </button> */}
            &nbsp;&nbsp;
            <button
              type="button"
              className={`btn btn-${isLastTab ? "primary" : "secondary"}`}
              style={{
                marginTop: "10px",
                display: isLastTab ? "none" : "inline-block",
              }}
              onClick={handleNext}
            >
              {isLastTab ? "Submit" : "Next"}
            </button>
          </div>
        </Form>
      </div>
    );
  };

  /********************* Bank Account details *********************** */

  const renderBankTab = () => {
    return (
      <div>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>Name</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentNo">
                <Form.Label>Account Number</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="number"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={accNumber}
                    onChange={(e) => setAccNumber(parseInt(e.target.value))}
                    placeholder="Enter account Number..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="documentNo">
                <Form.Label>IFSC Code</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    placeholder="Enter ifsc code..."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group controlId="documentType">
                <Form.Label>Branch Name</Form.Label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    id="val-username"
                    name="val-username"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter branch name..."
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              {" "}
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docImage">
                    <Form.Label> Image Of cancel cheque</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <img
                    src={bankScr1}
                    style={{
                      width: "120px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="row">
                <div className="col-8">
                  <Form.Group controlId="docPdf">
                    <Form.Label>Document PDF</Form.Label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={handlepdfUpload}
                    />
                  </Form.Group>
                </div>
                <div className="col-4" style={{ marginTop: "40px" }}>
                  <a target="_blank" href={bankScr2}>
                    View Document as Pdf
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-danger"
            style={{ marginTop: "10px" }}
            onClick={handleCancel}
          >
            Cancel
          </button>
          &nbsp;&nbsp;
          <button
            // type="submit"
            className={"btn btn-secondary"}
            style={{ marginTop: "10px" }}
            onClick={() => {
              onBankUpdate();
            }}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  const renderTabContent = (tabName) => {
    if (tabName === "Basic Info") {
      return renderBasicInfoTab();
    } else if (tabName === "KYC") {
      return renderKYCTab();
    } else if (tabName === "Bank Account Details") {
      return renderBankTab();
    }
  };

  return (
    <div className="col-12">
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Update KYC</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Body>
          <div className="card-header">
            <h1 className="card-title">Update KYC</h1>
          </div>
          <div className="default-tab">
            <Tab.Container
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={i}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={i} key={i}>
                    {renderTabContent(data.name)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserList;
