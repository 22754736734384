import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {environment} from "../environments/environment";

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login1(mobileNumber, otp) {
    const postData = {
        mobileNumber,
        otp,
        returnSecureToken: true,
    };
    return axios.post(
        environment.apiUrl + `employee/login-otp`,
        postData,
    );
}

export function login(userName, password) {
    const postData = {
        userName,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        environment.apiUrl + `employee/login`,
        postData,
    );
}

export function _addSubscription(title, day, amount) {
    const postData = {
        title,
        day,
        amount
    };
    return axios.post(
        environment.apiUrl + `account/author`,
        postData,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("MYTOKEN")
          }
        }
    );
}

export function getUserList() {
    return axios.get(
        environment.apiUrl + `auth/authors`,
    );
}

export function addUser(email, phoneNumber, name, accountId) {
    const postData = {
        email,
        phoneNumber,
        name,
        accountId
    };
    return axios.put(
        environment.apiUrl + `auth/signup`,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
          }
    );
}

export function _editUser(id, email, phoneNumber, name, accountId, myParentId) {
    const postData = {
        email,
        phoneNumber,
        name,
        accountId,
        myParentId
    };
    return axios.put(
        environment.apiUrl + `auth/user/` + id,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
        }
    );
}

export function _editSubscription(id, title, day, amount) {
    const postData = {
        title,
        day,
        amount
    };
    return axios.put(
        environment.apiUrl + `account/author/` + id,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
        }
    );
}

export function _editUser1(id, address, dob, pan, stateId, cityId, postalCode, link) {
    const postData = {
        address,
        dob,
        pan,
        stateId,
        cityId,
        postalCode,
        link,
    };
    return axios.put(
        environment.apiUrl + `auth/user1/` + id,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
        }
    );
}

export function _editUser2(id, stockBroker, loginId, mypassword, totpKey, mypan, apiKey) {
    const postData = {
        stockBroker,
        loginId,
        mypassword,
        totpKey,
        mypan,
        apiKey
    };
    return axios.put(
        environment.apiUrl + `auth/user2/` + id,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
        }
    );
}

export function addUser1(email, phoneNumber, name, myParentId) {
    const postData = {
        email,
        phoneNumber,
        name,
        myParentId
    };
    return axios.put(
        environment.apiUrl + `auth/signup1`,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
        }
    );
}

export function addUser2(email, phoneNumber, name, myParentId) {
    const postData = {
        email,
        phoneNumber,
        name,
        myParentId
    };
    return axios.put(
        environment.apiUrl + `auth/signup2`,
        postData,
        {
            headers: {
              Authorization: "bearer " + localStorage.getItem("MYTOKEN")
            }
        }
    );
}

export function changePassword(id, password) {
    const postData = {
        password,
    };
    return axios.put(
        environment.apiUrl + `employee/password/`+ id,
        postData,
    );
}

export function otpf(id) {
    return axios.get(
        environment.apiUrl + `auth/verify1/`+id,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}
